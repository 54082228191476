import React, { useState } from 'react'
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import { H3, DatePickerTitle } from '@modul-connect/shared/components/atoms/text'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateTime } from "luxon"
import { styles } from '../datePickerStyles'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import theme from '@modul-connect/shared/theme';
import { Icon } from '@modul-connect/shared/components/atoms/icon/icon';

const StartEndDatePicker = ({ loading, startDate, endDate, onStartMonthPicked, onEndMonthPicked, disabled, noTitle }) => {
  return (
    <View extend={styles.dayPickerWithTitle}>
      <View extend={styles.monthPickerView}>
        {noTitle ? null : <View extend={styles.filterHeaderContainer}><DatePickerTitle extend={{ width: '100%' }} title1={'Selected time'} title2={'period:'} /></View>}
        <View extend={[styles.filterSelectContainer, noTitle && {paddingLeft: 20}]}>
          <View extend={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              startDate={startDate}
              endDate={endDate}
              onDayPicked={onStartMonthPicked}
              loading={loading}
              dateIs='start'
            />
          </View>
          <H3 extend={{ paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: 'center', textAlign: 'center', lineHeight: 0 }}>―</H3>
          <View extend={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              startDate={startDate}
              endDate={endDate}
              onDayPicked={onEndMonthPicked}
              loading={loading}
              dateIs='end'
            />
          </View>
        </View>
      </View>
    </View>
  )
}

const DatePicker = ({
  startDate,
  endDate,
  onDayPicked,
  loading,
  dateIs,
  disabled
}) => {
  let [hoverRange, setHoverRange] = useState(null)

  const handleDayEnter = date => {
    setHoverRange(getMonthRange(date))
  }

  const handleDayLeave = () => {
    setHoverRange(getMonthRange(undefined))
  }

  const getMonthRange = (date) => {
    let dateTime = DateTime.fromJSDate(date)

    const days = []
    for (let i = 1; i <= dateTime.daysInMonth; i++) {
      days.push(DateTime.local(dateTime.year, dateTime.month, i).toJSDate())
    }
    return days
  }

  return (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <DayPickerInput
        value={dateIs === 'start' ? dateTimeToString(startDate, 'month-and-year') : dateTimeToString(endDate, 'month-and-year')}
        formatDate={(date) => {
          return dateTimeToString(DateTime.fromJSDate(date), 'month-and-year')
        }}
        style={{color: theme.colors.text}}
        placeholder=''
        dayPickerProps={{
          selectedDays: [startDate.toJSDate(), { from: startDate.toJSDate(), to: endDate.toJSDate() }],
          month: dateIs === 'start' ? startDate.toJSDate() : endDate.toJSDate(),
          toMonth: dateIs === 'end' ? DateTime.local().toJSDate() : endDate.toJSDate(),
          fromMonth: dateIs === 'end' ? startDate.toJSDate() : null,
          modifiers: {
            start: startDate.toJSDate(),
            end: endDate.toJSDate(),
            hoverRange,
            hoverRangeStart: hoverRange && hoverRange.length > 0 && hoverRange[0],
            hoverRangeEnd: hoverRange && hoverRange.length > 0 && hoverRange[hoverRange.length - 1],
          },
          onDayMouseEnter: (date) => handleDayEnter(date),
          onDayMouseLeave: (date) => handleDayLeave(date),
          onDayClick: (date) => {
            if (dateIs === 'start') {
              onDayPicked(DateTime.fromJSDate(date).startOf('month'))
            }
            else {
              onDayPicked(DateTime.fromJSDate(date).endOf('month'))
            }
          },
        }}
        inputProps={{
          style: {
            ...styles.inputField,
            backgroundColor: (disabled || loading.progress === 'loading') ? theme.colors.grey : styles.inputField.backgroundColor,
          },
          disabled: (disabled || loading.progress === 'loading') ? true : false,
          readOnly: true
        }}
      />
      <CalendarIcon />
    </View>
  )
}

// TODO: click on this should do the same as clicking inside the input field

// TODO: want to change to a different input component that supports icons properly, like MUI
// (had problems with rerender flickering with MUI, so for now rendering the icon like this)
export const CalendarIcon = () => {
  return (
    <View style={{ // for positioning the icon
      height: styles.inputField.height, width: 0,
      position: 'relative',
      alignSelf: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <View style={{
        position: 'absolute',
        height: '100%',
        padding: styles.inputField.padding,
        right: 0,

        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}>
        <Icon icon={'Calendar'} />
      </View>
    </View>
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps)(StartEndDatePicker)
