import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { checkTrackersBatteryIssue } from "../../../../utils/assets";
import { useAuth0 } from "@auth0/auth0-react";
import { DateTime } from 'luxon'
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { formatAddressTrackers } from "../../../../utils/vehicleUtils";
import { log_columns_view_all } from "./log_columns_view_all";
import SimpleTable from "@modul-connect/shared/components/molecules/tableWidget/simpleTable"
import DisabledNotificationIcon from '@modul-connect/shared/img/disabled_notificaion.svg'
import EnabledNotificationIcon from '@modul-connect/shared/img/enabled_notification.svg'
import GeoPositionICon from '@modul-connect/shared/img/geo_point.svg'
import BatteryFullIcon from '@modul-connect/shared/img/battery_full.svg'
import BatteryWarningIcon from '@modul-connect/shared/img/battery_warning.svg'

const filterByCategory = (data, category, categoryIndex) => {
  if(!category || !data?.length || (!categoryIndex && categoryIndex !== 0))
  {
    return data
  }

  return data?.filter(d => {
    return d[categoryIndex]?.value === category
  })
}

const filterBySearchString = (data, searchStr) => {
  if(!searchStr || !data?.length)
    {
      return data
    }

  return data?.filter(d => d?.some((property) => {
    return property.filterForValue && (
      (property?.value && property.value.toLowerCase().includes(searchStr?.toLowerCase())) ||
      (property?.value1 && property.value1.toLowerCase().includes(searchStr?.toLowerCase())) ||
      (property?.value2 && property.value2.toLowerCase().includes(searchStr?.toLowerCase()))
    )
  }))
}


const AssetTrackerTableViewAll = ({
  assets,
  vehicle_assets,
  loading,
  setOpenModal,
  asset_addresses,
  searchFilter,
  filterOption,
  setSelectedTracker,
  selectedTracker,
  edit,
  filterAppliesToColumnIndex
}) => {
  let [data, setData] = useState({})
  let [allData, setAllData] = useState({})

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const shouldFilterByCategory = filterOption
    const shouldSearchForString = searchFilter

    if(shouldFilterByCategory && shouldSearchForString)
    {
      setData(filterBySearchString(filterByCategory(allData, filterOption, filterAppliesToColumnIndex), searchFilter))
    }
    else if (shouldFilterByCategory) setData(filterByCategory(allData, filterOption, filterAppliesToColumnIndex))
  
    else if (shouldSearchForString) setData(filterBySearchString(allData, searchFilter))
    
    else setData(allData)
    }, [filterOption, searchFilter])

  useEffect(() => {
    let preparedData = []
    let missingBeacons = []

    if (assets && assets?.length > 0) {
      assets.map((asset) => {
        var addressObject = asset?.address ??  asset_addresses[asset?.tracker?.beaconId]?.address
        var address = addressObject 
          ? formatAddressTrackers(addressObject) 
          : {address: 'Unknown address'}
        var timeStamp = asset?.tracker?.currentStatus?.timeStamp ? asset?.tracker?.currentStatus?.timeStamp : asset?.tracker?.location?.timeStamp
        var date = dateTimeToString(DateTime.fromISO(timeStamp), 'date-and-time') === 'Invalid DateTime'
          ? '-'
          : dateTimeToString(DateTime.fromISO(timeStamp), 'date-and-time')?.replace(", ", "-")

        if (address?.address === 'Unknown address')
          missingBeacons.push(asset?.tracker?.beaconId)

        var isSelected = false
        if (data?.length > 0 && selectedTracker)
          isSelected = data?.filter(d => d?.[0].beaconId === asset?.tracker?.beaconId)?.[0]?.[0]?.isSelected
        if(selectedTracker?.[0].beaconId === asset?.tracker?.beaconId)
          isSelected = !selectedTracker?.[0].isSelected

        const addressStr = address?.address ? address?.address + (address?.postCodeCity ?? '') : 'Unknown addres'


        const hasBatteryIssue = checkTrackersBatteryIssue(asset?.tracker?.currentStatus?.batteryLevel)

        preparedData.push([
          { isSelected: isSelected, isSelectable: edit, value: asset?.vehicle.vehicleName, beaconId: asset?.tracker?.beaconId, filterForValue: true },
          { warning: !asset.isInside, value: !asset.isInside, tooltip: asset?.isInside ? undefined : "The asset is missing" },
          { value: asset.name, beaconId: asset?.tracker?.beaconId, filterForValue: true },
          { value: asset.category },
          {
            icon: hasBatteryIssue ? BatteryWarningIcon : BatteryFullIcon,
            value: hasBatteryIssue,
            tooltip: hasBatteryIssue ? "Battery is low" : "No battery warning"
          },
          { icon: GeoPositionICon, value1: addressStr, value2: date ?? '', filterForValue: true },
          {
            icon:
              asset.notificationEnabled
                ? EnabledNotificationIcon
                : DisabledNotificationIcon,
            value: asset.notificationEnabled,
            tooltip: asset?.notificationEnabled ? 'Notifications enabled' : 'Notifications disabled'
          },
          { value: 'Details', onClick: () => setOpenModal(asset.tracker.beaconId) },
        ]
        )
      }
      )
    }

    setData(preparedData)
    setAllData(preparedData)
  }, [assets, vehicle_assets, asset_addresses, selectedTracker, edit])


  return Object.keys(data)?.length ?
    <SimpleTable
      data={data}
      isLoading={loading.fetchAssetTrackersatus}
      columns={log_columns_view_all}
      setSelectedRow={setSelectedTracker}
      defaultSortIndex={1}
      defaultSortOrder={'desc'}
    />
    : null
}


const mapStateToProps = (asset_addresses) => (asset_addresses);

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetTrackerTableViewAll);