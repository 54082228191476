
import React from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import { DateTime } from "luxon";
import { H1, P } from "@modul-connect/shared/components/atoms/text";
import VehiclesList from "../../../vehiclesList";
import "../../../dashboard.css";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import HideableSidebar from "../../hideableSidebar";
import FilterMenu from "./filterMenu";
import { getServiceList } from "../../../../../utils/ServiceList";
import { vehicleMatchesMapFiltering } from "../../../../../utils/vehicleUtils";
import { ServiceType } from "@modul-connect/shared/utils/services";

const OverviewSidebar = ({
  themes,
  organisation,
  vehicles,
  loading,
  showSidebar,
  setShowSidebar,
  date,
  setDate,
  submenuIsOpen,
  setSelectedStartTime,
  setSelectedEndTime,
  startTime,
  endTime,
  filter,
  setFilter,
  subtrees,
  map_settings,
  vehicles_security_system_status
}) => {
  const isMobile = themes.device === 'mobile'

  const handleDayClick = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate(DateTime.fromJSDate(date).toISODate())
  }

  const handleDayChange = (date) => {
    if (!date || date > DateTime.local().toJSDate()) {
      return
    }

    setDate(DateTime.fromJSDate(date).toISODate())
  }

  const selectedDate = DateTime.fromISO(date)
  const services = getServiceList({subtrees})

  // Should only show those vehicles that have routes in the list
  const filtered_vehicles = vehicles.filter(x => vehicleMatchesMapFiltering(
    x, 
    date,
    DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, map_settings?.startClockTime?.hour, map_settings?.startClockTime?.minute),
    DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, map_settings?.endClockTime?.hour, map_settings?.endClockTime?.minute),
    map_settings))

  let noVehiclesToDisplay = false
  if (loading.fetchVehicleRoutes === null && loading.fetchLastKnownPositions === null && loading.fetchVehicles === null && !filtered_vehicles?.length && !loading.settingMapDate) {
    noVehiclesToDisplay = true
  }

  return (
    <HideableSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} hideOpenCloseButton={submenuIsOpen}>
      {
        date &&
        <View className={'sideMenuContainer'}>
          <View extend={styles.sideMenuHeader}>
            <H1 text={organisation?.name?.toUpperCase()} noPadding />
            {/* <H3 noPadding>{ date }</H3> */}

            {
              !isMobile && <DayPicker
                selectedDays={DateTime.fromISO(date).toJSDate()}
                toMonth={DateTime.local().toJSDate()}
                disabledDays={[
                  services.hasService(ServiceType.DrivingLogs)
                    ? {
                      after: DateTime.local().toJSDate()
                    }
                    : {
                      before: DateTime.local().toJSDate(),
                      after: DateTime.local().toJSDate()
                    },
                ]}
                onDayClick={handleDayClick}
              />
            }
            {
              isMobile && <View className={"DatePickerContainer"}>
                <DayPickerInput
                  onDayChange={handleDayChange}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format="LL"
                  toMonth={DateTime.local().toJSDate()}
                  dayPickerProps={{
                    modifiers: {
                      disabled: [
                        services.hasService(ServiceType.DrivingLogs)
                          ? {
                            after: DateTime.local().toJSDate()
                          }
                          : {
                            before: DateTime.local().toJSDate(),
                            after: DateTime.local().toJSDate()
                          },
                      ]
                    }
                  }}
                  selectedDay={DateTime.fromISO(date).toJSDate()}
                />
              </View>
            }
            
            <FilterMenu 
              themes={themes}
              setSelectedStartTime={setSelectedStartTime}
              setSelectedEndTime={setSelectedEndTime}
              startTime={startTime}
              endTime={endTime}
              filter={filter}
              setFilter={setFilter}
              subtrees={subtrees}/>

            {
              noVehiclesToDisplay ?
              <P>No vehicles available for this day.</P> : null
            }

            {
              loading.fetchVehicleRoutes === 'failed' ?
              <P>Loading vehicle routes failed.</P> : null
            }
          </View>
          <VehiclesList 
            vehicles={filtered_vehicles} 
            vehicles_security_system_status = {vehicles_security_system_status}
            date={date} />
        </View>
      }
    </HideableSidebar>
  )
}

const styles = {
  sideMenuHeader: ({ theme: { colors } }) => ({
    // "& h3": {
    //   display: 'inline',
    // }
    "> .DayPicker": {
      fontSize: 18,
      marginLeft: -19,
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: `${colors.primaryLight} !important`,
      color: `${colors.primary} !important`
    },
    "& .DayPicker-Day": {
      outline: 'none'
    },
    "& .DayPicker-Day--today": {
      color: `${colors.primary}`,
      fontWeight: 'bold'
    }
  }),
};

const mapStateToProps = ({
  themes,
  organisation,
  loading,
  vehicles,
  subtrees,
  map_settings,
  //selectedVehicle
}) => ({
  themes,
  organisation,
  loading,
  vehicles,
  subtrees,
  map_settings,
  //selectedVehicle
})

export default connect(mapStateToProps)(OverviewSidebar);