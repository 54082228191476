/* eslint-disable react-hooks/rules-of-hooks */
import React, {Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearAssetTrackers, fetchAssetTrackers, fetchVehicles, updateNotificationsOnTrackers } from "../../../../../state/actions/vehicles";
import { useAuth0 } from "@auth0/auth0-react";
import ReportPage from "../../../reportPage.v2";
import AssetStatusWidget from "../../assetTrackerStatusDetails/assetStatusWidget";
import AssetTrackerTable from "../../assetTrackerStatusDetails/assetTrackerTable";
import { getServiceList } from "../../../../../utils/ServiceList";
import { ServiceType } from "@modul-connect/shared/utils/services";
import { checkTrackersBatteryIssue } from "../../../../../utils/assets";
import TrackerDetailsModal from "../../modals/TrackerDetailsModal";
import { useHistory , useLocation} from 'react-router-dom';
import AssetTrackerTableViewAll from "../../assetTrackerStatusDetails/assetTrackerTableViewAll";
import View from "@modul-connect/shared/components/atoms/view/view";
import ConfirmationModal from "../../modals/confirmationModal";
import { SimpleDropdown } from "@modul-connect/shared/components/atoms/dropdown/simpleDropdown";
import SimpleButton from '@modul-connect/shared/components/atoms/iconButton/simpleButton';
import EditIcon from '@modul-connect/shared/img/edit.svg'
import CancelEditIcon from '@modul-connect/shared/img/cancel_edit.svg'
import theme from "@modul-connect/shared/theme";
import { GroupingOptionSelector } from "../../../tollPassages/summary/groupingOptionSelector";

export const groupingOptions = {
  byHomebase: 'Homebase',
  ViewAll: 'View all'
}

const actions = [
  'Turn off notifications',
  'Turn on notifications',
  'Delete'
]


const toolsCategory = [
  'Air Tools',
  'Axes',
  'Drills',
  'Compressors',
  'Ladders',
  'Lathes',
  'Nail Guns',
  'Power Ratchet Sets',
  'Saws',
  'Shovels',
  'Wrenches',
  'Other'
]

const assetTrackerStatusPage = ({
  selected_organisations,
  fetchAssetTrackers,
  assets,
  loading,
  vehicles,
  fetchVehicles,
  clearAssetTrackers,
  updateNotificationsOnTrackers,
  disabled
}) => {

  useEffect(
    () => {
      clearAssetTrackers()
    },[])

  const history = useHistory()
  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let [openModal, setOpenModal] = useState(null) // stores the beaconID of selected beacon, otherwise null
  let [openConfirmationModal, setOpenConfirmationModal] = useState(false) // stores the beaconID of selected beacon, otherwise null
  const [groupBy, setGroupBy] = useState( useQuery().get('groupby') ?? groupingOptions.byHomebase)
  const [action, setAction] = useState(null);
  const [edit, setEdit] = useState(false);
  const [categoryOption, setCategoryOption] = useState(null);
  const [filter, setFilter] = useState('')
  const [selectedTracker, setSelectedTracker] = useState(null)
  const [checkedRows, setCheckedRows] = useState([])

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() =>{
    if(!edit)
      setSelectedTracker(null)
  }, [edit])

  useEffect(() =>{
    getAccessTokenSilently().then((accessToken) => {
      if(checkedRows.length > 0 )
        switch (action){
          case 'Turn on notifications':
            setOpenConfirmationModal(true)
            return
          case 'Turn off notifications':
            setOpenConfirmationModal(true)
            return
          case 'Delete':
            console.error('Delete', checkedRows)
            return
          default: 
            return
        }
    });
  },[action,selected_organisations])

  useEffect(()=>
  {
    if (checkedRows?.includes(selectedTracker?.[0]?.beaconId))
    {
      checkedRows.pop(selectedTracker?.[0]?.beaconId)
      setCheckedRows(checkedRows)
    }
    else
    {
      if(selectedTracker)
      {
        checkedRows.push(selectedTracker?.[0]?.beaconId)
        setCheckedRows(checkedRows)
      }
    }
  }, [selectedTracker])


  useEffect(() => {
    setFilter('')
    setCategoryOption(null)
    setEdit(false)
    getAccessTokenSilently().then((accessToken) => {
      fetchAssetTrackers(accessToken, groupBy === groupingOptions.byHomebase);

      if(vehicles.length === 0)
        fetchVehicles(accessToken)
    });
  }, [groupBy, selected_organisations]);

  useEffect(() => {
    getAccessTokenSilently().then((accessToken) => {
      fetchVehicles(accessToken)
    })
  }, [selected_organisations])


  const changeGroupBy = (group) => {
    clearAssetTrackers()
    setGroupBy(group)
    history.replace({search: '?groupby=' + group})
  }

  const handleConfirmation = () => {
    getAccessTokenSilently().then((accessToken) => {
      if(checkedRows.length > 0 )
        switch (action){
          case 'Turn on notifications':
            updateNotificationsOnTrackers(accessToken, checkedRows, true)
            setOpenConfirmationModal(false)
            setEdit(false)
            setAction('None')
            return
          case 'Turn off notifications':
            updateNotificationsOnTrackers(accessToken, checkedRows, false)
            setOpenConfirmationModal(false)
            setEdit(false)
            setAction('None')
            return
          case 'Delete':
            setOpenConfirmationModal(false)
            setEdit(false)
            setAction('None')
            return
          default: 
            return
        }
    });
  }
  
  let allAssets = []
  if(groupBy === groupingOptions.byHomebase )
    assets.forEach(vehicle => 
      vehicle?.assets?.map(asset => 
        allAssets.push({
          asset: asset,
          vehicleName: vehicle.vehicleName,
          boxId: vehicle?.mainbox?.boxId
        })));
  else
    assets.forEach( asset => 
        allAssets.push({
          asset: asset,
          vehicleName: asset?.vehicle?.vehicleName,
          boxId: asset?.vehicle?.mainbox?.boxId
        }));
        
  let configuredAssets = allAssets.length
  let missingAssets = 0
  if(groupBy === groupingOptions.byHomebase )
    assets.forEach(vehicle => {
      missingAssets += vehicle?.assets?.filter(asset => !asset?.isInside)?.length ?? 0
    })
  else
    assets.forEach(asset =>  !asset?.isInside ?  missingAssets+= 1: missingAssets+= 0)

  let batteryIssueAssets = 0
  if(groupBy === groupingOptions.byHomebase)
    assets.forEach(vehicle => {
      batteryIssueAssets += vehicle?.assets?.filter(asset => checkTrackersBatteryIssue(asset?.tracker?.currentStatus?.batteryLevel))?.length ?? 0
    });
  else
    assets.forEach(asset =>  checkTrackersBatteryIssue(asset?.tracker?.currentStatus?.batteryLevel) ? batteryIssueAssets += 1  :  batteryIssueAssets +=  0 )

  let selectedAsset = allAssets.find(asset => asset?.asset?.tracker?.beaconId === openModal)
    

  let prepared_vehicles = []
  //TODO: FIX THE ISSUE WITH SERVER NOT SENDING ALL VEHICLES DATA
  /* vehicles.map(vehicle => {
    prepared_vehicles.push({
      boxId: vehicle.boxId,
      vehicleName: vehicle.vehicleName,
      telematicsId: vehicle.telematicsId
    })
  }) */

  const dataIsGroupedByVehicle = assets?.length && assets[0]?.mainbox

  if(dataIsGroupedByVehicle) {
    assets.map(vehicle => {
      prepared_vehicles.push({
        boxId: vehicle.mainbox?.boxId,
        vehicleName: vehicle.vehicleName,
        telematicsId: vehicle.mainbox?.telematicsId
      })
    })
  }
  else {
    assets.map(asset => {
      if(prepared_vehicles?.findIndex(v => v.vehicleName === asset.vehicle.vehicleName) <= -1)
        prepared_vehicles.push({
          boxId: asset.vehicle.mainbox?.boxId,
          vehicleName: asset.vehicle.vehicleName,
          telematicsId: asset.vehicle.mainbox?.telematicsId
        })
    })
  }
  
    /* <View 
      style= 
      {{
        display: 'flex', 
        flexDirection: 'row', 
        borderTop: '.5px solid #D5D5D5', 
        borderBottom: '.5px solid #D5D5D5', 
        backgroundColor: theme.colors.tableBackground1,
        gap: 1
      }}>
      <View>
        <SimpleButton 
          text={'Edit'}
          icon={edit ? CancelEditIcon : EditIcon}
          onClick={() => setEdit(!edit)}
          disabled={loading.fetchAssetTrackers === "loading" && loading.fetchAssetTrackers !== "failed"}
        />
      </View>

      <View>
        <SimpleDropdown 
          addOptionNone
          options={actions} 
          selectedValue={[action]}
          setValue={setAction}
          renderDefaultValue={'Actions'}
          extendInput=
            {{
              backgroundColor: theme.colors.simpleButtonBackground, 
              borderRadius: "0px",
              width: '95%',
              padding: 0,
              paddingLeft: '20px',
              paddingRight: '120px',
              paddingTop: '10px',
              paddingBottom: '10px',
              textTransform: 'none',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19px'
            }}
        /> 
      </View>
    </View> */

  const isLoading = (loading.fetchAssetTrackers === "loading") || (loading.counter_fetchingTrackersAddresses > 0)

 const viewSelector = <GroupingOptionSelector
    onChange={(selection) => {
      changeGroupBy(selection)
    }}
    options={groupingOptions}
    value={groupBy}
    disabled={loading.fetchAssetTrackers === "loading"}
  />

  if (groupBy === groupingOptions.byHomebase) {
      const tables = <AssetTrackerTable 
        setOpenModal={setOpenModal}
        searchFilter={filter}
      />
      return (
        <Fragment>
            <ReportPage
              useOldDatePickers
              disabled={disabled}
              noPadding
              noBackground
              noTimePickerBar
              onSearchChange={setFilter}
              groupingOptionSelector={viewSelector}
              searchValue={filter}
              kpi={<AssetStatusWidget
                isLoading={isLoading}
                configuredAssets={configuredAssets}
                missingAssets={missingAssets}
                batteryIssueAssets={batteryIssueAssets}
              />}
              title={"Asset Trackers"}
              noDataMessage={"There are no asset trackers."}
              tables={assets?.length ? tables : []}
              isLoading={isLoading}
              fetchingDataFailed={loading.fetchAssetTrackers === "failed"}
            >
            </ReportPage>
            <TrackerDetailsModal
              openModal={openModal}
              onClose={() => setOpenModal(null)}
              beacon={selectedAsset}
              vehicles={prepared_vehicles}
              groupBy={groupBy}
            />
        </Fragment>
      );
  }
  else {
    const tables = <AssetTrackerTableViewAll 
      setOpenModal={setOpenModal} 
      searchFilter={filter} 
      filterOption={categoryOption}
      filterAppliesToColumnIndex={3}
      setSelectedTracker={setSelectedTracker}
      selectedTracker={selectedTracker}
      edit={edit}
    />
    return (
      <Fragment>
          <ReportPage
            useOldDatePickers
            disabled={disabled}
            noPadding
            noBackground
            noTimePickerBar
            onSearchChange={setFilter}
            title={"Asset Trackers"}
            noDataMessage={"There are no asset trackers."}
            groupingOptionSelector={viewSelector}
            filterDropdowns={[<SimpleDropdown 
              disabled={loading.fetchAssetTrackers === "loading"}
              flexWidth
              addOptionNone
              options={toolsCategory} 
              selectedValue={[categoryOption]}
              setValue={setCategoryOption}
              renderDefaultValue={'Category'}
            /> ]}
            tables={assets?.length ? tables : []}
            isLoading={isLoading}
            fetchingDataFailed={loading.fetchAssetTrackers === "failed"}
            extend={{ paddingTop: 0 }}
            headerSection={
              <AssetStatusWidget
                configuredAssets={configuredAssets}
                missingAssets={missingAssets}
                batteryIssueAssets={batteryIssueAssets}
                groupBy={groupBy}
              />
            }
          >
          </ReportPage>
          <TrackerDetailsModal
            openModal={openModal}
            onClose={() => setOpenModal(null)}
            beacon={selectedAsset}
            vehicles={prepared_vehicles}
            groupBy={groupBy}
          />
          <ConfirmationModal 
            open = {openConfirmationModal}
            onClose={() => setOpenConfirmationModal(false)}
            body={
              checkedRows.length > 1
              ? "You're about to "  + action + " for " + checkedRows.length + " Asset Trackers."
              : "You're about to "  + action + " for " + checkedRows.length + " Asset Tracker."
             }
            onConfirm={() => handleConfirmation()}
            title = {'Are you sure?'}
          />
      </Fragment>
    );
  }
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({
  fetchAssetTrackers: (accessToken, byVehicle) => dispatch(fetchAssetTrackers(accessToken, byVehicle)),
  fetchVehicles: (accessToken) => dispatch(fetchVehicles(accessToken)),
  clearAssetTrackers: () => dispatch(clearAssetTrackers()),
  updateNotificationsOnTrackers: (accessToken, trackers, isEnabled) => dispatch(updateNotificationsOnTrackers(accessToken, trackers, isEnabled))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(assetTrackerStatusPage);
