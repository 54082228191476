import React, { useContext } from 'react'
import { connect } from "react-redux";
import { SimpleTableValue, TableLink } from "@modul-connect/shared/components/atoms/text/text";
import { Divider } from "@mui/material";
import {WarningIcon} from '@modul-connect/shared/components/atoms/icons/warningIcon'
import { ServiceType } from "@modul-connect/shared/utils/services";
import View from "@modul-connect/shared/components/atoms/view";
import { GroupingContext } from '../../drivingLogPage';
import { getServiceList } from '../../../../../utils/ServiceList';
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";

// TODO: need to support fetching warnings for multiple logs now ... opening another dropdown overrides the values for the previous one
const WarningDisplay = ({
  label,
  value,
  isWarning,
  showLink,
  linkTitle,
  onLinkClick,
  notSubscribedText,
  disabled,
  fetchingFailed,
}) => {
  let content
  if (disabled) content = [
    <SimpleTableValue text={notSubscribedText}/>
  ]
  else if (fetchingFailed) content = [
    <SimpleTableValue text={'Error fetching the report from the server.'}/>
  ]
  else content = [
    <SimpleTableValue text={value ?? '-'}/>,
    isWarning ? <WarningIcon size={'medium'}/> : null,
    showLink ? <TableLink extend={{ margin: 0 }} onClick={onLinkClick}>{linkTitle}</TableLink> : null,
  ]

  return (
    <View extend={{ paddingTop: 12, paddingBottom: 12, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
      <SimpleTableValue extend={{ minWidth: 150 }} text={label}/>
      {content}
    </View>
  )
}

const WarningSection = ({loading, subtrees, highestWeightPercentage, log, toll_reports}) => {
  const history = useHistory()

  const services = getServiceList({ subtrees });
  const groupBy = useContext(GroupingContext);

  const fetchingFailed_overweight = loading?.fetchWarningLogs === 'failed'
  const fetchingFailed_toll = loading?.fetchTollReport === 'failed'

  const hasServiceWeightReports = services?.hasService(ServiceType.WeightReports)
  const hasServiceTollServices = services?.hasService(ServiceType.TollPassages)

  const overweight = (highestWeightPercentage >= 100) ? highestWeightPercentage.toFixed() : null

  return (
    <View
      extend={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Divider />
      <WarningDisplay
        fetchingFailed={fetchingFailed_overweight}
        disabled={!hasServiceWeightReports}
        notSubscribedText={'Only available for Overweight Report subscribers'}
        label={"Weight:"}
        value={overweight && overweight + "%"}
        isWarning={highestWeightPercentage >= 100}
        showLink={highestWeightPercentage >= 100}
        linkTitle={"Weight Report for route"}
        onLinkClick={() => { 
          if (log.boxId && log.startTime) {
            history.replace({
              search: '',
            })
            history.push('/reports/reports_weight-reports/' + log.boxId + '/' + log.startTime) }}
          }
      />
      <Divider />
      <WarningDisplay
        fetchingFailed={fetchingFailed_toll}
        disabled={!hasServiceTollServices}
        notSubscribedText={'Only available for Toll Report subscribers'}
        label={"Toll Passages:"}
        value={toll_reports.total}
        showLink={toll_reports.total > 0}
        linkTitle={"Toll Report for route"}
        onLinkClick={() => {            
          if (log.boxId && log.startTime) {
            const startDT = DateTime.fromISO(log.startTime)
            history.replace({
              search: '',
            })
            history.push({
              pathname: '/reports/reports_toll-reports/' + log.boxId + '-' + startDT.month + '_' + startDT.year,
              search: '?groupby=' + groupBy
            })
          }
        }}
      />
      <Divider />
    </View>
  );
}

const mapStateToProps = ({
  loading,
  subtrees,
  toll_reports
}) => ({
  loading,
  subtrees,
  toll_reports
})

export default connect(mapStateToProps)(WarningSection);
