import { redirectUri } from "./adalConfig/adalConfig"
import { environment as node_env } from '../env.js'

// Feature-toggle that hides UI elements pertaining to humidity.
// Set to false to display them again.
export const isHumiditySensorBroken = false

export const serverURL = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-poc.azurewebsites.net'
    //return 'http://localhost:5000'
  } else {
    return 'https://mc-prod.azurewebsites.net'
  }
}

export const backofficeCommandURL = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-backoffice-poc.azurewebsites.net'
    //return 'https://localhost:5004'
  } else {
    return 'https://mc-backoffice-command.azurewebsites.net'
  }
}

export const backofficeQueryURL = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-backoffice-poc-query.azurewebsites.net'
//    return 'https://localhost:5003'
  } else {
    return 'https://mc-backoffice-query.azurewebsites.net'
  }
}

export const auth0Domain = () => {
  if (!node_env || node_env === 'development') {
    return 'dev-rzl4knic.eu.auth0.com'
  } else {
    return 'modul-system.eu.auth0.com'
  }
}

export const auth0ClientId = () => {
  if (!node_env || node_env === 'development') {
    return '9647lAC2SX539ql14jqyWPePKIU0vUvk'
  } else {
    return 'EEfXyOOGYmktvHP8LJJMXIPwGCyjK7rd'
  }
}

export const auth0ClientId_backoffice = () => {
  if (!node_env || node_env === 'development') {
    return '25w8tmwN9w0S0ze0Ot5577ThcJypnMJ2'
  } else {
    return 'bb2yCoDJfgyAJzgmxo0zCBNgByAfXTbR'
  }
}

export const auth0_params = {
  redirectUri: redirectUri(),
  audience: "api.modulconnect.com.customer",
};

export const auth0_params_backoffice = {
  redirectUri: redirectUri(),
  audience: "api.modulconnect.com.backoffice",
}

export const customerCommandServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-customer-poc.azurewebsites.net'
    // return 'https://localhost:5001'
  } else {
    return 'https://mc-customer-cmd.azurewebsites.net'
  }
}

export const assetsCommandServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-assets-poc-command.azurewebsites.net'
    //return 'https://localhost:5001'
  } else {
    return 'https://mc-assets-command.azurewebsites.net'
  }
}

export const customerServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-customer-poc-query.azurewebsites.net'
    //return 'https://localhost:5004'
  } else {
    return 'https://mc-customer.azurewebsites.net'
  }
}

export const notificationsServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-notifications-database-poc.azurewebsites.net/'
    //return 'https://localhost:5006'
  } else {
    return 'https://mc-notifications-database.azurewebsites.net/'
  }
}

export const firmwareServerUrl = () => {
  if (!node_env || node_env === 'development') {
    return 'https://mc-utils-poc.azurewebsites.net'
    // return 'https://localhost:5001'
  } else {
    return 'https://mc-utils.azurewebsites.net'
  }
}

export const redirectUri_logout = () => {
  const logoutLandingPagePath = 'logout'

  return window.location.pathname === '/' ? window.location.href + logoutLandingPagePath 
    : window.location.href.replace(window.location.pathname, '/' + logoutLandingPagePath)
}
