import React from "react";
import { styled } from "@mui/material/styles";
import theme from "@modul-connect/shared/theme";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import "./timespanPickers.css";
import { isSameDay } from "@modul-connect/shared/utils/dateTimeUtils";

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay" && prop !== "isHover" && prop !== "isFirstHover" && prop !== "isLastHover",
})(({ dayIsBetween, isFirstDay, isLastDay, isHover, isFirstHover, isLastHover }) => ({
  ...(isHover && !dayIsBetween) && {
    backgroundColor: `${theme.colors.midGrey} !important`,
    borderRadius: '0px !important',
  },
  ...(dayIsBetween && {
    borderRadius: '0px !important',
    backgroundColor: `${theme.colors.primaryDark} !important`,
    color: `${theme.colors.white} !important`,
  }),
  ...((isFirstDay || isFirstHover) && {
    borderTopLeftRadius: "50% !important",
    borderBottomLeftRadius: "50% !important",
  }),
  ...((isLastDay || isLastHover) && {
    borderTopRightRadius: "50% !important",
    borderBottomRightRadius: "50% !important",
  }),
}));


export const WeekPicker = ({
  startDate,
  setSelectedStartTime,
  setSelectedEndTime,
  disabled,
}) => {
  const [hoverDay, setHoverDay] = React.useState(null)

  function Day(props) {
    const { day, selectedDay, ...other } = props;
  
    if (selectedDay == null) {
      return <PickersDay day={day} {...other} />;
    }
  
    const start = selectedDay.startOf("week");
    const end = selectedDay.endOf("week");
  
    const dayIsBetween = day >= start && day <= end;
    const isFirstDay = isSameDay(day, start)
    const isLastDay = isSameDay(day, end)

    const hoverStart = hoverDay?.startOf('week')
    const hoverEnd = hoverDay?.endOf('week')
    const isHover = hoverDay && (day >= hoverStart && day <= hoverEnd)
    const isFirstHover = hoverStart && isSameDay(day, hoverStart)
    const isLastHover = hoverEnd && isSameDay(day, hoverEnd)
  
    return (
      <CustomPickersDay
        {...other}
        day={day}
        onMouseEnter={() => { setHoverDay(day) }}
        onMouseLeave={() => { setHoverDay(null) }}
        sx={(dayIsBetween || isHover) ? { px: 2.5, mx: 0 } : {}}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isHover={isHover}
        isFirstHover={isFirstHover}
        isLastHover={isLastHover}
      />
    );
  }

  return (
    <DateCalendar    
      disableFuture
      displayWeekNumber
      value={startDate}
      onChange={(newValue) => {
        const startOfWeek = newValue.startOf("week");
        const endOfWeek = newValue.endOf("week");

        setSelectedStartTime(startOfWeek);
        setSelectedEndTime(endOfWeek);
      }}
      slots={{ day: Day }}
      slotProps={{
        day: {
          selectedDay: startDate,
        },
      }}
    />
  );
};
