import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  fetchVehiclesData,
  fetchOverweightReport,
  fetchDrivingLogs,
  fetchDrivingLogDetails,
} from '../../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import { DateTime } from "luxon";
import WeightReportDetails from '../weightReportDetails/weightReportDetails';
import { useParams } from 'react-router-dom';
import WeightReportSummary from '../weightReportSummary/weightReportSummary';
import { getServiceList } from '../../../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';
import { dateTimeToEpoch } from '@modul-connect/shared/utils/dateTimeUtils';

const getSelectedDay = (detailsId) => {
  if (!detailsId) return undefined
  const split = detailsId.split('-')
  if (!split || split.length < 3) return undefined
  const year = parseInt(split[0])
  const month = parseInt(split[1])
  const day = parseInt(split[2].split('T')[0])
  return new DateTime.local(year, month, day)
}

const WeightReportPage = ({
  selected_organisations,
  vehicles_data,
  services,
  loading,
  fetchVehiclesData,
  fetchOverweightReport,
  fetchDrivingLogs,
  fetchDrivingLogDetails,
  driving_logs,
  driving_log_details,
  overweight_report,
  subtrees,
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const [startDateDetails, setStartDateDetails] = useState(DateTime.local().startOf('month'))
  const [endDateDetails, setEndDateDetails] = useState(DateTime.local())
  const [selectedVehicle, setSelectedVehicle] = useState(null)

  const [detailsLogVehicleId, setDetailsLogVehicleId] = useState(null)

  const { summaryId } = useParams()
  const { detailsId } = useParams()

  const [showDetailedLog, setShowDetailedLog] = useState(detailsId ? true : false)
  const [detailedDrivingLog, setDetailedDrivingLog] = useState(null)
  const [detailedDrivingLogInfo, setDetailedDrivingLogInfo] = useState(null)

  const disabled = !getServiceList({subtrees}).hasService(ServiceType.WeightReports, ServiceType.DrivingLogs)

  const logIdRef = useRef()

  useEffect(() => {
    setShowDetailedLog(detailsId ? true : false)

    if (detailsId && summaryId && services.hasService(ServiceType.WeightReports) && services.hasService(ServiceType.DrivingLogs)) {
      const selectedDay = getSelectedDay(detailsId)

      const start = selectedDay.startOf('day')
      const end = selectedDay.endOf('day')

      let boxId, driverId
      if (summaryId.startsWith('auth0')) { // at the moment, this should never be the case. Once this page supports groupBy, should use that, like we do for the driving log report.
        driverId = summaryId ? [summaryId.split('-')[0]] : null
        boxId = null
      } else {
        driverId = null
        boxId = summaryId ? summaryId.split('-')[0] : null
      }

      logIdRef.current = boxId + '_' + dateTimeToEpoch(start)

      getAccessTokenSilently().then(accessToken => {
        fetchDrivingLogs(accessToken, boxId ? boxId : null, driverId, null, start, end)
        fetchDrivingLogDetails(accessToken, start, end, boxId, driverId )
      })
    }
  }, [detailsId])

  useEffect(() => {
    setDetailsLogVehicleId(summaryId ? summaryId.split('-')[0] : null)
  }, [summaryId])

  useEffect(() => {
    const selectedDay = getSelectedDay(detailsId)

    getAccessTokenSilently().then(accessToken => {
      const start = selectedDay ? selectedDay.startOf('day') : (startDateDetails ?? null)
      const end = selectedDay ? selectedDay.endOf('day') : (endDateDetails ?? null)
      fetchOverweightReport(accessToken, start, end, selectedVehicle ?? detailsLogVehicleId)
    })
  }, [startDateDetails, endDateDetails, selectedVehicle, detailsId, detailsLogVehicleId,selected_organisations])

  useEffect(() => {
    if ((!vehicles_data || !vehicles_data.data || vehicles_data.data.length === 0)) {
      getAccessTokenSilently().then(fetchVehiclesData)
    }
  }, [selected_organisations])

  useEffect(() => {
    if (
      summaryId && detailsId &&
      driving_logs?.[summaryId]?.length &&
      driving_log_details && Object.keys(driving_log_details)?.length
    ) {
      const boxId = summaryId.split('-')[0]
      const warningStart = detailsId && new DateTime.fromISO(detailsId)

      const driving_log = driving_logs?.[summaryId]?.find(x => {
        const logStartTime = new DateTime.fromISO(x.startTime)
        const logEndTime = new DateTime.fromISO(x.endTime)
        return logStartTime <= warningStart && logEndTime >= warningStart && x.boxId === boxId
      })

      const relevantLogs = driving_log_details[boxId  + '_' + dateTimeToEpoch(warningStart.startOf('day'))]
      const details_route = relevantLogs?.find(x => x.boxId === boxId && x.startTime === driving_log?.startTime)

      setDetailedDrivingLog(driving_log)
      setDetailedDrivingLogInfo({
        route: details_route,
      })
    } else if (
      summaryId && detailsId &&
      overweight_report.data?.logs?.length
    ) {
      const warningStart = new DateTime.fromISO(detailsId)
      const boxId = summaryId.split('-')[0]

      const driving_log = overweight_report.data.logs?.find(x => {
        const logStartTime = new DateTime.fromISO(x.startTime)
        const logEndTime = new DateTime.fromISO(x.endTime)

        return logStartTime <= warningStart && logEndTime >= warningStart && x.boxId === boxId
      })
      setDetailedDrivingLog(driving_log)
    }
  }, [driving_logs, overweight_report, driving_log_details, loading])

  return (
    <React.Fragment>
      {
        showDetailedLog
          ? <WeightReportDetails
            vehicle={vehicles_data?.data?.find(v => v.mainbox_id === detailsLogVehicleId)}
            drivingLog={detailedDrivingLog}
            drivingLogDetails={detailedDrivingLogInfo}
            disabled={disabled}
            warningLog={overweight_report?.data?.logs?.length ? overweight_report?.data?.logs?.find(w => w.startTime === detailsId) : null}
            isLoading={
              loading.fetchWeightReport === 'loading' ||
              loading.fetchVehiclesData === 'loading' ||
              Object.keys(loading.fetchDrivingLogDetails)?.length && loading.fetchDrivingLogDetails[logIdRef.current] === 'loading' // TODO!
            }
            warningTime={detailsId}
          />
          : <WeightReportSummary
            startDate={startDateDetails}
            endDate={endDateDetails}
            disabled={disabled}
            onStartDatePicked={(date) => { setStartDateDetails(date.startOf('day')) }}
            onEndDatePicked={(date) => { setEndDateDetails(date.endOf('day')) }}
            onVehicleSelected={setSelectedVehicle}
          />
      }
    </React.Fragment>
  )
}

const mapStateToProps = (props) => ({ ...props, services: getServiceList(props) });

const mapDispatchToProps = dispatch => ({
  fetchDrivingLogs: (accessToken, boxId, driverId, deviceType, start, end) => dispatch(fetchDrivingLogs(accessToken, boxId, driverId, deviceType, start, end)),
  fetchVehiclesData: (accessToken) => dispatch(fetchVehiclesData(accessToken)),
  fetchOverweightReport: (accessToken, start, end, boxId) => dispatch(fetchOverweightReport(accessToken, start, end, boxId)),
  fetchDrivingLogDetails: (accessToken, start, end, boxId ) => dispatch(fetchDrivingLogDetails(accessToken, start, end, boxId ))
});

export default connect(mapStateToProps, mapDispatchToProps)(WeightReportPage);