import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import StartEndMonthPicker from '../../datePickers/startEndMonthPicker/startEndMonthPicker'
import { DateTime } from 'luxon'
import { useAuth0 } from '@auth0/auth0-react'
import { downloadTollReport, fetchTollReportSummaries, fetchVehiclesData } from '../../../../state/actions/vehicles'
import TollPassageSummaryTable from './tollPassageSummaryTable'
import { GroupingOptionSelector } from './groupingOptionSelector'
import { dateTimeToString } from '../../../../../../shared/utils/dateTimeUtils'
import columns_byVehicle from './columns/tollSummaryByVehicleColumns'
import columns_byDriver from './columns/tollSummaryByDriverColumns'
import DateSelectionWidget from "@modul-connect/shared/components/molecules/dateSelectionWidget"
import ReportPage from '../../reportPage.v2';
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers'

const TollPassagesSummaryPage = ({
  selected_organisations,
  loading,
  fetchTollReportSummaries,
  toll_reports,
  downloadTollReport,
  onSummaryClicked,
  fetchVehiclesData,
  vehicles_data,
  downloading,
  groupBy,
  setGroupBy,
  disabled,
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const [startDate, setStartDate] = useState(DateTime.local().minus({ months: 2 }).startOf('month'))
  const [endDate, setEndDate] = useState(DateTime.local().endOf('month'))

  const groupingOptions = {
    byVehicle: 'vehicle',
    byDriver: 'driver'
  }

  useEffect(() => {
    getAccessTokenSilently().then(accessToken => {
      const start = startDate && startDate.startOf('month')
      const end = endDate && endDate.endOf('month')
      fetchTollReportSummaries(accessToken, start, end, groupBy)
    })
  }, [startDate, endDate, groupBy, selected_organisations])

  const onDownloadTollReport = async (boxOrDriverId, startDate, endDate) => {
    const boxId = boxOrDriverId.indexOf('|') >= 0 ? null : boxOrDriverId
    const driverId = boxOrDriverId.indexOf('|') >= 0 ? boxOrDriverId : null
    const accessToken = await getAccessTokenSilently()
    downloadTollReport(accessToken, boxId, driverId, startDate, endDate, groupBy ?? groupingOptions.byVehicle)
  }

  const [tableData, setTableData] = useState({})

  useEffect(() => {
    getAccessTokenSilently().then(fetchVehiclesData)
  }, [selected_organisations])

  useEffect(() => {
    if (!toll_reports?.summary?.data)
      return

    const summaries_prepares = prepareSummariesForTable(toll_reports.summary.data, vehicles_data)

    setTableData(summaries_prepares)

  }, [toll_reports, vehicles_data])

  const prepareSummariesForTable = useCallback((perMonth, vehicles_data) => {
    if (!perMonth) return []
    let prepared_data = {}

    for (const monthlySummary of perMonth) {
      let month = monthlySummary.key
      let [y, m] = month.split('-')
      let monthDT = new DateTime.local().set({ month: m, year: y })

      const summary = Object.keys(monthlySummary.value).map(id => {
        const vehicle = vehicles_data?.data?.find(y => y.mainbox_id === id)
        const entry = monthlySummary.value[id]
        return {
          // TODO: id contains the date, so we can fetch the right logs from the next page and on reloading. We should indead use a URL parameter like groupBy
          id: `${(groupBy === groupingOptions.byDriver && (!id || id.toLowerCase() === 'unknown') ? 'none' : id)}-${m}_${y}`,
          vehicleName: vehicle?.vehicleName ?? (vehicle?.vehicle.baseVehicleName ?? '-'),
          driver: entry.driverName?.toLowerCase() === 'unknown' ? 'No driver assigned' : entry.driverName,
          stations: entry.numberOfStations,
          charge: entry.mixedCurrency ? 'mixed currency' : entry.totalCharge, // TODO: compute total charge for mixed currencies?,
          currency: entry.mixedCurrency ? 'mixed' : (entry.charges?.length ? entry.charges[0].currency : '-'),
          date: dateTimeToString(monthDT, 'month-and-year')
        }
      })

      const sortFunction = groupBy === groupingOptions.byDriver
        ? (a, b) => a.driver > b.driver
        : (a, b) => a.vehicleName > b.vehicleName
      summary.sort(sortFunction)

      for (const entry of summary) {
        if (!(prepared_data[entry.date]?.length || prepared_data[entry.date]?.length === 0)) {
          prepared_data[entry.date] = []
        }
        prepared_data[entry.date].push(entry)
      }
    }
    return prepared_data
  }, [groupBy])

  const isLoading = loading.fetchTollReportSummaries === 'loading'

  const dateSelectionComponent = <DateSelectionWidget
    datePickerComponent={
      <StartEndMonthPicker
        disabled={isLoading}
        startDate={startDate}
        endDate={endDate}
        onStartMonthPicked={setStartDate}
        onEndMonthPicked={setEndDate}
      />
    }
    rightHandSide={
      <GroupingOptionSelector
        onChange={(selection) => setGroupBy(selection)}
        options={groupingOptions}
        value={groupBy}
        disabled={isLoading} />
    }
  />

  return (
    <ReportPage
      title={'Toll Passages'}
      timeSelectors={[timespanOptions.month]}
      useOldDatePickers
      startDate={startDate}
      endDate={endDate}
      noSearch
      onStartDatePicked={setStartDate}
      onEndDatePicked={setEndDate}
      isLoading={isLoading}
      noDataMessage={'There are no toll passages for the selected time period.'}
      disabled={disabled}
      fetchingDataFailed={loading.fetchTollReportSummaries === 'failed'}
      isPreparingFile={downloading?.toll_report && downloading.toll_report.boxId && downloading.toll_report.status === 'working'}
      downloadFailed={downloading?.toll_report && downloading.toll_report.boxId && downloading.toll_report.status === 'failed'}
      tables={Object.keys(tableData)?.map(key => {
          return <TollPassageSummaryTable
            tableTitle={key}
            key={key}
            onSummaryClicked={onSummaryClicked}
            onDownloadTollReport={onDownloadTollReport}
            tableData={tableData[key]}
            columns={groupBy === groupingOptions.byDriver ? columns_byDriver : columns_byVehicle}
          />
        })
      }
    />
  )
}

const mapStateToProps = ({
  selected_organisations,
  loading,
  toll_reports,
  vehicles_data,
  downloading,
}) => ({
  selected_organisations,
  loading,
  toll_reports,
  vehicles_data,
  downloading,
})

const mapDispatchToProps = dispatch => ({
  fetchTollReportSummaries: (accessToken, start, end, groupBy) => dispatch(fetchTollReportSummaries(accessToken, undefined, start, end, groupBy)),
  downloadTollReport: (accessToken, boxId, userId, start, end, groupBy) => dispatch(downloadTollReport(accessToken, boxId, userId, start, end, groupBy)),
  fetchVehiclesData: accessToken => dispatch(fetchVehiclesData(accessToken))
})

export default connect(mapStateToProps, mapDispatchToProps)(TollPassagesSummaryPage)
