import React, { useEffect } from "react"
import { Route, BrowserRouter as Router} from "react-router-dom"
import { getRoutes } from '../../routes'
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import View from "@modul-connect/shared/components/atoms/view"
import NavBar from "@modul-connect/shared/components/organisms/nav-bar"
import { connect } from 'react-redux'
import { BubbleLoader } from 'react-css-loaders'
import theme from "@modul-connect/shared/theme"
import { H3, H1, H4 } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button'
import { acceptUserTerms, fetchAcceptedUserTerms, fetchUserTerms, hideFailBar, hideSuccessBar } from "../../state/actions/app"
import { Fade, Modal } from "@material-ui/core"
import { Alert, Snackbar } from '@mui/material';
import modal from '@modul-connect/shared/components/atoms/modal'
import { DateTime } from "luxon"
import FileViewer from 'react-file-viewer'
import { setSelectedOrganisations, appStarted } from "../../state/actions/customer"
import { showFilterBar } from "../../utils/app"
import { sec } from '@modul-connect/shared/auth0/auth0Helper'
import { ServiceType } from "@modul-connect/shared/utils/services"
import { getServiceList } from "../../utils/ServiceList"
import { fetchNotifications, markNotificationsSeen } from "../../state/actions/notifications"

function datetimeToReadable(dateTime) {
  return DateTime.fromISO(dateTime).toLocal().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

let nFetched = 0
const PortalLoggedIn = ( {
  authenticated_user, 
  services,
  appStarted,
  themes, 
  userTerms, 
  acceptedUserTerms, 
  fetchUserTerms, 
  fetchAcceptedUserTerms, 
  acceptUserTerms, 
  subtrees, 
  organisationNames, 
  setSelectedOrganisations, 
  showWarningBar,
  showSuccessBar,
  hideFailBar,
  hideSuccessBar,
  loadingSubtrees,  
  notifications,
  fetchNotifications,
  markNotificationsSeen,
 } ) => {
    const { user } = useAuth0()
    const [isLoading, setIsLoading] = React.useState(true)
    const [hasAcceptedUserTerms, setHasAcceptedUserTerms] = React.useState(false)
    const [hasReachedBottom, setHasReachedBottom] = React.useState(false)
    const [didPressConsent, setDidPressConsent] = React.useState(false)

    const [routes, setRoutes] = React.useState([])
    
    const {
      isAuthenticated,
      getAccessTokenSilently,
    } = useAuth0();
    if (isAuthenticated) {
      sec.setAccessTokenSilently(getAccessTokenSilently);
    }

    const [showWarning, setShowWarning] = React.useState(null)
    const [showSuccess, setShowSuccess] = React.useState(null)

    useEffect(() => {
      if (loadingSubtrees) return
      
      const geoLocationNotAllowed = services.hasService(ServiceType.mapAccessNoGeo)
      setRoutes(getRoutes(geoLocationNotAllowed))
    }, [services, loadingSubtrees])

    useEffect(() =>{
      setShowWarning(showWarningBar.showBar)
    }, [showWarningBar])

    useEffect(() => {
      setShowSuccess(showSuccessBar.showBar)
    }, [showSuccessBar])

    React.useEffect(() => {
      getAccessTokenSilently().then(token => {
        fetchUserTerms(token)
      })
    }, [getAccessTokenSilently, fetchUserTerms])

    useEffect(() => {
      getAccessTokenSilently().then(token => {
        fetchNotifications(token)
      })
    }, [getAccessTokenSilently])

    React.useEffect(() => {
      if (userTerms == null) return
      if (acceptedUserTerms?.version === userTerms.version)
        setHasAcceptedUserTerms(true)
      else if (nFetched === 1 && !didPressConsent && !loadingSubtrees)
        setIsLoading(false)
      else if (!(nFetched % 2)) {
        nFetched++
        getAccessTokenSilently().then(token => fetchAcceptedUserTerms(token))
      }
      else setTimeout(() => {
        nFetched++
        setDidPressConsent(!didPressConsent)
      }, 2000)
    }, [getAccessTokenSilently, userTerms, fetchAcceptedUserTerms, acceptedUserTerms, didPressConsent, loadingSubtrees])

    React.useEffect(() => {
      if (!hasAcceptedUserTerms) return

      getAccessTokenSilently().then(token =>
        appStarted(token, user.email)
      )
    }, [hasAcceptedUserTerms, appStarted, user.email])

    const handleScroll = event => {
      if (hasReachedBottom) return
      
      const element = event.target
      if (element.scrollHeight - element.scrollTop - element.clientHeight < 100) {
        setHasReachedBottom(true)
      }
    }

    const consent = () => {
      setDidPressConsent(true)
      setIsLoading(true)
      getAccessTokenSilently().then(token => acceptUserTerms(token))
    }

    return (
      <Router>
      <View extend={styles.root}>
        <NavBar
          showFilterBar={showFilterBar}
          routes={routes}
          authTool='auth0'
          username={authenticated_user ? authenticated_user.firstName : null}
          themes={ themes }
          organisationTrees={ subtrees }
          organisationNames={ organisationNames }
          setSelectedOrganisations={ setSelectedOrganisations }
          notifications={notifications}
          setSeenNotifications={(notificationType, notificationIds) => notificationIds?.length ?  getAccessTokenSilently().then(token => markNotificationsSeen(token, notificationType, notificationIds)) : null}
        />

        <SnackbarAlert open={showWarning} onHide={hideFailBar} severity={showWarningBar?.alertType ?? "error"} message={showWarningBar?.message} />
        <SnackbarAlert open={showSuccess} onHide={hideSuccessBar} severity={"success"} message={showSuccessBar?.message}/>

        <View>
          {
            hasAcceptedUserTerms && subtrees.total > 0 ?
              routes.map(route => (
                <Route
                  path={route.url}
                  exact={!!route.exact}
                  key={route.url}
                  render={() => <route.component/>}
                />
              ))
            : isLoading ?
            <View extend={styles.loaderContainer}>
              <BubbleLoader
                color={ theme.colors.primary }
                size={ 5 }
              />
              <H3>Fetching user data...</H3>
            </View>
            :
            <Modal
              open  
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Fade in>
                <div>
                  <View extend={ [ modal.container_wide, styles.userTermsModal] }>
                    <View extend={ modal.header }>
                      <H1 text={ 'User terms & conditions' }/>
                    </View>
                    {/* <View extend={ modal.content }> */}
                      {/* <View> */}
                        
                      {/* </View> */}
                      <View extend={ [modal.content, styles.userTerms] } onScroll={handleScroll}>
                        {/* <P>Terms {'&'} conditions were updated at: {datetimeToReadable(userTerms.version)}</P> */}
                        <H4>{DateTime.fromISO(userTerms.requiredFrom) < DateTime.now().toUTC() ? 'In order to continue you need to consent to the terms of service' : `Consent required by: ${datetimeToReadable(userTerms.requiredFrom)}`}</H4>
                        <FileViewer
                          fileType='docx'
                          filePath={userTerms.link}
                        />
                      </View>
                    {/* </View> */}
                    <View extend={ modal.footer }>
                      { DateTime.fromISO(userTerms.requiredFrom) > DateTime.now().toUTC() &&
                        <Button noBg onClick={() => setHasAcceptedUserTerms(true)}>
                          Skip
                        </Button>
                      }
                      <Button noBg disabled={!hasReachedBottom} onClick={consent}>
                        Consent
                      </Button>
                    </View>
                  </View>
                </div>
              </Fade>
            </Modal>
          }
        </View>
      </View>
      </Router>
    )
}

const SnackbarAlert = ({ open, onHide, severity, message }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => onHide()}
    >
      <Alert
        onClose={() => onHide()}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const styles = {
    userTerms: {
      '> .pg-viewer-wrapper': {
        overflow: 'hidden',
        height: 'fit-content',
        '& td': {
          borderBottom: 0,
          borderRight: 0
        },
        '& .document-container': {
          width: '100%',
        },
        '& p, li': {
          fontSize: 14,
        },
        '& ol': {
          marginLeft: 0,
        }
      },
    },
    root: ({ theme: { colors } }) => ({
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: colors.white,
      overflowY: 'hidden'
    }),
    loaderContainer: ({ theme: { colors, layout } }) => ({
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      })
  }

  const mapStateToProps = ({
    userTerms,
    acceptedUserTerms,
    authenticated_user,
    themes,
    subtrees,
    organisationNames,
    showWarningBar,
    showSuccessBar,
    loading,
    notifications
  }) => ({
    userTerms,
    acceptedUserTerms,
    authenticated_user,
    themes,
    subtrees,
    organisationNames,
    showWarningBar,
    showSuccessBar,
    services: getServiceList({subtrees: subtrees}),
    loadingSubtrees: loading?.fetchSubtrees,
    notifications
  })

  const mapDispatchToProps = dispatch => ({
    appStarted: (token, email) => dispatch(appStarted(token, email)),
    fetchUserTerms: accessToken => dispatch(fetchUserTerms(accessToken)),
    fetchAcceptedUserTerms: accessToken => dispatch(fetchAcceptedUserTerms(accessToken)),
    acceptUserTerms: accessToken => dispatch(acceptUserTerms(accessToken)),
    setSelectedOrganisations: uuids => dispatch(setSelectedOrganisations(uuids)),
    hideFailBar: () => dispatch(hideFailBar()),
    hideSuccessBar: () => dispatch(hideSuccessBar()),
    fetchNotifications: (accessToken) => dispatch(fetchNotifications(accessToken)),
    markNotificationsSeen: (accessToken, notificationType, notificationIds) => dispatch(markNotificationsSeen(accessToken, notificationType, notificationIds)),
  })

export default connect(mapStateToProps, mapDispatchToProps) (withAuthenticationRequired(
    PortalLoggedIn,
    { onRedirecting: () => <LoadingDisplay/> }))

export const LoadingDisplay = () => {
  return <View extend={styles.loaderContainer}>
  <BubbleLoader
    color={ theme.colors.primary }
    size={ 5 }
  />
  <H3></H3>
</View>
}