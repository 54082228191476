import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation} from 'react-router-dom';
import { getServiceList } from "../../../utils/ServiceList";
import { ServiceType } from "@modul-connect/shared/utils/services";
import TrackerMaintenance from './subPages/maintenance/maintenance'
import AssetTrackerStatusPage_v2 from "./subPages/trackers/assetTrackerStatusPage_v2";


export const subPageOptions = {
  maintenance: 'maintenance',
  trackers: 'trackers'
}

const AssetsReportPage_v2 = ({ subtrees }) => {

  const history = useHistory()
  const location = useLocation();

  useEffect(()=>{
    if(subPage !== subPageOptions.trackers && subPage !== subPageOptions.maintenance)
    {
      history.push({ 
        pathname:'/reports/reports_asset-trackers/' + subPageOptions.trackers,})
      setSubPage(subPageOptions.trackers)
    }
  }, [])

  useEffect(() =>{
    setSubPage(location.pathname.split('/')[location.pathname.split('/').length - 1])
  }, [location])

  const [subPage, setSubPage] = useState( useLocation().pathname.split('/')[useLocation().pathname.split('/').length - 1] )


  const disabled = !getServiceList({ subtrees }).hasService(ServiceType.AssetTrackers);
  const hasNotMaintenanceService = !getServiceList({ subtrees }).hasService(ServiceType.MaintenanceAccess);


  const { getAccessTokenSilently } = useAuth0();


   return (
      subPage == subPageOptions.trackers ?
      <AssetTrackerStatusPage_v2
      disabled={disabled}
      />
      : 
      <TrackerMaintenance disabled={hasNotMaintenanceService}/>
   )
};

const mapStateToProps = (props) => props;

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AssetsReportPage_v2);
