import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import { P, H1, H3 } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button'
import { DateTime } from 'luxon'
import { convertToSelectedUnit, getDistanceUnit } from '../../../../utils/unitConverter'
import { useHistory } from 'react-router-dom'
import { fetchVehicleWeights } from '../../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import { SpinLoader } from 'react-css-loaders'
import theme from '@modul-connect/shared/theme'
import { weightIssue, weightWarning } from '../../../../utils/vehicleUtils'
import { RouteMarker } from '../../sidebars/details/components/routeMarker'
import chroma from 'chroma-js'
import StatusDisplay from '@modul-connect/shared/components/atoms/statusDisplay'


const RoutePopup = ({
  pointProps: {
    title,
    boxId,
    startTime,
    endTime
  },
  date,
  vehicles,
  weight_logs,
  warning_logs,
  fetchVehicleWeights,
}) => {
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const vehicleRef = useRef(vehicles.find(v => v.boxId === boxId))
  const routeRef = useRef(vehicleRef.current?.geoJSON.routes[DateTime.fromISO(startTime).toISODate()]?.[startTime])

  const startRef = useRef(DateTime.fromISO(startTime))
  const endRef = useRef(endTime && DateTime.fromISO(endTime))

  const [cargoLoad, setCargoLoad] = useState(null)
  const [totalLoad, setTotalLoad] = useState(null)
  const [frontLoad, setFrontLoad] = useState(null)
  const [rearLoad, setRearLoad] = useState(null)
  const [hasOverweightWarning, setHasOverweightWarning] = useState(false)

  useEffect(() => {
    const maxWeight = vehicleRef.current?.vehicle.maxWeight
    if (!maxWeight || cargoLoad && cargoLoad !== 'loading') return

    let overweightLog
    let overweightLogs = warning_logs?.data?.overweightWarnings?.filter(log => {
      const timestamp = DateTime.fromISO(log.startTime)
      return boxId == log.boxId && timestamp >= startRef.current && (!endRef.current || timestamp <= endRef.current)
    })

    if(overweightLogs?.length) {
      overweightLog = overweightLogs?.pop()
      setTotalLoad(overweightLog.ratioGross)
      setFrontLoad(overweightLog.ratioFront)
      setRearLoad(overweightLog.ratioRear)
    } 
    else {
      return
      // let relevant = warning_logs?.data?.overweightWarnings?.filter(log => {

      //   console.warn('log', log)
      //   console.warn('log2', startRef.current)
        
      //   const timestamp = DateTime.fromISO(log.startTime)
      //   console.warn('log2', timestamp >= startRef.current && boxId == log.boxId)
      //   console.warn('log2', timestamp < endRef.current && boxId == log.boxId)
      //   return boxId == log.boxId && timestamp >= startRef.current && (!endRef.current || timestamp <= endRef.current)
      // })
    }

    



    // if (!relevant?.length) {
    //   getAccessTokenSilently().then(accessToken =>
    //     fetchVehicleWeights(accessToken, startRef.current, endRef.current, boxId)
    //   )
    //   setCargoLoad('loading')
    // }
    // else {
      // let overweightLog = relevant?.pop()

      // if (!overweightLog?.length) {
      //   setCargoLoad(null)
      //   return
      // }

      // let highest = { gross: 0 }
      // relevant.forEach(log => {
      //   if (log.gross > highest.gross)
      //     highest = log
      // })

      // let overweightValue = overweightLog.ratioGross
      // if(!overweightLog.hasOverweightGross && overweightLog.hasOverweightFront) {
      //   overweightValue = overweightLog.ratioFront
      // } else if(overweightLog.hasOverweightRear) {
      //   overweightValue = overweightLog.ratioRear
      // } 

      // setCargoLoad(overweightLog.ratioGross)
      setTotalLoad(overweightLog.ratioGross)
      setFrontLoad(overweightLog.ratioFront)
      setRearLoad(overweightLog.ratioRear)

      console.warn(overweightLog.ratioGross)
    // }
  }, [weight_logs])

  useEffect(() => {
    const vehicle = vehicles.find(v => v.boxId === boxId)
    if ((vehicle?.warning_logs ? vehicle?.warning_logs[date]?.overweightWarnings : [])?.some(warning => {
      const timestamp = DateTime.fromISO(warning.startTime)
      return timestamp >= startRef.current && (!endRef.current || timestamp <= endRef.current)
    }))
      setHasOverweightWarning(true)
  }, [warning_logs])

  const goToDetailsPage = () =>
    history.push(`/reports/reports_driving-logs/${boxId}/${startTime}`)

  return (
    <View style={{ padding: 5 }}>
      <View extend={styles.header}>
        <View style={styles.title}>
          <RouteMarker text={title} />
          <H1 noPadding text={`Route`} />
        </View>
        <View style={styles.name}>
          <H3 noPadding>{vehicleRef.current?.vehicleName ?? vehicleRef.current?.vehicle.baseVehicleName ?? boxId}</H3>
        </View>
      </View>
      <View extend={styles.content}>
        {cargoLoad === 'loading' ?
          <View extend={styles.loaderContainer}>
            <SpinLoader
              color={theme.colors.grey}
              size={5}
            />
            {/* <H3>Fetching weight</H3> */}
          </View>
          : 
          <View extend={ {display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <View extend={styles.element}>
              <StatusDisplay
                dark
                value={ !endTime || endTime === 'null' || endTime === 'undefined' ? // 'null' seems to sometimes be the case
                  null : convertToSelectedUnit(routeRef.current.distance, 1, 'distance', true)}
                noValueText={'Ongoing ...'}
                unit={getDistanceUnit()}
                title={'DISTANCE'}
              />
              {
                totalLoad &&
                <StatusDisplay
                  dark
                  value={Math.round(frontLoad * 100)}
                  unit='%'
                  title='FRONT LOAD'
                  warning={weightWarning(Math.round(frontLoad * 100))}
                  issue={weightIssue(Math.round(frontLoad * 100))}
                />                
              }
              {/*  <P noPadding strong>Distance:</P>
              <P large strong>{convertToSelectedUnit(routeRef.current.distance, 1, 'distance')}</P> */}
            </View>
            { totalLoad && <View extend={styles.element}>
                <View>
                  <StatusDisplay
                    dark
                    value={Math.round(totalLoad * 100)}
                    unit='%'
                    title='TOTAL LOAD'
                    warning={weightWarning(Math.round(totalLoad * 100))}
                    issue={weightIssue(Math.round(totalLoad * 100))}
                  />
                  <StatusDisplay
                    dark
                    value={Math.round(rearLoad * 100)}
                    unit='%'
                    title='REAR LOAD'
                    warning={weightWarning(Math.round(rearLoad * 100))}
                    issue={weightIssue(Math.round(rearLoad * 100))}
                  />
                </View>
            </View>
            }      
          </View>
         }
      </View>
     
      {/* <View>
        <View style={{display: 'flex',alignItems: 'center', justifyContent: 'center', marginTop: '10px'}}>
          <Button
            onClick={goToDetailsPage}
            smaller
            warning
          >
            Route Details
          </Button>
        </View>
      </View> */}
    </View>
  )
}

const borderColor = chroma(theme.colors.grey).brighten(1).hex()

const styles = {
  title: {
    display: 'flex',
    paddingBottom: 5,
    alignItems: 'center'
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid ' + borderColor,
    padding: 5
  },
  header: {
    fontFamily: 'Inter',
  },
  content: {
    fontFamily: 'Inter',
    display: 'inline-flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    paddingTop: 5,
    paddingBottom: 10,
    minHeight: 86,
    alignItems: 'center',
    borderTop: '1px solid ' + borderColor,

    borderBottom: '1px solid ' + borderColor,
  },
  element: {
    padding: 0,
    width: '50%',
    textAlign: 'center'
  },
  loaderContainer: {
    padding: '5%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    '> div': {
      marginTop: 0,
      marginBottom: 0
    }
  }
}

const mapStateToProps = ({
  vehicles,
  weight_logs,
  warning_logs
}) => ({
  vehicles,
  weight_logs,
  warning_logs
})

const mapDispatchToProps = dispatch => ({
  fetchVehicleWeights: (accessToken, startTime, endTime, boxId) => dispatch(fetchVehicleWeights(accessToken, startTime, endTime, boxId))
})

export default connect(mapStateToProps, mapDispatchToProps)(RoutePopup)