import React, { Fragment, useContext, useEffect, useState } from "react"
import View from '@modul-connect/shared/components/atoms/view'
import { AssetMaintenanceTaskContext } from "../maintenanceDetailsModal"
import { P, DownloadLink } from '@modul-connect/shared/components/atoms/text/text'
import IconButton from "@modul-connect/shared/components/atoms/iconButton/iconButton";
import { useAuth0 } from '@auth0/auth0-react'
import { connect } from 'react-redux'
import { addDocumentToAsset, fetchAssetDocuments, removeAssetDocument } from "../../../../../../state/actions/assets";
import { CircularProgress } from "@material-ui/core";
import { DeletionConfirmationModal } from "../DocumentDisplay";
import { ModalBase } from "@modul-connect/shared/components/molecules/modal/ModalBase";
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import UploadFileLink from "@modul-connect/shared/components/molecules/uploadFileLink";
import { clearUploadedDocuments } from "../../../../../../state/actions/vehicles";
import { ContainerWithBorder } from '@modul-connect/shared/components/atoms/containerWithBorder'
import { Icon } from '@modul-connect/shared/components/atoms/icon/icon';
import theme from "@modul-connect/shared/theme";

const DocumentSection = ({
  addDocumentToAsset,
  fetchAssetDocuments,
  clearUploadedDocuments,
  removeAssetDocument,
  
  uploaded_file,
  loading,
  saving,
  asset_documents
}) => {
  const context = useContext(AssetMaintenanceTaskContext)

  const isFetching = loading.fetchAssetDocuments[context.assetId] === 'loading'
  const fetchingFailed = loading.fetchAssetDocuments[context.assetId] === 'failed'

  const documents = asset_documents[context.assetId]

  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  
  const sendAttachDocument = () => {
    getAccessTokenSilently()
      .then((accessToken) =>
      {
        addDocumentToAsset(accessToken, uploaded_file.blobUrl, uploaded_file.fileName, context.assetId)
      })
  }

  const sendRemoveDocument = (documentId) => {
    getAccessTokenSilently()
      .then((accessToken) => {
        removeAssetDocument(accessToken, documentId, context.assetId)
      })
  }

  useEffect(() => {
    getAccessTokenSilently()
      .then((accessToken) =>
      {
        fetchAssetDocuments(accessToken, context.assetId)
      })
  }, [])

  return (
    <View style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }}>
{/*         <ModalSectionTitle extend={{ marginTop: 0 }}>{"Documents" + (documents?.length ? ` (${documents?.length})` : "")}</ModalSectionTitle>
*/}
      {isFetching ? <CircularProgress /> : null}

      {!isFetching && !fetchingFailed ? <DocumentList
        sendRemoveDocument={sendRemoveDocument}
        sendAttachDocument={sendAttachDocument}
        isSaving={saving.addAssetDocument === 'saving'}
        isRemovingIDs={saving.removeAssetDocument}
        documents={documents}/> 
        
        : null}

      {fetchingFailed ? <P>Could not fetch documents.</P> : null}

      <AddDocumentModal 
        open={openAddDocumentModal}
        onClose={() => {
          setOpenAddDocumentModal(false)
        }}
        onSave={() => {
          sendAttachDocument()
        }}
        uploadedDocument={uploaded_file}
        clearUploadedDocuments={clearUploadedDocuments}
      />
    </View>
  )
}

const AddDocumentModal = ({
  open,
  onClose,
  onSave,
  uploadedDocument,
  clearUploadedDocuments
}) => {
  const context = useContext(AssetMaintenanceTaskContext)
  
  const isUploading = context.saving.uploadDocument === 'uploading'

  const disabled = isUploading || !uploadedDocument?.blobUrl

  return (
    <ModalBase open={open} onClose={onClose} title={"Attach document"}>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <UploadFileLink />

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          <Button
            extend={{ width: "30%" }}
            disabled={disabled}
            onClick={() => {
              onSave && onSave()
              onClose();
            }}
          >
            {"Save"}
          </Button>

          <Button
            border
            noBg
            onClick={() => {
              onClose();
              clearUploadedDocuments()
            }}
          >
            {"Cancel"}
          </Button>
        </View>
      </View>
    </ModalBase>
  );
}

const DocumentList = ({
  sendAttachDocument,
  sendRemoveDocument,
  documents,
  isSaving,
  isRemovingIDs
}) => {

  const documentsToShow= [
    documents?.length > 0 ? documents[0] : null,
    documents?.length > 1 ? documents[1] : null,
    documents?.length > 2 ? documents[2] : null
  ]

  const [showDeletionModalForDocument, setShowDeletionModalForDocument] = useState(false)

  return (
    <Fragment>
      <DeletionConfirmationModal 
        open={showDeletionModalForDocument}
        documentFileURI={showDeletionModalForDocument.documentUrl}
        documentFileName={showDeletionModalForDocument.fileName}
        onRemoveDocument={() => sendRemoveDocument(showDeletionModalForDocument.documentId)}
        onClose={() => {setShowDeletionModalForDocument(false)}}
      />

      <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', gap: 8 }}>
        {
          documentsToShow.map((d, index) => {
            const disabled = index && !documentsToShow[index - 1]

            const isRemoving = d && isRemovingIDs ? Object.keys(isRemovingIDs)?.find(id => d.documentId === id) : false

            return <DocumentContainer
              key={index}
              document={d}
              disabled={disabled}
              sendAttachDocument={sendAttachDocument}
              sendRemoveDocument={sendRemoveDocument}
              setShowDeletionModalForDocument={setShowDeletionModalForDocument}
              isSaving={isSaving}
              isRemoving={isRemoving}
            />
          })
        }
      </View>
    </Fragment>
  )
}

const DocumentContainer = ({ isSaving, isRemoving, sendAttachDocument, document, setShowDeletionModalForDocument, disabled }) => {
  return <ContainerWithBorder extend={{ flex: 1, width: 190, gap: 12 }}>
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
      <Icon icon="PDF_File" color={document && theme.colors.blue}/>

      {document ? (isRemoving ? <CircularProgress size={24} /> : <IconButton v2 size={20} noPadding icon={"Delete"} onClick={() => setShowDeletionModalForDocument(document)} />) : null}
    </View>
      
      {
        document ? <DownloadLink noIcon url={document.documentUrl} text={document.fileName} />
        : (isSaving && !disabled) ? <CircularProgress size={24} /> 
        : <UploadFileLink
          onSave={() => sendAttachDocument()}
          disabled={disabled || isRemoving}
          normalTextColor
          noIcon
          noUnderline
          showProgressSpinner={false}
          showFile={false}
          text={"+ Add new document"}
          /> 
        
      }
    </ContainerWithBorder>
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  addDocumentToAsset: (accessToken, documentUrl, fileName, assetId) => dispatch(addDocumentToAsset(accessToken, documentUrl, fileName, assetId)),
  removeAssetDocument: (accessToken, documentId, assetId) => dispatch(removeAssetDocument(accessToken, documentId, assetId)),
  fetchAssetDocuments: (accessToken, assetId) => dispatch(fetchAssetDocuments(accessToken, assetId)),
  clearUploadedDocuments: () => dispatch(clearUploadedDocuments())
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSection);