import React, { useEffect, useState } from "react";
import "./timespanPickers.css";
import "./dayPicker.css";
import { DateTime } from "luxon";
import View from "@modul-connect/shared/components/atoms/view";
import { DayPicker } from "./dayPicker";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { isSameDay } from "@modul-connect/shared/utils/dateTimeUtils";
import { CustomPickersDay } from "./weekPicker";

function Day(props) {
  const { day, selectedStart, selectedEnd, hoverDay, setHoverDay, ...other } = props;

  if (selectedStart == null && selectedEnd == null) {
    return <PickersDay day={day} {...other} />;
  }

  const dayIsBetween = day >= selectedStart && day <= selectedEnd;
  const isFirstDay = selectedStart && isSameDay(day, selectedStart);
  const isLastDay = selectedEnd && isSameDay(day, selectedEnd);

  const hoverStart = selectedStart
  const hoverEnd = hoverDay
  const isHover = (selectedStart && !selectedEnd) ?
    (hoverStart && hoverEnd) ? hoverDay && (day >= hoverStart && day <= hoverEnd) : false
    : false
  const isFirstHover = isHover && hoverStart && isSameDay(day, hoverStart)
  const isLastHover = isHover && hoverEnd && isSameDay(day, hoverEnd)

  return (
    <CustomPickersDay
      {...other}
      day={day}
      onMouseEnter={() => {
        setHoverDay(day);
      }}
      onMouseLeave={() => {
        setHoverDay(null);
      }}
      sx={dayIsBetween || isHover ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      isHover={isHover}
      isFirstHover={isFirstHover}
      isLastHover={isLastHover}
    />
  );
}

export const CustomStartEndPicker = ({
  startTime,
  endTime,
  setSelectedStartTime,
  setSelectedEndTime,
}) => {
  const [selectedStart, setSelectedStart] = useState(startTime);
  const [selectedEnd, setSelectedEnd] = useState(endTime);

  const [hoverDay, setHoverDay] = React.useState(null);

  const [toSelect, setToSelect] = React.useState("start");
  
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <DateCalendar
        defaultCalendarMonth={startTime}
        shouldDisableDate={(date) => {
          if (selectedEnd && selectedStart) return false;
          // -> should be able to select new range
          else
            return (
              (selectedEnd && date > selectedEnd) ||
              (selectedStart && date < selectedStart)
            );
        }}
        disableFuture
        onChange={(value, selectionState) => {
          const beginningOfDay = value.startOf("day");
          const endOfDay = value.endOf("day");

          if (toSelect === "start") {
            setSelectedStart(beginningOfDay);
            setSelectedEnd(null);
            setToSelect("end");
          } else {
            setSelectedEnd(endOfDay);
            setSelectedStartTime(selectedStart)
            setSelectedEndTime(endOfDay)
            setToSelect("start");
          }
        }}
        slots={{ day: Day }}
        slotProps={{
          day: {
            selectedStart: selectedStart,
            selectedEnd: selectedEnd,
            hoverDay: hoverDay,
            setHoverDay: setHoverDay
          },
        }}
      />
    </View>
  );
};
