import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { auth0Domain, auth0ClientId, auth0_params } from "@modul-connect/shared/config";
import { Auth0Provider } from '@auth0/auth0-react';
import PortalLoggedIn, { LoadingDisplay } from "./pages/portal-logged-in/portal-logged-in";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { LogoutLandingPage } from "./pages/logout-callback/logoutCallback";
import { sec } from '@modul-connect/shared/auth0/auth0Helper'
import { userIsLoggedIn } from "./state/actions/users";

const App = ({notifications, userIsLoggedIn}) => {
  const history = useHistory()

  const unseenNotificationsCount = notifications?.filter(n => !n?.seen)?.length

  return (
    <Auth0Provider
      navigate={history.push}
      domain={auth0Domain()}
      clientId={auth0ClientId()}
      
      onRedirectCallback={() => userIsLoggedIn()}
      authorizationParams={{
        redirect_uri: auth0_params.redirectUri,
        audience: auth0_params.audience
      }}
    >
      <Helmet>
        <title>{(unseenNotificationsCount ? `(${unseenNotificationsCount}) ` : '') + 'Modul-Connect Portal'}</title>
      </Helmet>

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Portal />
      </LocalizationProvider>

    </Auth0Provider>
  );
};

const Portal = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  const auth0 = useAuth0()

  const tryingToAccessSpecificPage = window.location.pathname !== "/logout" && window.location.pathname !== "/login_success"

  React.useEffect(() => {
    if (auth0.isLoading) return
    if (auth0.isAuthenticated) {
      sec.setAccessTokenSilently(auth0.getAccessTokenSilently)
      if (auth0.user) {
        setIsLoggedIn(true)
        return
      }
    }
      setIsLoggedIn(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0])

  const isLoading = !auth0 || auth0.isLoading

  return isLoading ? <LoadingDisplay /> : (isLoggedIn || tryingToAccessSpecificPage) ? <PortalLoggedIn/> : <LogoutLandingPage />
}

const mapStateToProps = ({
  notifications
}) => ({
  notifications
})

const mapDispatchToProps = dispatch => ({
  userIsLoggedIn: () => dispatch(userIsLoggedIn()),
});

export default connect(mapStateToProps, mapDispatchToProps) (App)
