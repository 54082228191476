import {
  Select,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import React from "react";
import theme from '../../../theme'

const textStyle = {
  fontSize: "12px",
  textTransform: "uppercase",
  fontFamily: "Inter",
  fontWeight: 500,
  paddingTop: 5,
  color: theme.colors.tableText
};

export const SimpleDropdown = ({
  multiple,
  options,
  selectedValue,
  setValue,
  renderDefaultValue,
  flexWidth,
  addOptionNone,
  extendInput,
  disabled
}) => {
  return (
    <Select
      disabled={disabled}
      id="demo-simple-select-standard"
      value={selectedValue}
      multiple={multiple ? true : false}
      renderValue={() => !selectedValue[0] ? renderDefaultValue : selectedValue}
      fullWidth={flexWidth}
      size="small"
      sx={{
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      }}
      style={{
        boxShadow: 'none',
        ...textStyle,
        ...theme.inputFieldBoxShadow,
        borderRadius: "4px",
        border: "none",
        height: 33,
        paddingLeft: '14px',
        paddingRight: '14px',
        backgroundColor: theme.colors.white,

        ...extendInput,
      }}
      MenuProps={{
        MenuListProps: {
          sx: {
            "li.MuiButtonBase-root": {
              display: "flex",
              flexDirection: "column",

              alignItems: 'flex-start'
            },
          },
        },
        sx: {
          "&& .Mui-selected": {
            backgroundColor: theme.colors.selectedGrey,
            ':hover': { backgroundColor: theme.colors.lightGrey }
          },
        }
      }}
      onChange={
        (e) =>
          setValue &&
          setValue(
            e.target.value
          ) /** TODO: if multiple, add to value array, unless None, otherwise overwrite */
      }
      disableUnderline
    >
      {addOptionNone ? <MenuItem style={textStyle} selected={false} value="">{"None"}</MenuItem> : null}
      {options?.map((option) => ( // TODO: remove the blue selected style from the menu item
        <MenuItem selected={true} style={textStyle} key={option} value={option}>{option}</MenuItem>
      ))}
    </Select>
  );
};
