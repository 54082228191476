import React from 'react'
import { Fade, Modal } from '@material-ui/core'
import View from '@modul-connect/shared/components/atoms/view'
import Button from '@modul-connect/shared/components/atoms/button'
import TextField from '@modul-connect/shared/components/molecules/textField'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import { useEffect } from 'react'
import modal from '@modul-connect/shared/components/atoms/modal'

const ChangeNameModal = ({ 
    openModal,
    onClose,
    vehicle,
    changeName,
    disabled,
    updateFailed
}) => {
    const [vehicleName, setVehicleName] = React.useState('')
    const [error, setError] = React.useState(false)

    useEffect(() => {
        if(openModal) {
            setVehicleName(null)
            setError(false)
        }
    }, [openModal])

    useEffect(() => {
        if(updateFailed) {
            setError(true)
        }
    })

    const isName = (name) => {
        if(!name) {
            return false
        }

        if(!name.length) {
            return false
        }

        return true
    }

    const onSave = () => {
        let hasErrors

        if(!isName(vehicleName)) {
            setError(true)
            hasErrors = true
        }

        if(hasErrors) {
            return
        }

        const data = {
            vehicleName: vehicleName.trim(),
            vehicleId: vehicle.boxId
        }

        changeName(data)
    }

    const getErrorText = () => {
        if(updateFailed) {
            return '- Update failed'
        }
        return null
    }

    const errorFeedbackText = getErrorText()

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (disabled && reason === 'backdropClick') {
                return
              }
              onClose()
            }}            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Change Vehicle Name"}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name="Vehicle Name"
                                placeholder={vehicle.vehicleName ?? vehicle.vehicle.baseVehicleName}
                                disabled={ disabled }
                                onBlur={ (e) => setError(!isName(e.target.value)) }
                                error={error}
                                errorText={ errorFeedbackText }
                                onChange={e => setVehicleName(e.target.value)}
                            />
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg disabled={ disabled } cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg disabled={ disabled } noPadding onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ChangeNameModal
