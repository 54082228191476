import React from 'react';
import Fade from '@material-ui/core/Fade';
import theme from '@modul-connect/shared/theme';
import View from '@modul-connect/shared/components/atoms/view'
import { P } from '@modul-connect/shared/components/atoms/text'
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";

const Notification = ({
    themes,
    show,
    fadeDuration,
    text,
    loadingNotification
}) => {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '10',
        backgroundColor: 'rgb(246,248,246, 0.95)',
        border: '1px solid ' + theme.colors.grey,
        height: '30px',
        position: 'absolute',
        bottom: themes.device === 'mobile' ? '430px' : '30px',
        right: '50px',
        padding: '10px',
        borderRadius: '10px'
    }

    const style_loading = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '10',
      backgroundColor: 'rgb(246,248,246, 0.95)',
      border: '1px solid ' + theme.colors.grey,
      width: '150px',
      height: '30px',
      position: 'absolute',
      bottom: themes.device === 'mobile' ? '440px' : '30px',
      right: '50px',
      padding: '10px',
      borderRadius: '10px'
  }

    return (
        <Fade in={ show } timeout={ fadeDuration }>
          <div>
            <View extend={ loadingNotification ? style_loading : style }>
                { !loadingNotification && <P>{ text }</P> }
                {
                  loadingNotification &&
                  <BubbleLoadingWidget horizontal text={text}/>
                }
            </View>
          </div>
        </Fade>
    )
}

export default Notification;