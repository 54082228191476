import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget.v2'
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget'
import Vehicle from './vehicle/vehicle'
import vehicle_columns from './vehicle_columns'
import { fetchVehiclesSorted } from '../../../state/actions/vehicles'
import { H3 } from '@modul-connect/shared/components/atoms/text'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory, useParams } from 'react-router-dom';
import { getTagString } from '../../../utils/stringify';
import Paper from '@modul-connect/shared/components/atoms/paper'
import Page from '@modul-connect/shared/components/atoms/page.v2'

const Vehicles = React.memo(({
  loading,
  vehicles_sorted,
  fetchVehiclesSorted,
  themes,
}) => {
  let filterTimer = null

  const history = useHistory()

  const { getAccessTokenSilently } = useAuth0()

  const { vehicleId } = useParams()

  const [filter, setFilter] = useState('')
  const [fetchOptions, setFetchOptions] = useState()

  const fetchVehiclesWithAccessToken = options => {
    if (vehicleId) return
    getAccessTokenSilently()
      .then(accessToken => fetchVehiclesSorted(accessToken, options))
    setFetchOptions(options)
  }

  const vehicles_prepared = useMemo(() =>
    vehicles_sorted?.data?.length
      ? vehicles_sorted.data.map(item => ({
        ...item,
        id: item.mainbox_id ? item.mainbox_id : null,
        vin: item && item.vehicle ? item.vehicle.vin : null,
        name: item && item.vehicleName ? item.vehicleName : item.vehicle.baseVehicleName,
        serialId: item && item.mainbox_id ? item.mainbox_id.split('_').pop() : null,
        year: item && item.vehicle ? item.vehicle.year : null,
        brand_model: item && item.vehicle && item.vehicle.brandName &&
          item.vehicle.modelName ? item.vehicle.brandName + ' ' + item.vehicle.modelName : null,
        order_number: item && item.vehicle ? item.vehicle.orderNumber : null,
        translatedTags: getTagString(item?.translatedTags),
        licenseNumber: item.vehicle.licenseNumber ?? '-',
        driver: item.currentDriver ? item.currentDriver : '-'
      }))
      : [],
    [vehicles_sorted]
  )

  const handleSearchChange = event => {
    if (event.target.value.length < 2 && filter.length < 3)
      setFilterAfterDelay('')
    else
      setFilterAfterDelay(event.target.value)
  }

  const setFilterAfterDelay = newFilter => {
    if (filterTimer) clearTimeout(filterTimer)

    filterTimer = setTimeout(() => {
      setFilter(newFilter)
    }, 1000)
  }

  const handleClick = (_, id) => {
    history.push('/fleet/fleet_vehicles/' + id)
  }

  if (vehicleId) {
    return (
      <Vehicle vehicleId={vehicleId} />
    )
  }
  else if (vehicles_sorted) {
    return (
      <Page>
        <Paper title={'Vehicles'}>
          <SearchWidget
            title={'Vehicles'}
            onChange={handleSearchChange}
            placeholder={'Search...'}
            initialValue={filter} />

          <TableWidget
            data={vehicles_prepared}
            loadingStatus={loading.fetchVehiclesSorted}
            totalItems={vehicles_sorted && vehicles_sorted.total ? vehicles_sorted.total : 0}
            columns={vehicle_columns}
            onFetchData={fetchVehiclesWithAccessToken}
            searchStr={filter}
            themes={themes}
            defaultSort={"name"}
            onClickRow={handleClick}
            extend={{ width: "100%", paddingTop: 24 }}
          />

        </Paper>
      </Page>
    )
  }
  else {
    return (
      <Page>
        <H3 children={'Loading...'} />
      </Page>
    )
  }
})

const mapStateToProps = ({
  loading,
  vehicles_sorted,
  themes,
}) => ({
  loading,
  vehicles_sorted,
  themes,
})

const mapDispatchToProps = dispatch => ({
  fetchVehiclesSorted: (accessToken, options) => dispatch(fetchVehiclesSorted(accessToken, options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles)