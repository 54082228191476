import React from "react";
import { connect } from "react-redux";
import { P } from "@modul-connect/shared/components/atoms/text";
import Page from "@modul-connect/shared/components/atoms/page.v2";
import { useHistory } from "react-router-dom";
import {
  changeVehicleName,
  fetchVehicle
} from '../../../../state/actions/vehicles'
import { useAuth0 } from '@auth0/auth0-react'
import VehicleInfo from "./vehicle_info";
import { MdEdit } from "react-icons/md";
import ChangeNameModal from "./changeNameModal";
import LogGraphs from "./logGraphs/logGraphs"
import { BubbleLoadingWidget } from "@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget";
import Paper from '@modul-connect/shared/components/atoms/paper'
import { Header } from "@modul-connect/shared/components/atoms/header";

const Vehicle = ({
  vehicleId,
  vehicles,
  fetchVehicle,
  changeVehicleName,
  saving,
  vehicle_loading
}) => {
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()
  
  let [showChangeNameModal, setShowChangeNameModal] = React.useState(false)
  let [newName, setNewName] = React.useState(null);
  
  const vehicle = React.useMemo(() => {
    return vehicles ? vehicles.find(v => (v.boxId === vehicleId)) : null
  }, [vehicles])
  

  const changeVehicleNameWithAccessToken = (data) => {
    getAccessTokenSilently()
      .then(accessToken => {
        changeVehicleName(accessToken, data.vehicleId, data.vehicleName)
      })
  }

  const fetchVehicleWithAccessToken = () => {
    getAccessTokenSilently()
      .then(accessToken => {
        fetchVehicle(accessToken, vehicleId)
      })
  }

  React.useEffect(() => {
    if (vehicle_loading.fetchVehicle === 'loading') {
      return
    }
    fetchVehicleWithAccessToken()
  }, [vehicleId])

  React.useEffect(() => {
    if (saving && saving.ajaxError) {
      setShowChangeNameModal(false)
      setNewName(null)
    }

    if (saving && saving.changeVehicleName === 'failed') {
      setNewName(null)
    }

    if (saving && !saving.changeVehicleName) {
      setShowChangeNameModal(false)
    }
  }, [saving])


  const displayName = newName ? newName : (
    vehicle?.vehicleName ?? (vehicle?.vehicle?.baseVehicleName ?? 'Vehicle Details')
  )
  if (vehicle) {
    return (
      <Page>
        <Paper
          title={displayName}
          linkTitle={'Edit name'}
          linkIcon={<MdEdit />}
          onClickLink={() => {
            setShowChangeNameModal(true)
          }}
          /* linkBack={history}
          onClickLinkBack={() => setSelectedVehicleId(null)}
          */>

          {vehicle.vehicle ?
            <VehicleInfo vehicle={vehicle} vehicleId={vehicleId} />
            : <P>Loading ...</P>}
        </Paper>

        <LogGraphs vehicle={vehicle} vehicleId={vehicleId} />

        <ChangeNameModal
          openModal={showChangeNameModal}
          onClose={() => setShowChangeNameModal(false)}
          vehicle={vehicle}
          changeName={(data) => {
            changeVehicleNameWithAccessToken(data)
            setNewName(data.vehicleName)
          }}
          updateFailed={saving && saving.changeVehicleName === 'failed'}
          disabled={saving && saving.changeVehicleName === 'saving'} />
      </Page>
    )
  }
  else {
    return (
      vehicle_loading.fetchVehicle === 'loading' ?
      <BubbleLoadingWidget text={ 'Loading vehicle page ...' } />
      : vehicle_loading.fetchVehicle === 'forbidden' 
            ? <P>You are not allowed to view this vehicle.</P>
            : <P>Failed fetching information for the specific vehicle.</P>
    )
  }
}

const mapStateToProps = ({
  vehicles_data,
  battery_logs,
  saving,
  vehicles,
  vehicle_loading
}) => ({
  vehicles_data,
  battery_logs,
  saving,
  vehicles,
  vehicle_loading
})

const mapDispatchToProps = dispatch => ({
  changeVehicleName: (accessToken, vehicleId, newName) => dispatch(changeVehicleName(accessToken, vehicleId, newName)),
  fetchVehicle: (accessToken, boxId) => dispatch(fetchVehicle(accessToken, boxId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
