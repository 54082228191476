

import React from 'react';
import View from '@modul-connect/shared/components/atoms/view'
import { BubbleLoader } from 'react-css-loaders'
import theme from '@modul-connect/shared/theme'
import { H3 } from '../../atoms/text';

export const BubbleLoadingWidget = ({ text, horizontal, extend }) => {
  return (
    <View extend={[horizontal ? style_horizontal : style, extend  ] }>
      <View extend={horizontal && { width: 75, marginBottom: 15 }}>
        <BubbleLoader
          color={theme.colors.primary}
          size={horizontal ? 3 : 5}
        />
      </View>
      <View extend={horizontal && { height: '100%', display: 'flex', alignItems: 'center' }}>
        <H3>{text ?? ''}</H3>
      </View>
    </View>
  )
}

const style = {
  //height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}

const style_horizontal = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  height: 50
}