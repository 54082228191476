import React, { Fragment } from "react";
import Paper from "@modul-connect/shared/components/atoms/paper";
import { Fade, Modal } from "@material-ui/core";
import View from "@modul-connect/shared/components/atoms/view";
import theme from "@modul-connect/shared/theme";
import { CardTitle } from '@modul-connect/shared/components/atoms/text/text'


export const ModalBase = ({ children, title, open, onClose, overflow, noBg, dropShadow, extend }) => {
  const content = (
    <Fragment>
      {title ? (
        <CardTitle
          extend={{
            fontSize: 16,
            color: theme.colors.midDarkGrey,
            alignSelf: "flex-start",
            paddingBottom: 10,
          }}
        >
          {title}
        </CardTitle>
      ) : null}

      {children}
    </Fragment>
  );
  return (
    <Modal
      open={open}
      onClose={(event, reason) => () => onClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open ? true : false}>
        <div style={{ height: "100%", width: "100%" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              ...extend
            }}
          >
            {noBg ? content : dropShadow ? <WhitePaperBackground overflow={overflow}>{content}</WhitePaperBackground> : <BackgroundPaper overflow={overflow}>{content}</BackgroundPaper>}
          </View>
        </div>
      </Fade>
    </Modal>
  );
};

const BackgroundPaper = ({ children, overflow }) => {
  return (
    <Paper
      extend={{
        height: "fit-content",
        minWidth: 500,
        maxWidth: 800,
        width: "50%",
        padding: 0,
        backgroundColor: theme.colors.barBackground,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: 4,
        overflow: overflow ?? "hidden",
      }}
    >
      {children}
    </Paper>
  );
}

export const WhitePaperBackground = ({ children, overflow, fullView }) => {
  return (
    <View style={{
      height: fullView ? '100%' : 'auto',
      width: fullView ? '100%' : '50%',
      padding: '12px 24px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '4px 4px 4px 4px',
      backgroundColor: theme.colors.white,
      boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
      gap: 16,
      overflow: overflow ?? 'hidden'
    }}>{children}</View>
  )
}