 
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { timespanOptions } from "../datePickers/timespanPickers/timespanPickers"
import ReportPage from "../reportPage.v2"
import { useHistory, useLocation} from 'react-router-dom';
import { octopusDemoChecklist } from "../reports";
import { report_columns, summary_columns } from "./checklist_report_columns";
import TableWidgetSeparated from "@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2";
import { connect } from 'react-redux'
import { dummyData } from "./dummyData";
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { WarningIcon } from "@modul-connect/shared/components/atoms/icons/warningIcon";
import theme from "@modul-connect/shared/theme";
import { TableLink } from "@modul-connect/shared/components/atoms/text/text";
import ChecklistDetails from "./checklistDetails";
import DefectsReportPage from "./defectsReportPage";
import { ChecklistManagementPage } from "./checklistManagementPage";

export const subPageOptions = {
  report: 'reports',
  defects: 'defects',
  management: 'settings'
}

const prepareData = (data) => {
  if (!data || !data?.length) return data

  const toReturn = []

  data.forEach((report, index) => {
    const row = []
    row.push({sortValue: DateTime.fromISO(report.date), value: dateTimeToString(DateTime.fromISO(report.date), "date-only") })
    row.push({value: report.issues, tooltip: report.issues + ' issues identified', iconComponent: report.issues > 0 ? <WarningIcon size={"medium"} color={theme.colors.tag["warning"]}/> : null })
    row.push({value: report.dangerous, tooltip: report.issues + ' dangerous issues identified', iconComponent: report.issues > 0 ? <WarningIcon size={"medium"}/> : null })
    row.push({value: report.user, tooltip: 'The report was made by ' + report.user })
    row.push({value: report.informed, tooltip: report.informed ? 'The results were reported to ' + report.informed : 'The results were not reported to anyone.' })
    row.push({valueComponent: <TableLink onClick={(event) => {}}>Download report</TableLink> })
    row.push({ collapsibleComponent: 
      <ChecklistDetails  
        checklist={report}
      />,
      onExpand: () => {}
    })

    toReturn.push(row)
  })

  return toReturn
}


const groupedData_vehicle = {}
dummyData.forEach(d => {
  const existingData = groupedData_vehicle[d.vehicle] ?? []
  existingData.push(d)
  groupedData_vehicle[d.vehicle] = existingData
})

const summaryData = Object.keys(groupedData_vehicle).map(v => {
  const withIssues = groupedData_vehicle[v]?.filter(report => report.issues > 0) ?? []
  const withDangerousIssues = groupedData_vehicle[v]?.filter(report => report.dangerous > 0) ?? []

  return {
    id: v,
    vehicle: v,
    amount: groupedData_vehicle[v]?.length,
    amount_tooltip: groupedData_vehicle[v]?.length + ((groupedData_vehicle[v]?.length === 1) ? ' check' : ' checks') + ' performed',
    issues: withIssues?.length,
    issues_tooltip: withIssues?.length + ((withIssues?.length === 1) ? ' check' : ' checks') + ' resulted in issues being identified',
    danger: withDangerousIssues?.length,
    danger_tooltip: withDangerousIssues?.length + ((withDangerousIssues?.length === 1) ? ' check' : ' checks') + ' resulted in dangerous issues being identified',
    
    isExpandable: true,
    onExpand: () => {
    },
    collapsibleData: groupedData_vehicle[v],
  }
})


const Checklist_demo = ({
  themes
}) => {
  const history = useHistory()
  const location = useLocation();

  const [subPage, setSubPage] = useState(useLocation().pathname.split('/')[useLocation().pathname.split('/').length - 1] )
  
  useEffect(()=>{

    if(subPage !== subPageOptions.management && subPage !== subPageOptions.report && subPage !== subPageOptions.defects)
    {
      history.push({ 
        pathname: '/reports/' + 'reports_' + octopusDemoChecklist + '/' + subPageOptions.report})
      setSubPage(subPageOptions.report)
    }
  }, [])

   useEffect(() =>{
    setSubPage(location.pathname.split('/')[location.pathname.split('/').length - 1])
  }, [location])

  const tables = [
    <TableWidgetSeparated
      data={summaryData}
      onelineOnly
      loadingStatus={null}
      columns={summary_columns}
      themes={themes}
      isSortable
      pagination={false}
      defaultSort={'danger'}
      defaultSortOrder={'desc'}
      collapsibleDataLoadingStatus={null}
      collapsibleDefaultSortIndex={0}
      collapsibleDefaultSortOrder={'desc'}
      collapsibleLogColumns={report_columns}
      prepareCollapsibleData={(data) => prepareData(data)}
    />
  ]
  const isLoading = false
  const loadingFailed = false
  const disabled = false

  const [startDate, setStartDate] = useState(DateTime.local().startOf('month'))
  const [endDate, setEndDate] = useState(DateTime.local().endOf('month'))

  const onTimeframePicked = (start, end) => {
    setStartDate(start);
    setEndDate(end);

    // TODO: filter the fake data
  }

  if (subPage === subPageOptions.management) return <ChecklistManagementPage />

  else if (subPage === subPageOptions.defects) return <DefectsReportPage />

  else return (
    <ReportPage
      title={"Checklist Reports"}
      headerSection={null}
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom
      ]}
      noDataMessage={"There are no checklist reports for the selected time period."}
      isLoading={isLoading}
      fetchingDataFailed={loadingFailed}
      disabled={disabled}
      startDate={startDate}
      endDate={endDate}
      onTimeframePicked={onTimeframePicked}
      noSearch
      tables={tables}
    />
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Checklist_demo);