import React from 'react'
import theme from '@modul-connect/shared/theme';
import View from '../view';
import { Error } from '@material-ui/icons';

const isMuiIconSize = (size) => {
  switch(size) {
    case 'small':
    case 'medium':
    case 'large':
    case 'inherit':
      return true
    default:
      return false
  }
}

export const WarningIcon = ({ size, color, extend }) => {
  return (<View
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: color ?? theme.colors.warningIcon,
      fontSize: isMuiIconSize(size) ? 'inherit' : size,
      ...extend
    }}
  >
    <Error fontSize={!isMuiIconSize(size) ? 'inherit' : size} color='inherit' />
  </View>)
}
