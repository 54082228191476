import React from "react";
import View from "../../atoms/view";
import { SidebarIcon } from "../../atoms/sideBarIcon";
import theme from "../../../theme";
import { SidebarLink, SidebarLinkForSubpages } from "../side-bar-link/side-bar-link";

// TODO: restructure so this has all the categories and the report page doesn't need to do as much logic
const SideBar = ({ children, themes, selected, setActive = true, showFilterBar }) => {
  return (
    <View extend={styles(showFilterBar).sidebar_container}>
      <View
        extend={
          !setActive
            ? styles(showFilterBar).sidebar_inactive
            : [
                styles(showFilterBar).sidebar_active,
                themes.device === "desktop"
                  ? styles(showFilterBar).sidebar_desktop
                  : themes.device === "tablet"
                  ? styles(showFilterBar).sidebar_tablet
                  : themes.device === "mobile" && styles.sidebar_mobile,
                styles(showFilterBar).outer_sidebar,
              ]
        }
      >
        {children}
      </View>
      <InnerSidebar title={selected?.title} icon={selected?.icon} subPages={selected?.subPages} showFilterBar={showFilterBar}/>
    </View>
  );
};

const InnerSidebar = ({ title, icon, subPages, showFilterBar }) => {
  return (
    <View extend={styles(showFilterBar).inner_sidebar}>
      <View style={{
          display: 'flex',
          alignItems: 'center',
          height: theme.layout.sidebarItemHeight,
          width: '100%',
          borderBottom: `1px solid ${theme.colors.lightDividerGrey}`
        }}>
        <InnerSidebarHeader title={title} icon={icon}/>
      </View>
      {
        subPages?.length ? subPages.map(subPage => <SubpageHeader key={subPage.id} id={subPage.id} title={subPage.title} subPages={subPages} />)
        : null
      }
    </View>
  );
};


const onClick = (subPage, url, history, subPages) => {
  if(subPage && url && history)
  {
    const lastIndex = history.location.pathname.lastIndexOf('/');
    history.location.pathname = history.location.pathname.substring(0, lastIndex);
    if(subPages.length >= 2)
      history.push({ 
        pathname: url.pathname + '/' + subPage.toLowerCase(),})
  }
}

// TODO: styling
const SubpageHeader = ({ id, title, subPages }) => {
  return <SidebarLinkForSubpages textOnly id={id} title={title} onClick={onClick} subPages={subPages}/>
}

const InnerSidebarHeader = ({ title, icon }) => {
  const titleFont = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',

    color: '#404040',
  }

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        width: '100%',
        height: 40,
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <SidebarIcon icon={icon} dark/>
      <View style={titleFont}>{title}</View>
    </View>
  );
};

const styles = (showFilterBar) => { return {
  sidebar_container: ({ theme: { colors, layout } }) => ({
    display: "flex",
    flexDirection: "column",
    width: `${layout.sidebarWidth}px`,
    height: "100vh",
    marginTop:
      layout.navBarHeight + (showFilterBar() ? layout.filterBarHeight : 0) + layout.navigationBarHeight,
  }),
  outer_sidebar: ({ theme: { colors, layout } }) => ({
    width: layout.sidebarItemWidth,
    backgroundColor: colors.offWhite,
    "> a": {
      paddingTop: "8px",
      transition: "color 200ms ease-in-out",
      "&:hover": {
        color: colors.primary,
      },
      "&:active": {
        color: colors.white,
      },
    },
  }),
  inner_sidebar: ({ theme: { colors, layout } }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 12px",

    width: layout.sidebarWidth - layout.sidebarItemWidth + 'px',
    height: "100%",
    alignSelf: 'flex-end',

    borderRight: "1px solid #BCBCBC",
    borderLeft: "1px solid #BCBCBC",

    backgroundColor: colors.white,
  }),
  sidebar_active: ({ theme: { colors, layout } }) => ({
    backgroundColor: colors.white,
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 5,
    height: "100vh",
    // boxShadow: '2px 2px 5px 3px rgba(0,0,0,.05)',ht
    //borderRight: '1px solid rgb(240, 240, 240)',
    top: layout.navBarHeight + (showFilterBar() ? layout.filterBarHeight : 0) + layout.navigationBarHeight,
    //paddingLeft: 8,
    //paddingRight: 8,
  }),
  sidebar_inactive: {
    display: "none",
  },
  sidebar_desktop: ({ theme: { layout } }) => ({
    // top: layout.navBarHeight
  }),
  sidebar_tablet: ({ theme: { layout } }) => ({
    // top: layout.navBarHeight_tablet
  }),
  sidebar_mobile: ({ theme: { layout } }) => ({
    // top: layout.navBarHeight_mobile,
    "& .DatePickerContainer": {
      marginLeft: "auto !important",
      marginRight: "auto !important",
      width: "100%",
    },
  }),
}};

export default SideBar;
