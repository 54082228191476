import React from "react"
import View from "@modul-connect/shared/components/atoms/view";
import { createMap } from "./create-map"
import { MdMap } from "react-icons/md";
import { 
  selectVehicle, 
  selectRoute, 
  addVehicle, 
  stopSignalRConnection, 
  clearAnimationTargets, 
  fetchVehiclePositions, 
  updateAnimationSpeed, 
  fetchBeaconSnapshot,
  fetchLastKnownVehiclePosition,
  fetchLastKnownVehiclePosition_v2,
  unselectSecurityAlarmIncidentOnMap
} from "../../../state/actions/vehicles";
import { setMapLiveMode } from '../../../state/actions/map'
import { connect } from "react-redux";
import { startAnimation, stopAnimation } from './animation'
import { getVehiclesToRender, isToday, setBounds } from './utils'
import { updateMapHandler } from './updateMapHandler'
import { styles } from './style'
import { DateTime } from "luxon"
import RoutePopup from "./popups/routePopup"
import { createPortal } from 'react-dom';
import './map.css'
import { vehicleHasActiveWarning } from "../../../utils/vehicleUtils";
import { hasAllMapDayData, setMapVersion } from "../../../state/actions/app";
import { mapVersions } from "../../../state/reducers/settings";

const skipLevelForFirstRouteFetch = 100
class Map_v2 extends React.PureComponent {
  isOpeningMap = true
  hasWarning = false
  isReloadingVehicles = false
  previousVehicles = undefined

  state = {
    mapIsLoaded: false,
    map: null,
    index: -1
  }

  blurListener = () => stopAnimation(this)
  focusListener = () => this.onFetchVehiclePositions()

  componentDidMount() {
    const {
      onSelectVehicle,
      onSelectRoute,
      getAccessTokenSilently,
      themes,
      map_settings,
      setMapLiveMode,
      setMapVersion
    } = this.props

    setMapVersion(mapVersions.fetchRoutesOnSelectVehicle)

    const map = createMap({ center: [8, 52.7], zoom: 4, onSelectVehicle, onSelectRoute, self: this, themes, map_settings: map_settings })

    this.setState({
      map
    })

    const interval = setInterval(() => {
      if (map._loaded) {
        this.setState({
          ...this.state,
          mapIsLoaded: true
        })
        return clearInterval(interval)
      }
    }, 100)

    getAccessTokenSilently()
      .then((accessToken) => {
        setMapLiveMode(true, accessToken)
      })
  }

  componentWillUnmount() {
    const {
      signalRConnection,
      onStopSignalRConnection,
      onSelectVehicle,
      onSelectRoute,
      mapPopups,
      setMapVersion
    } = this.props

    onStopSignalRConnection(signalRConnection)
    onSelectVehicle(null)
    onSelectRoute(null, null)
    stopAnimation(this)
    mapPopups.forEach(popup => popup.popup.remove()) 
    
    setMapVersion(mapVersions.default)


    stopAnimation(this)


 //   window.removeEventListener('blur', this.blurListener)
 //   window.removeEventListener('focus', this.focusListener)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      date,
      vehicles,
      bottom,
      selectedVehicle,
      selectedRoute,
      selectedAssetTracker,
      warning_logs,
      onSelectVehicle,
      onSelectRoute,
      map_settings,
      loading,
      mapPopups,
      store,
      beacons,
      hasAllMapDayData,
      selected_organisations,
      selectedSecurityAlarm
    } = this.props

    const {
      map,
      index,
      mapIsLoaded
    } = this.state

    if (selectedVehicle?.boxId != null) {
      const vehicle = vehicles.find(v => v.boxId === selectedVehicle.boxId)
      this.hasWarning = !vehicle ? false : vehicleHasActiveWarning(vehicle, beacons)
    }

    if (!mapIsLoaded || mapPopups !== prevProps.mapPopups)
      return

    if (!this.isOpeningMap && !this.isReloadingVehicles && prevProps.vehicles && vehicles.length !== prevProps.vehicles?.length) {
      this.previousVehicles = prevProps.vehicles
      this.isReloadingVehicles = true
      this.onFetchVehiclePositions()
      return
    }

    if (this.isReloadingVehicles) {
      this.isReloadingVehicles = false
      this.onUpdateMap('data_updated')
      this.previousVehicles = undefined
      return
    }

    //On opening map first time -> get bounds and fetch todays routes
    if(this.isOpeningMap && vehicles?.length) { // && loading.fetchVehicleRoutes !== 'loading') {
      this.isOpeningMap = false

      setBounds({ map, vehicles, date })
      this.onFetchVehiclePositions()
      return
    }

    //Selected (or deselected) a vehicle -> update map
    if(prevProps.selectedVehicle.boxId !== selectedVehicle.boxId) {
      this.onUpdateMap('selected_vehicle')
      return
    }

    //Selected (or deselected) a route -> update map
    if(prevProps.selectedRoute?.boxId !== selectedRoute?.boxId) {
      if (!selectedRoute?.boxId) {
        // if deselected route -> want to zoom back out to vehicle level
        this.onUpdateMap('selected_vehicle')
        return
      }
      else {
        this.onUpdateMap('selected_route')
        return
      }
    }

    //Selected (or deselected) an asset tracker -> update map
    if(prevProps.selectedAssetTracker?.beaconId !== selectedAssetTracker?.beaconId) {
      if (selectedAssetTracker?.beaconId) this.onUpdateMap('selected_asset_tracker') // select
      else this.onUpdateMap('selected_vehicle') // deselect -> zoom back out to vehicle level
      return
    }

    // On changing selected_organisations -> reload
    if (selected_organisations !== prevProps.selected_organisations) {
      stopAnimation(this)
      onSelectVehicle(null)
      onSelectRoute(null, null)
      this.onUpdateMap('data_updated')

      this.onFetchVehiclePositions()
      return
    }

    // if switching to non-live mode -> need to fetch last known vehicle positions, behave as if date was changed
    const liveModeTurnedOff = prevProps.map_settings.isLive && !map_settings.isLive
    //On changing date
    if(date !== prevProps.date || liveModeTurnedOff) {
      if (!isToday(date) || liveModeTurnedOff)  { 
        stopAnimation(this)
      } else {
        this.onFetchVehiclePositions()
        return
      }

      // -> get the positions for that date
      if(this.hasRoutesForDate(map_settings.isLive)) {
        hasAllMapDayData()
        this.onUpdateMap('data_updated')
      }
      else {
        this.onFetchVehiclePositions()
      }
      return
    }

    //On changing time
    if(map_settings.startClockTime !== prevProps.map_settings.startClockTime || map_settings.endClockTime !== prevProps.map_settings.endClockTime) {
      stopAnimation(this)
      this.onFetchVehiclePositions()
      this.onUpdateMap('data_updated')
      return
    }

    // If the warning logs or warning log filter updated
    if (warning_logs !== prevProps.warning_logs || map_settings.filter?.mustHaveWeightWarning !== prevProps.map_settings.filter?.mustHaveWeightWarning) {
      this.onUpdateMap('data_updated')
      return
    }

    // if last known positions were fetched
    if (loading.fetchLastKnownPositions === null && loading.fetchLastKnownPositions !== prevProps.loading.fetchLastKnownPositions) {
      this.onUpdateMap('data_updated')
      return
    }

    if (bottom !== prevProps.bottom && map) {
      map.resize()
      return
    }
    
    if (index === prevState.index) {
      if(map_settings.isLive) {
        startAnimation(map, this, onSelectVehicle, onSelectRoute, store)
      }
      this.onUpdateMap('data_updated')
    }
  }

  hasRoutesForDate = (isLive) =>
    this.props.vehicles.length === 0 ||
    (!isLive && this.props.vehicles.some(vehicle => vehicle.geoJSON?.routes?.hasOwnProperty(this.props.date)))

  onUpdateMap = action => {
    const {
      date,
      vehicles,
      themes,
      selectedVehicle,
      selectedRoute,
      selectedAssetTracker,
      onSelectVehicle,
      onSelectRoute,
      beacons,
      map_settings,
      store,
      selectedSecurityAlarm
    } = this.props

    const vehicles_to_render = getVehiclesToRender({ 
      vehicles: vehicles,
      map_settings: map_settings,
      date: date,
    })

    updateMapHandler({
      vehicles,
      vehicles_to_render,
      beacons,
      self: this, 
      map: this.state.map, 
      themes, 
      selectedVehicle, 
      date, 
      action,
      hasWarning: this.hasWarning,
      prevVehicles: this.previousVehicles,
      onSelectVehicle: onSelectVehicle,
      onSelectRoute: onSelectRoute,
      selectedRoute: selectedRoute,
      selectedAssetTracker: selectedAssetTracker,
      unselectSecurityAlarmIncidentOnMap: () => store.dispatch(unselectSecurityAlarmIncidentOnMap()),
      selectedSecurityAlarm: selectedSecurityAlarm,
      map_settings: map_settings,
      store: store
    })
  }

  onFetchVehiclePositions = () => {
    const {
      getAccessTokenSilently,
      onClearAnimationTargets,
      fetchVehiclePositions,
      fetchBeaconSnapshot,
      fetchLastKnownVehiclePosition,
      fetchLastKnownVehiclePosition_v2,
      map_settings,
    } = this.props

    getAccessTokenSilently()
      .then(accessToken => {
        if (map_settings.isLive && (!map_settings.date || map_settings.date === DateTime.local().toISODate())) {
          const rightNow = DateTime.fromJSDate(new Date())

          onClearAnimationTargets()
          fetchVehiclePositions(accessToken, DateTime.fromSeconds(rightNow.toSeconds() - 60))
          fetchBeaconSnapshot(accessToken) 
        }
        else {
          const selectedDate = DateTime.fromISO(map_settings?.date)
          const startTime = map_settings?.startClockTime
          const endTime = map_settings?.endClockTime


          let start = DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, startTime.hour, startTime.minute)
          let end = DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, endTime.hour, endTime.minute)

          if (map_settings.mapVersion === mapVersions.fetchRoutesOnSelectVehicle) fetchLastKnownVehiclePosition_v2(accessToken, start, end)
          else fetchLastKnownVehiclePosition(accessToken, start, end)
        }
      })
  }

  render = () => {
    const {
      date,
    } = this.props

    return (<React.Fragment>
      <View 
        id="mapContainer" 
        extend={[
          styles.default, 
          { bottom: this.props.bottom ? this.props.bottom : 0 }
        ]}
      />
      <View 
        id="switchMapsControl" 
        extend={[
          styles.switchMap, 
          this.props.themes.device !== 'mobile' 
            ? styles.switchMap_control_pos 
            : styles.switchMap_control_pos_mobile
        ]}
      >
        <MdMap />
      </View>
      { this.props.mapPopups.map(popup => {
        return createPortal(
        <RoutePopup date={date} pointProps={popup.pointProps}/>,
        document.getElementById(popup.popupId)
      )})}
    </React.Fragment>)
  }
}

const mapStateToProps = ({
  themes, selectedVehicle, selectedAssetTracker, selectedRoute, warning_logs, vehicles, signalRConnection, beacons, map_settings, loading, mapPopups, selected_organisations, selectedSecurityAlarm
}) => ({
  themes, selectedVehicle, selectedAssetTracker, selectedRoute, warning_logs, vehicles, signalRConnection, beacons, map_settings, loading, mapPopups, selected_organisations, selectedSecurityAlarm
})

const mapDispatchToProps = dispatch => ({
  onSelectVehicle: (boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle) => dispatch(selectVehicle(boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle)),
  onSelectRoute: (boxId, endTimestamp) => dispatch(selectRoute(boxId, endTimestamp)),
  onAddVehicle: vehicle => dispatch(addVehicle(vehicle)),
  onStopSignalRConnection: connection => dispatch(stopSignalRConnection(connection)),
  onClearAnimationTargets: () => dispatch(clearAnimationTargets()),
  fetchVehiclePositions: (accessToken, start, end) => dispatch(fetchVehiclePositions(accessToken, start, end)),
  fetchLastKnownVehiclePosition: (accessToken, start, end) => dispatch(fetchLastKnownVehiclePosition(accessToken, start, end)),
  fetchLastKnownVehiclePosition_v2: (accessToken, start, end) => dispatch(fetchLastKnownVehiclePosition_v2(accessToken, start, end)),
  fetchBeaconSnapshot: (accessToken) => dispatch(fetchBeaconSnapshot(accessToken)),
  updateAnimationSpeed: (boxId, speed) => dispatch(updateAnimationSpeed(boxId, speed)),
  setMapLiveMode: (newMode, accessToken) => dispatch(setMapLiveMode(newMode, accessToken)),
  hasAllMapDayData: () => dispatch(hasAllMapDayData()),
  setMapVersion: (version) => dispatch(setMapVersion(version))
})

export default connect(mapStateToProps, mapDispatchToProps)(Map_v2)