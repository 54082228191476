import TableWidgetSeparated from '@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { DateTime } from "luxon";
import { log_columns } from './log_columns'
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { getDayName } from '@modul-connect/shared/utils/text'
import KpiRow from '@modul-connect/shared/components/molecules/kpiRow/kpiRow';
import theme from "@modul-connect/shared/theme"
import TopFiveDisplay from '../../batteryReport/batteryReportDetails/topFiveDisplay/topFiveDisplay';
import { convertToSelectedUnit, formatAddress } from '../../../../utils/unitConverter';
import { useHistory } from 'react-router-dom';
import ReportPage from '../../reportPage.v2';
import { timespanOptions } from '../../datePickers/timespanPickers/timespanPickers';

const WeightReportSummary = ({
  startDate,
  endDate,
  onStartDatePicked,
  onEndDatePicked,
  themes,
  loading,
  overweight_report,
  onVehicleSelected,
  disabled
}) => {
  let [reportData, setReportLogs] = useState(null)
  let [kpiData, setKpiData] = useState(null)
  let [selectedVehicleId, setSelectedVehicleId] = useState(null)
  let [data, setData] = useState({})

  const history = useHistory()

  const isLoading = loading.fetchWeightReport === 'loading'
  const fetchingFailed = loading.fetchWeightReport === 'failed'

  const goToWeightLogDetailsPage = (event, index) => {
    const logs = overweight_report?.data?.logs
    if (!logs || logs.length < index) {
      return
    }

    const log = logs[index]

    const timestamp = DateTime.fromISO(log.startTime)
    if (!timestamp || !timestamp.isValid) {
      return
    }

    let summaryId = log.boxId

    history.push('/reports/reports_weight-reports/' + summaryId + '/' + log.startTime);

  }

  const getDataByDay = (data) => {

    let dividedByDay = {}
    let dailyList = []

    let prevTimestamp = ''

    if (data?.length) {
      data.forEach(log => {

        if (prevTimestamp && (log.timestamp.startOf("day").toMillis() !== prevTimestamp.startOf("day").toMillis())) {

          dividedByDay[prevTimestamp] = [...dailyList]
          dailyList = []
        }
        dailyList.push(log)
        prevTimestamp = log.timestamp
      })
    }
    if (dailyList.length > 0) {

      dividedByDay[prevTimestamp] = [...dailyList]
    }
    return dividedByDay
  }

  const totalHours = (endDate - startDate) / 60 / 60 / 1000
  const top_five_data = overweight_report?.data?.byVehicle ? overweight_report?.data?.byVehicle.map(d => {
    return {
      name: d.vehicle,
      percentage: Math.min((d.hours / totalHours * 100).toFixed(2), 100),
      additionalInfo: 'Number of warnings: ' + d.trips,
      vehicleId: d.id
    }
  }) : []

  const weightString = (log) => {
    let gross = {
      kg: (log.hasOverweightGross || log.gross?.ratio > 1) ? `${convertToSelectedUnit(log.weightGross - log.gross.max, 0, 'weight')}` : null,
      percent: (log.hasOverweightGross || log.gross?.ratio > 1) ? `${(log.gross.ratio * 100).toFixed(0)} %` : null

    }

    if ((log.hasOverweightGross || log.gross?.ratio > 1) && !(log.hasOverweightFront || log.front?.ratio > 1) && !(log.hasOverweightRear || log.rear?.ratio > 1)) {
      return gross
    }

    let front = {
      kg: (log.hasOverweightFront || log.front?.ratio > 1) ? `${convertToSelectedUnit(log.weightFront - log.front.max, 0, 'weight')} (front)` : null,
      percent: (log.hasOverweightFront || log.front?.ratio > 1) ? `${(log.front.ratio * 100).toFixed(0)} %` : null
    }
    let rear = {
      kg: (log.hasOverweightRear || log.rear?.ratio > 1) ? `${convertToSelectedUnit(log.weightRear - log.rear.max, 0, 'weight')} (rear)` : null,
      percent: (log.hasOverweightRear || log.rear?.ratio > 1) ? `${(log.rear.ratio * 100).toFixed(0)} %` : null
    }

    return {
      kg: (gross.kg ? gross.kg + ', ' : '') + (front.kg && rear.kg ? `${front.kg}, ${rear.kg}` : front.kg ? `${front.kg}` : (rear.kg ? `${rear.kg}` : '0 kg')),
      percent: (gross.percent ? gross.percent + ', ' : '') + (front.percent && rear.percent ? `${front.percent}, ${rear.percent}` : front.percent ? `${front.percent}` : (rear.percent ? `${rear.percent}` : '- %')),
    }
  }

  useEffect(() => {
    let nextId = -1
    let preparedData = []

    const logs = overweight_report?.data?.logs
    if (logs && logs.length >= 0) {
      setReportLogs(logs.map((log, index) => {
        nextId++

        let weight_formatted = weightString(log)
        preparedData.push({
          id: nextId,
          key: nextId + '_' + index,
          boxId: log.boxId,
          timestamp: log.startTime ? DateTime.fromISO(log.startTime) : null,
          timestamp_end: log.endTime ? DateTime.fromISO(log.endTime) : null,
          driver: log.driver,
          vehicle: log.vehicle,
          weight_kg: weight_formatted.kg ?? '0 kg',
          weight_percent: weight_formatted.percent ?? '- %',
          startAddress: formatAddress(log.startAddress),
          endAddress: formatAddress(log.stopAdress),
          endTimeString: log.endTime ? dateTimeToString(DateTime.fromISO(log.endTime), 'time-only') : null,
          startTimeString: log.startTime ? dateTimeToString(DateTime.fromISO(log.startTime), 'time-only') : null,
          distance: (log.distance || log.distance === 0) ? convertToSelectedUnit(log.distance, 1, 'distance') : '-',
          purpose: log.purpose.toLowerCase() === 'work' ? 'Business' : 'Private',
          startMileage: (log.startMileage || log.startMileage === 0) ? convertToSelectedUnit(log.startMileage, 0, 'distance') : '-',
        })
      }))
    }

    setKpiData([
      {
        title: "Trips with overweight",
        value: overweight_report?.data?.count ?? '-'
      },
      {
        title: "Of all trips",
        value: overweight_report?.data ? toPercentage(overweight_report.data.ratio) : '-',
        unit: overweight_report?.data ? '%' : '',
        color: percentageColor(overweight_report?.data?.ratio ?? 0),
        width: 180
      },
      {
        title: "Distance with overweight",
        value: overweight_report?.data?.distance ? convertToSelectedUnit(overweight_report.data.distance, 0, 'distance') : '-'
      },
      {
        title: "Of distance driven",
        value: overweight_report?.data ? toPercentage(overweight_report.data.distanceRatio) : '-',
        unit: overweight_report?.data ? '%' : '',
        color: percentageColor(overweight_report?.data?.distanceRatio ?? 0)
      },
      {
        title: "Hours of overweight",
        value: overweight_report?.data?.totalHours?.toFixed(0) ?? '-'
      }
    ])

    if (preparedData && preparedData.length > 0) {
      setData(getDataByDay(preparedData))
    } else {
      setData({})
    }
  }, [overweight_report])

  const toPercentage = (ratio) => `${(100 * ratio).toFixed(1)}`
  const percentageColor = (ratio) => ratio > 0.9 ? theme.colors.error : ratio > 0.67 ? theme.colors.yellowText : theme.colors.text

  // TODO: update to new table
  const tables = Object.keys(data).map(key => {
    const logs = data[key]
    return <TableWidgetSeparated
      tableTitle={getDayName(DateTime.fromISO(key))}
      key={key}
      data={logs}
      loadingStatus={loading.fetchWeightReport}
      totalItems={reportData}
      columns={log_columns}
      themes={themes}
      isSortable={false}
      pagination={false}
      onClickRow={goToWeightLogDetailsPage}
      rowsOptions={[logs.length ?? 0]}
    />
  })

  return (
    <ReportPage
      title={'Weight Report'}
      noDataMessage={'There are no weight warnings for the selected time period.'}
      disabled={disabled}
      noSearch
      timeSelectors={[timespanOptions.custom]}
      useOldDatePickers
      startDate={startDate}
      endDate={endDate}
      onStartDatePicked={onStartDatePicked}
      onEndDatePicked={onEndDatePicked}
      tablesInOwnSubPage
      isLoading={isLoading}
      fetchingDataFailed={fetchingFailed}
      subpageTitle={"Overweight Warnings" +
        (selectedVehicleId ?
          (' for ' + top_five_data.find(v => v.vehicleId === selectedVehicleId)?.name)
          : '')}
      kpi={kpiData ? <KpiRow title={'Status'} width={'100%'} bgColor={'white'} data={kpiData} isLoading={loading.progress} /> : null}
      tables={tables}
      reportGraph={top_five_data?.length > 0 && !loading.fetchWeightReport ?
        <TopFiveDisplay
          title={'Top 5 vehicles with overweight warnings'}
          unit={'%'}
          data={top_five_data}
          tooltipText={'Time spent with overweight'}
          onSelect={(selectedId) => {
            setSelectedVehicleId(selectedId)
            onVehicleSelected(selectedId)
          }}
          onDeselect={() => {
            setSelectedVehicleId(null)
            onVehicleSelected(null)
          }}
        /> : null}
    />
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps, {})(WeightReportSummary);
