import { DateTime } from 'luxon'
import { vehicleMatchesMapFiltering } from '../../../utils/vehicleUtils'
import { stopAnimation } from './animation'
import {
  setBounds,
  updateVehiclePositions,
  selectVehicleOnMap,
  selectRouteOnMap,
  selectAssetTrackerOnMap,
  unSelectVehicleOnMap,
  drawAllRoutes,
  clearAllRoutes,
  updateSource,
  isToday,
  updateBeaconPositions,
  updateVehicleWarningMarkers,
  clearAssetTrackerRadiusFromMap,
  selectSecurityAlarmIncidentOnMap,
  clearSecurityRadiusFromMap,
  flyToSecurityIncident
} from './utils'


export function updateMapHandler({vehicles, vehicles_to_render, beacons, map, themes, selectedVehicle, date, self, action, hasWarning, prevVehicles, onSelectVehicle, onSelectRoute, selectedRoute, selectedAssetTracker, map_settings, store, selectedSecurityAlarm, unselectSecurityAlarmIncidentOnMap}) {
  if(!(vehicles && vehicles.length) && !(beacons && beacons.length)) return

  if(map_settings.isLive) {
    const vehicles_to_render = vehicles?.filter(v => {
      return (
        vehicleMatchesMapFiltering(v, date, DateTime.local().minus({ hours: 24 }), DateTime.local(), map_settings) &&
        v.currentStatus?.geoLocation &&
        v.geoJSON
      )
    })
    
    const beacons_to_render = beacons?.filter(b => {
      return !b.isConnected && selectedVehicle && b.boxId === selectedVehicle.boxId
    })

    switch(action) {
      case 'data_updated':
        unSelectVehicleOnMap({self, map})        
        clearAllRoutes({map, vehicles: prevVehicles ?? vehicles})
        clearAssetTrackerRadiusFromMap(map)
        clearSecurityRadiusFromMap(map)
        updateVehiclePositions({vehicles: vehicles_to_render, map, date, map_settings})
        
        updateVehicleWarningMarkers({ map, vehicles: vehicles_to_render, beacons, selectedVehicle, isLive: map_settings.isLive })

        if (vehicles_to_render?.length) {
          updateSource({ id: 'lastKnownVehiclePositions', data: {
            type: 'FeatureCollection',
            features: []
          }, map })

          if (selectedVehicle && vehicles_to_render.some(v => selectedVehicle.boxId === v.boxId))
            selectVehicleOnMap({ map, self, date, hasWarning, onSelectVehicle, onSelectRoute, store })
          if (selectedAssetTracker && beacons_to_render.some(b => b.beaconId === selectedAssetTracker?.beaconId))
            selectAssetTrackerOnMap({ self, map, date})
          if(selectedSecurityAlarm)
            selectSecurityAlarmIncidentOnMap({self, map, date})
        }

        updateBeaconPositions(beacons_to_render, map)

        break
      case 'selected_vehicle':
        if (!vehicles_to_render?.length) break

        unSelectVehicleOnMap({self, map})
        clearAllRoutes({map, vehicles: vehicles})
        clearAssetTrackerRadiusFromMap(map)

        if(selectedVehicle)
          selectVehicleOnMap({ map, self, date, hasWarning, onSelectVehicle, onSelectRoute, store })
          
          updateVehicleWarningMarkers({ map, vehicles: vehicles_to_render, beacons, selectedVehicle, isLive: map_settings.isLive })

        updateBeaconPositions(beacons_to_render, map)

        break
      case 'selected_route':
        if (!vehicles_to_render?.length) break

        unSelectVehicleOnMap({self, map})
        clearAllRoutes({map, vehicles: vehicles})
        clearAssetTrackerRadiusFromMap(map)
  
        if(selectedRoute?.endTime)
          selectRouteOnMap({ map, self, date, hasWarning, onSelectVehicle, onSelectRoute, store })
  
          break
      case 'selected_asset_tracker':
        selectAssetTrackerOnMap({ map, self, date })
        break
      case 'mapStyle_changed':
        if (!vehicles_to_render?.length) break

        updateSource({ id: 'vehicles', data: {
          type: 'FeatureCollection',
          features: vehicles_to_render.map(v => ({
            ...v.geoJSON.position,
            properties: {
              name: v.name
            }
          }))
        }, map })
        unSelectVehicleOnMap({self, map})
        clearAssetTrackerRadiusFromMap(map)
        clearSecurityRadiusFromMap(map)
        clearAllRoutes({map, vehicles: prevVehicles ?? vehicles})

        updateVehicleWarningMarkers({ map, vehicles: vehicles_to_render, beacons, selectedVehicle, isLive: map_settings.isLive })
        updateBeaconPositions(beacons_to_render, map)

        if (selectedVehicle)
          selectVehicleOnMap({ map, self, date, hasWarning, onSelectVehicle, onSelectRoute: onSelectRoute, store })
        if (selectedAssetTracker)
          selectAssetTrackerOnMap({ self, map, date })
        if(selectedSecurityAlarm)
            selectSecurityAlarmIncidentOnMap({self, map, date})
        break
      default:
        console.warn('Unhandled exception: updatesVehicleOnMapHandler. ', action)
        break
    }
  } else {
    stopAnimation(self);
    updateSource({ id: 'beacons', data: {
      type: 'FeatureCollection',
      features: []
    }, map })
    
    if (selectedSecurityAlarm) {
      unselectSecurityAlarmIncidentOnMap()
      clearSecurityRadiusFromMap(map)
    }

    clearAssetTrackerRadiusFromMap(map)

    // updateSource({ id: 'vehicles', data: {
    //   type: 'FeatureCollection',
    //   features: []
    // }, map, onSelectVehicle: onSelectVehicle, onSelectRoute: onSelectRoute })


    // JSON stringify + parse to create deep copy, so original vehicle entries will be unaffected
    
    updateVehiclePositions({vehicles: vehicles_to_render.filter(v => !v.targets?.length), map, date, map_settings})

    updateVehicleWarningMarkers({ map, vehicles: vehicles_to_render, beacons, selectedVehicle, isLive: map_settings.isLive })

    unSelectVehicleOnMap({self, map})
    if(selectedRoute?.endTime) {
      selectRouteOnMap({ map, self, date, hasWarning, onSelectVehicle, onSelectRoute, store })
    }
    else if(selectedVehicle)
      selectVehicleOnMap({ map, self, date, hasWarning, onSelectVehicle, onSelectRoute, store })
    else {
      setBounds({map, vehicles_to_render, date})
    }

    switch(action) {
      default:
        clearAllRoutes({map, vehicles: prevVehicles ?? vehicles})
        drawAllRoutes({
          map: map, 
          vehicles: vehicles_to_render, 
          date: date, 
          selectedVehicleId: selectedVehicle?.boxId,
          onSelectVehicle: onSelectVehicle,
          onSelectRoute: onSelectRoute,
          selectedRoute: selectedRoute,
          map_settings: map_settings,
          store: store})

          const day = DateTime.fromISO(map_settings.date)
          const endTime = DateTime.local(day.year, day.month, day.day, map_settings.endClockTime.hour, map_settings.endClockTime.minute)      

        const getLastPosition = (v) => {
          return v.lastKnownPosition ? v.lastKnownPosition[endTime.toFormat('yyyy-LLL-dd-HH-mm')] : null
        }
        
        updateSource({ id: 'lastKnownVehiclePositions', data: {
          type: 'FeatureCollection',
          features: vehicles_to_render.filter(v => getLastPosition(v)?.latitude && getLastPosition(v)?.longitude).map(v => ({
            geometry: {
              type: 'Point',
              coordinates: [getLastPosition(v).longitude, getLastPosition(v).latitude]
            },
            properties: {
              name: v.name,
              bearing: getLastPosition(v).course
            },
          }))
        }, map })
        break
    }
  }
}