import { Box, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React, { Fragment, createContext, useContext, useEffect } from "react";
import View from "../../atoms/view/view";
import { WarningIcon } from "../../atoms/icons/warningIcon";
import { visuallyHidden } from '@mui/utils';
import { H5, SimpleTableValue } from "../../atoms/text/text";
import theme from "../../../theme";
import SortableArrowsIcon from '@modul-connect/shared/img/sortable_arrows.svg'
import UnSelectedBox from '@modul-connect/shared/img/check-box-outline.svg'
import SelectedBox from '@modul-connect/shared/img/check-box-ok.svg'
import { BubbleLoadingWidget } from "../bubbleLoadingWidget/bubbleLoadingWIdget";
import { CollapsibleContent, ExpandButton, cellPadding } from "./tableWidgetSeparated.v2";
import { CustomTooltip } from "../tooltip";

const getValueToCompare = (d1, d2, orderBy) => {
  const d1_sortValue = d1 && d1[orderBy]?.sortValue;
  const d2_sortValue = d2 && d2[orderBy]?.sortValue;
  const d1_hasSortValue = d1_sortValue || d1_sortValue === 0;
  const d2_hasSortValue = d2_sortValue || d2_sortValue === 0;
  // convoluted code ... purpose is to turn the values to lowercase, if string
  // and to use the sortValue for sorting if one exists
  const val1_d1 = d1_hasSortValue ? null : d1[orderBy]?.value1;
  const val2_d1 = d1_hasSortValue ? null : d1[orderBy]?.value2;
  const val_d1 = d1_hasSortValue ? d1_sortValue : d1[orderBy]?.value;
  const val1isStr_d1 = typeof val1_d1 === "string";
  const val2isStr_d1 = typeof val2_d1 === "string";
  const valIsStr_d1 = typeof val_d1 === "string";

  const toCompare1_d1 = val1isStr_d1 ? val1_d1.toLowerCase() : val1_d1;
  const toCompare2_d1 = val2isStr_d1 ? val2_d1.toLowerCase() : val2_d1;
  const toCompare_d1 = valIsStr_d1 ? val_d1.toLowerCase() : val_d1;

  const val1_d2 = d2_hasSortValue ? null : d2[orderBy]?.value1;
  const val2_d2 = d2_hasSortValue ? null : d2[orderBy]?.value2;
  const val_d2 = d2_hasSortValue ? d2_sortValue : d2[orderBy]?.value;
  const val1isStr_d2 = typeof val1_d2 === "string";
  const val2isStr_d2 = typeof val2_d2 === "string";
  const valIsStr_d2 = typeof val_d2 === "string";

  const toCompare1_d2 = val1isStr_d2 ? val1_d2.toLowerCase() : val1_d2;
  const toCompare2_d2 = val2isStr_d2 ? val2_d2.toLowerCase() : val2_d2;
  const toCompare_d2 = valIsStr_d2 ? val_d2.toLowerCase() : val_d2;

  if ((val_d1 === null || val_d1 === undefined) && (val1_d1 || val2_d1)) {
    return [toCompare1_d1 + toCompare2_d1, toCompare1_d2 + toCompare2_d2]
  }
  else return [toCompare_d1, toCompare_d2]
};

const TableTitle = () => {
  const tableContext = useContext(TableContext)
  const tableTitle = tableContext.tableTitle
  const dataColumns = tableContext.tableContext

  if (!tableTitle) return null

  return (
    <TableRow>
      <TableCell
        className={"separatedTableWidgetCell"}
        key={"header_text"}
        colSpan={dataColumns}
        style={{ padding: 0, backgroundColor: theme.colors.white }}
      >
        <View
          extend={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
            height: 35,
            padding: 0,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <H5
            extend={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "19px",
              color: theme.colors.black,
              textTransform: "none",
            }}
          >
            {tableTitle}
          </H5>
        </View>
      </TableCell>
    </TableRow>
  );
}

const iconWidth = 28

const TableContext = createContext({
  tableTitle: undefined,
  dataColumns: undefined,
  columnWidthStyle: undefined,
  handleRowSelection: undefined
})

const SimpleTable = ({
  data,
  prepareData,
  isLoading,
  loadingFailed,
  columns,
  setSelectedRow,
  defaultSortIndex,
  defaultSortOrder,
  tableTitle,
  showSortArrows,
  onTableEntryClick
}) =>{
  const [order, setOrder] = React.useState(defaultSortOrder ?? 'asc');
  const [orderBy, setOrderBy] = React.useState(defaultSortIndex ?? 0);

  const [preparedData, setPreparedData] = React.useState(prepareData ? (prepareData(data)) : data)


  useEffect(() => {setPreparedData(prepareData ? prepareData(data) : data)}, [data])

  const handleSortBy = (index) => {
    const isAsc = (orderBy === index) && (order === 'asc');
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(index);
  };

  var dataColumns = columns?.length 
  var headers = []
  for(let index = 0; index < dataColumns; index++){
    headers.push(
      columns[index].label ?? <img style={{ paddingRight: 5, }} src={SortableArrowsIcon} />
    )
  }

  const handleRowSelection = (row) => {
    setSelectedRow(row)
  }

  const columnWidthStyle = (column) => { 
    return {
    maxWidth: column.width ?? column.maxWidth ?? 'auto',
    minWidth: column.width ?? column.minWidth ?? 'auto',
    width: column.width ?? 'auto',
    padding: column.noPadding ? 0 : cellPadding,
    overflow: 'hidden',
  }}

  const divideData = (data) => {
    if (!data || !data.length) return data
    let dataToDivide = data
    let subArrays = []
    let dataUntilDivider

    while (dataToDivide?.length) {
      if (dataToDivide[0].isDivider) { // remove leading divider
        subArrays.push(dataToDivide[0])
        dataToDivide = dataToDivide.slice(1)
      }
      let nextDividerIndex = dataToDivide.findIndex(d => d.isDivider)

      if (nextDividerIndex === -1) dataUntilDivider = dataToDivide
      else dataUntilDivider = dataToDivide.slice(0, nextDividerIndex)
      
      subArrays.push(dataUntilDivider)
      dataToDivide = nextDividerIndex === -1 ? [] : dataToDivide.slice(nextDividerIndex)
    }
    return subArrays
  }

  const sortData = (data) => {
    if (!data || !data.length) return data

    let dividedData = divideData(data)

    let sortedData = []
    dividedData.forEach(row => {
      if (row.isDivider) return sortedData.push(row)
      else sortedData.push(...row.sort((d1, d2) => {
        const [val1, val2] = getValueToCompare(d1, d2, orderBy)
  
        return order === 'asc' ? (val1 < val2 ? -1 : 1) : (val2 < val1 ? -1 : 1)
      }))
    })
    return sortedData
  }

  const hasSortable = columns?.findIndex(c => c.isSortable) !== -1
  const hasLabel = columns?.findIndex(c => c.label) !== -1

  const showTable = preparedData?.length && (sortData(preparedData)?.length)

  return (
    <TableContext.Provider value={{
      tableTitle: tableTitle,
      dataColumns: dataColumns,
      columnWidthStyle: columnWidthStyle,
      handleRowSelection: handleRowSelection,
      onTableEntryClick: onTableEntryClick
    }}>
      <Table>
        {showTable ? (
          (hasSortable || hasLabel) ? <SimpleTableHead 
            headers={headers}
            columns={columns}
            handleSortBy={handleSortBy}
            orderBy={orderBy}
            order={order}
            showSortArrows={showSortArrows}
          /> : null
        ) : (
          <TableBody>
            <TableRow
              style={{
                backgroundColor: theme.colors.tableBackground1,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loadingFailed ? (
                <TableCell sx={{ borderBottom: "none" }}>
                  {"There was an issue trying to fetch the data from the server."}
                </TableCell>
              ) : isLoading || data?.length ? ( // if data but no preparedData, the component is still processing it
                <TableCell sx={{ borderBottom: "none" }}>
                  {" "}
                  <BubbleLoadingWidget horizontal text={"Loading ..."} />{" "}
                </TableCell>
              ) : (
                <TableCell sx={{ borderBottom: "none" }}>{"No data."}</TableCell>
              )}
            </TableRow>
          </TableBody>
        )}
        {showTable ? <TableBody>
          {sortData(preparedData)?.map((row, index) => {
            const collapsible = !row.isDivider && row.find(r => r.collapsibleComponent)?.collapsibleComponent
            return row.isDivider ? (
              <TableDivider
                key={"divider_" + index}
                text={row.text}
              />
            ) : (
              <ValueRow
                key={'row' + index}
                rowIndex={index}
                backgroundColor={
                  !(index % 2)
                    ? theme.colors.tableBackground1
                    : theme.colors.tableBackground2
                }
                row={row}
                columns={columns}
                collapsible={collapsible}
              />
            );
          })}

        </TableBody> : null}
      </Table>
    </TableContext.Provider>
  );
}

const SimpleTableHead = ({ headers, columns, handleSortBy, orderBy, order, showSortArrows }) => {
  const columnHeaderStyle = (column) => { return {
    borderBottom: "none",
  }}

  const columnWidthStyle = useContext(TableContext).columnWidthStyle

  return (
    <TableHead>
      <TableTitle/>

      <TableRow
        sx={{
          backgroundColor: theme.colors.tableBackground1,
          height: 40,
          borderBottom: `0.5px solid ${theme.colors.lightDividerGrey}`,
        }}
      >
        {headers?.map((header, index) => (
          <TableCell
            key={index}
            sx={{
              ...columnHeaderStyle(columns[index]),
              ...columnWidthStyle(columns[index]),
            }}
            onClick={() => {
               if (columns[index]?.isSortable) handleSortBy(index);
            }}
          >
            {columns[index]?.isSortable ? (
              <TableSortLabel
                active={orderBy === index}
                direction={orderBy === index ? order : "asc"}
                sx={{
                  display: "flex",
                  justifyContent: columns[index].align,
                }}
                IconComponent={() => {
                  return orderBy === index ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null;
                }}
              >
                {header?.toUpperCase ? header?.toUpperCase() : header}
                {showSortArrows ? <img style={{ paddingLeft: 10 }} src={SortableArrowsIcon} /> : null}
                </TableSortLabel>
            ) : (
              " "
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ValueRow = ({ backgroundColor, row, rowIndex, columns, collapsible}) => {
  const [collapsibleOpen, setCollapsibleOpen] = React.useState(false)
  const [showCollapsible, setShowCollapsible] = React.useState(false) // when it is closed, it should not even show, because otherwise we get a gap in the table

  const columnWidthStyle = useContext(TableContext).columnWidthStyle
  const onTableEntryClick = useContext(TableContext).onTableEntryClick
  return (
    <Fragment>
      <TableRow onClick={onTableEntryClick ? ()=>{onTableEntryClick(rowIndex, row.id)} : undefined} sx={{ backgroundColor: backgroundColor }}>
        {row.map((cell, index) => {
          return (
            <TableCell
              key={index}
              sx={{
                paddingTop: 1,
                paddingBottom: 1,
                borderBottom: "none",
                ...columnWidthStyle(columns[index]),
              }}
            >
              {cell?.component ? (
                cell.tooltip ? <CustomTooltip dontExpandDiv title={cell.tooltip}>{cell.component}</CustomTooltip> : cell.component
              ) : cell?.collapsibleComponent ? (
                <ExpandButton
                  rowId={rowIndex}
                  isOpen={collapsibleOpen}
                  onClick={() => {
                    if (collapsibleOpen) setCollapsibleOpen(false)
                    else {
                      setCollapsibleOpen(true)
                      cell?.onExpand(); setShowCollapsible(true)}
                    }
                  }
                />
              ) : (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: columns[index].align,
                    alignItems: "center",
                  }}
                >
                  {columns[index].icon &&
                  columns[index].iconPosition !== "after" ? (
                    <CellIcon
                      column={columns[index]}
                      cell={cell}
                    />
                  ) : null}
                  <CellValue column={columns[index]} cell={cell} />
                  {columns[index].icon &&
                  columns[index].iconPosition === "after" ? (
                    <CellIcon
                      column={columns[index]}
                      cell={cell}
                    />
                  ) : null}
                </View>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {collapsible && showCollapsible ? (
        <CollapsibleContent
          isOpen={collapsibleOpen}
          onClose={() => setShowCollapsible(false)}
          columns_total={columns.length}
          collapsibleComponent={collapsible}
        />
      ) : null}
    </Fragment>
  );
};

const TableDivider = ({ text }) => {
  const dataColumns = useContext(TableContext).dataColumns
  return (
    <TableRow
      sx={{
        backgroundColor: theme.colors.white,
        padding: 0,
        color: theme.colors.black,
        borderTop: `0.5px solid ${theme.colors.lightDividerGrey}`,
        borderBottom: `0.5px solid ${theme.colors.lightDividerGrey}`,
      }}
    >
      <TableCell sx={{ width: "100%", padding: 0 }} colSpan={dataColumns}>
        <View
          extend={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
            padding: cellPadding,
            width: "100%",
          }}
        >
          {text}
        </View>
      </TableCell>
    </TableRow>
  );
}

const CellIcon = ({ column, cell }) => {
  const iconComponent = <View style={{ display: "flex", flexDirection: "row", marginLeft: column.iconPosition === 'after' ? 5 : 0 }}>
    <View
      style={{
        flex: 1,
        justifyContent: column.align,
        alignItems: "center",
      }}
    >
      {cell.isSelectable ? (
        <img
          style={{ paddingRight: 5 }}
          src={cell.isSelected ? SelectedBox : UnSelectedBox}
          onClick={() => useContext(TableContext).handleRowSelection(row)}
        />
      ) : null}
    </View>
    {cell.warning ? (
      <WarningIcon size={"medium"} />
    ) : cell.icon ? (
      <View
        style={{
          display: "flex",
          justifyContent: column.align,
          alignItems: column.align,
          width: iconWidth,
        }}
      >
        <img style={{ width: column.iconWidth ?? 20 }} src={cell.icon} />
      </View>
    ) : 
        cell.iconComponent 
        ? <View style={{ 
            paddingRight: (cell.value || cell.value === 0 || cell.values) && column.iconPosition !== 'after' ? 10 : 'auto',
            paddingLeft: (cell.value || cell.value === 0 || cell.values) && column.iconPosition === 'after' ? 10 : 'auto',
            display: 'flex', justifyContent: 'center', alignItems: 'center'  
          }}>{cell.iconComponent}</View>
        : null  }
  </View>

  if (cell.tooltip) return <CustomTooltip dontExpandDiv title={cell.tooltip}>{iconComponent}</CustomTooltip>
  else return iconComponent
};

const CellValue = ({ column, cell }) => {
  if (cell.valueComponent) return cell.valueComponent
  
  return (
    <View
      style={{
        display: "flex",
        alignContent: column.align,
        justifyContent: column.align,
        alignItems: column.align,
        textDecorationLine: cell.onClick ? "underline" : "auto",
        fontWeight: cell.onClick ? 300 : "auto",
        backgroundColor: cell.backgroundColor ? cell.backgroundColor : null
      }}
      onClick={cell.onClick}
    >
      {cell?.value1 || cell?.value2 ? (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SimpleTableValue
            extend={{ fontSize: "12px", }}
            maxOneLine
            text={cell.value1}
            tooltip={cell.tooltip1}
          />
          <SimpleTableValue
            extend={{ fontSize: "12px" }}
            maxOneLine
            text={cell.value2}
            tooltip={cell.tooltip2}
          />
        </View>
      ) : (
        <SimpleTableValue maxTwoLines text={cell.value} tooltip={cell.tooltip}/>
      )}
    </View>
  );
};

export default SimpleTable;