import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import '../fleet/fleet.css';
import { dismissAjaxError } from '../../state/actions/app'
import DrivingLogPage from './drivingLog/drivingLogPage'
import BatteryReportPage from './batteryReport/batteryReportPage'
import WeightReportPage from './weightReport/weightReportPage'
import TollPassagePage from './tollPassages/tollPassagePage'
import TemperatureReportPage from './temperatureReport/temperatureReportPage'
import HumidityReportPage from './humidityReport/humidityReportPage'
import { isHumiditySensorBroken } from '@modul-connect/shared/config'
import AssetTrackerReportPage from './assetTrackerReport-debug/assetTrackerReportPage'
import ReportDashboardPage from './reportsDashboard'
import MainPage from '../mainPage'
import { useParams } from 'react-router-dom'
import { getServiceList } from '../../utils/ServiceList';
import { ServiceType } from '@modul-connect/shared/utils/services';
import LpsReportPage from './lpsReport/lpsReportPage';
import AssetsReportPage from './assetTrackerStatus/assetsReportPage'
import LpsReportPage_v2 from './lpsReport/lpsReportPage_v2';
import Checklist_demo from './demo/checklist_demo';
import { isAllowedToSeeDemoPages } from '../../utils/app';
import SecuritySystemReportPage from './securitySystem/securitySystemReportPage';
import AssetsReportPage_v2 from './assetTrackerStatus/assetsReportPage_v2';

const hideAssetTrackerPage = true // remove this once Modul gave their OK. For now, they want these reports accessible via direct URL, but hide the link from users.

const drivingReportsId = "driving-logs"
const batteryReportsId = "battery-reports"
const weightReportsId = "weight-reports"
const tollReportsId = "toll-reports"
const temperatureReportsId = "temperature-reports"
const humidityReportsId = "humidity-reports"
const assetTrackerReportsId = "asset-trackers-old"
const assetTrackersDebugId = "asset-tracker-reports"
const lithiumPowerSupplyId = "lps-reports-old"
const securityIncidentRepotsId = "security-incident"

const lithiumPowerSupplyId_v2 = "lps-reports"
const assetTrackerReportsId_v2 = "asset-trackers"

export const octopusDemoChecklist = "checklist-demo"

const allReports = [
  {
    id: "dashboard",
    title: "Dashboard",
    content: <ReportDashboardPage />,
    icon: "Dashboard",
  },
  {
    id: drivingReportsId,
    title: "Driving Reports",
    content: <DrivingLogPage />,
    icon: "DJBlack",
    subPages: [{ id: "main", title: "Driving Reports" }],
  },
  {
    id: batteryReportsId,
    title: "Battery Reports",
    content: <BatteryReportPage />,
    icon: "BMBlack",
    subPages: [{ id: "main", title: "Battery Reports" }],
  },
  {
    id: weightReportsId,
    title: "Weight Reports",
    content: <WeightReportPage />,
    icon: "VWBlack",
    subPages: [{ id: "main", title: "Weight Reports" }],
  },
  {
    id: tollReportsId,
    title: "Toll Reports",
    content: <TollPassagePage />,
    icon: "TRBlack",
    subPages: [{ id: "main", title: "Toll Reports" }],
  },
  {
    id: temperatureReportsId,
    title: "Temperature Reports",
    content: <TemperatureReportPage />,
    icon: "Temp",
    subPages: [{ id: "main", title: "Temp. Reports" }],
  },
  {
    id: humidityReportsId,
    title: "Humidity Reports",
    hide: isHumiditySensorBroken,
    content: <HumidityReportPage />,
    icon: "Hum",
    subPages: [{ id: "main", title: "Humidity Reports" }],
  },
  {
    id: assetTrackerReportsId,
    title: "Asset Trackers",
    content: <AssetsReportPage />,
    hide: true,
    icon: "AssetTracker",
    subPages: [{ id: "trackers", title: "Trackers" }, {id: 'maintenance', title: 'Maintenance'}],
  },
  {
    id: assetTrackerReportsId_v2,
    title: "Asset Trackers",
    content: <AssetsReportPage_v2 />,
    icon: "AssetTracker",
    subPages: [{ id: "trackers", title: "Trackers" }, {id: 'maintenance', title: 'Maintenance'}],
  },
  {
    id: assetTrackersDebugId,
    title: "Asset Tracker Reports",
    hide: hideAssetTrackerPage,
    content: <AssetTrackerReportPage />,
    icon: "AssetTracker",
    subPages: [{ id: "main", title: "Asset Trackers" }],
  },
  {
    id: lithiumPowerSupplyId,
    title: 'LPS',
    content: <LpsReportPage />,
    hide: true,
    icon: "LPS",
    subPages: [{ id: 'history', title: 'History' },{ id: 'footprint', title: 'Footprint' }]
  },
  {
    id: lithiumPowerSupplyId_v2,
    title: 'LPS',
    content: <LpsReportPage_v2 />,
    icon: "LPS",
    subPages: [{ id: 'history', title: 'History' },{ id: 'footprint', title: 'Footprint' }]
  },
  {
    id: securityIncidentRepotsId,
    title: 'Security System',
    content: <SecuritySystemReportPage />,
    icon: "SecuritySystem",
    subPages: [{ id: 'incidents', title: 'Incidents' }]
  }
];

const Reports = ({ 
  subtrees
}) => {
  const { option } = useParams()
  let blockScrolling = false

  const title = option?.includes('_') && option.split('_').length > 1 ? option.split('_')[1] : undefined;

  const services = getServiceList({ subtrees })
  const hasDrivingLogAccess = services.hasService(ServiceType.DrivingLogs)
  const hasBatteryAccess = services.hasService(ServiceType.BatteryWarnings)
  const hasWeightAccess = services.hasService(ServiceType.WeightReports)
  const hasTollAccess = services.hasService(ServiceType.TollPassages)
  const hasClimateAccess = services.hasService(ServiceType.ClimateReports)
  const hasAssetTrackersAccess = services.hasService(ServiceType.AssetTrackers)
  const hasLpsReports = services.hasService(ServiceType.LpsReports)
  const hasSecurityIncidentReports = services.hasService(ServiceType.SecuritySystemReports)

  const noMapPage = services.hasService(ServiceType.mapAccessNoGeo)

  const availableServices = noMapPage ? allReports : allReports.filter(r => r.id !== 'dashboard')

  const isAllowedToSeeDemo = isAllowedToSeeDemoPages()
  if (isAllowedToSeeDemo) availableServices.push({
      id: octopusDemoChecklist,
      title: 'Checklist',
      content: <Checklist_demo />,
      icon: "Checklist",
      subPages: [{ id: 'reports', title: 'Checklist reports' }, { id: 'defects', title: 'Defect reports' }, { id: 'settings', title: 'Settings' }]
  })

  const sidebarContent = availableServices.length
    ? availableServices
    : [{ title: 'You have no active services', content: null, icon: null }]

  const [wordReplacements, setWordReplacements] = useState(null)

  const hideBreadcrumbs = true //!selected || (selected?.length < 2) || sidebarContent[selected]?.id === 'dashboard'

  switch (title) { 
    case drivingReportsId: {
      if (!hasDrivingLogAccess) blockScrolling = true
      break
    }
    case batteryReportsId: {
      if (!hasBatteryAccess) blockScrolling = true
      break
    }
    case weightReportsId: {
      if (!hasWeightAccess) blockScrolling = true
      break
    }
    case tollReportsId: {
      if (!hasTollAccess) blockScrolling = true
      break
    }
    case temperatureReportsId: {
      if (!hasClimateAccess) blockScrolling = true
      break
    }
    case humidityReportsId: {
      if (!hasClimateAccess) blockScrolling = true
      break
    }
    case assetTrackerReportsId_v2:
    case assetTrackerReportsId: {
      if (!hasAssetTrackersAccess) blockScrolling = true
      break
    }
    case assetTrackersDebugId: {
      if (!hasAssetTrackersAccess) blockScrolling = true
      break
    }
    case lithiumPowerSupplyId: {
      if (!hasLpsReports) blockScrolling = true
      break
    }
    case lithiumPowerSupplyId_v2: {
      if (!hasLpsReports) blockScrolling = true
      break
    }
    case securityIncidentRepotsId:{
      if(!hasSecurityIncidentReports) blockScrolling = true
      break
    }
    default: break
  }

  return (
    <MainPage 
      rootURL={'reports'}
      landingPageURL={'/reports/dashboard'}
      initialPageIndex={0} // the initial value needs to match the value of the landing page (see routes file)
      hideBreadcrumbs={hideBreadcrumbs}
      breadCrumbWordReplacements={wordReplacements}
      sidebarContent={sidebarContent}
      blockScrolling={blockScrolling}
    />
  );
};

const mapStateToProps = ({ 
  subtrees
 }) => ({
  subtrees
});

const mapDispatchToProps = dispatch => ({
  dismissAjaxError: () => dispatch(dismissAjaxError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);