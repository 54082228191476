import { Divider, Fade, Modal } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import modal from '@modul-connect/shared/components/atoms/modal'
import Paper from '@modul-connect/shared/components/atoms/paper'
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import { InfoListItem } from "@modul-connect/shared/components/molecules/listitem/infoListItem";
import { checkTrackersBatteryIssue } from "../../../../utils/assets";
import BatteryFullIcon from '@modul-connect/shared/img/battery_full.svg'
import BatteryWarningIcon from '@modul-connect/shared/img/battery_warning.svg'
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils";
import { DateTime } from "luxon";
import { useAuth0 } from "@auth0/auth0-react";
import { changeVehicleOwner, selectAssetTracker, selectVehicle, updateTrackerInfo } from "../../../../state/actions/vehicles";
import { formatAddressTrackers, getPositionImageUrl } from "../../../../utils/vehicleUtils";
import { WarningIcon } from '@modul-connect/shared/components/atoms/icons/warningIcon'
import { useHistory } from 'react-router-dom';
import { Dropdown } from '@modul-connect/shared/components/molecules/dropdown/dropdown'
import { InputField } from '@modul-connect/shared/components/atoms/textInput/inputField'
import theme from "@modul-connect/shared/theme";

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    minWidth: 600,
  },
  side: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 10,
    border: '1px solid rgba(0, 0, 0, 0.27)',
    margin: 10
  },
  address: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '19px',
    textAlign: 'left',
    letterSpacing: '0em',
    color: '#222222',
  },
  date: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'left',
    letterSpacing: '0.04em',
    color: theme.colors.text_v2,
    marginBottom: 10
  }
}

const getTextByteSize = (text) => {
  let size = new Blob([text]).size;

  return size
}

const TrackerDetailsModal = ({
  openModal,
  onClose,
  beacon,
  vehicles,
  updateTrackerInfo,
  changeVehicleOwner,
  themes,
  vehicle_assets,
  asset_addresses,
  selectAssetTracker,
  selectVehicle,
  groupBy
}) => {
  var selectedTracker = vehicle_assets[vehicles?.filter(vehicle => vehicle?.vehicleName === beacon?.vehicleName)?.[0]?.boxId]?.filter(b => b.beaconId === beacon?.asset?.tracker?.beaconId)?.[0]
  var geoLocation = beacon?.asset?.tracker?.location
  var address = groupBy === 'Homebase' ? selectedTracker?.address?.formattedAddress 
      ? formatAddressTrackers( selectedTracker?.address)?.address + ', ' + formatAddressTrackers( selectedTracker?.address)?.postCodeCity
      : beacon?.isMoving ? 'Vehicle is driving' : 'Unknown address'
    : beacon?.asset?.address 
      ? formatAddressTrackers( beacon?.asset?.address)?.address + ', ' + formatAddressTrackers( beacon?.asset?.address)?.postCodeCity
      : asset_addresses[beacon?.asset?.tracker?.beaconId]?.address ? formatAddressTrackers( asset_addresses[beacon?.asset?.tracker?.beaconId]?.address)?.address + ', ' + formatAddressTrackers( asset_addresses[beacon?.asset?.tracker?.beaconId]?.address)?.postCodeCity : 'Unknown address'

  var timeStamp = beacon?.asset?.tracker?.currentStatus?.timeStamp ? beacon?.asset?.tracker?.currentStatus?.timeStamp : beacon?.asset?.tracker?.location?.timeStamp
  var date = dateTimeToString(DateTime.fromISO(timeStamp), 'date-and-time') === 'Invalid DateTime'
    ? '-'
    : dateTimeToString(DateTime.fromISO(timeStamp), 'date-and-time')?.replace(", ", "-")

  const history = useHistory()
  const goToAsset = () => {
    history.push('');
    selectVehicle(beacon?.boxId, false, true, false, true)
    selectAssetTracker(beacon?.asset?.tracker?.beaconId, beacon?.boxId)
  }

  const locationMissing = !(geoLocation?.longitude || geoLocation?.longitude === 0) || !(geoLocation?.longitude || geoLocation?.longitude === 0)

  return (
    <Modal
      open={openModal ? true : false}
      onClose={(event, reason) => {
        onClose()
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={openModal ? true : false}>
        <div>
          <Paper title={'Details – Asset Tracker'} extend={{ ...modal.container_v2, /* width: '40%', */ height: 'fit-content', width: 'fit-content', paddingBottom: 0, overflow: 'hidden' }}>
            <View style={styles.content}>
              <EditInfoSide 
                beacon={beacon} 
                onClose={onClose} 
                updateTrackerInfo={updateTrackerInfo} 
                changeVehicleOwner={changeVehicleOwner} 
                vehicles={vehicles} 
                groupBy={groupBy}
              />

              <View style={styles.side}>
                <View extend={{
                  height: '100%',
                  width: '100%',
                  marginBottom: 10 + 'px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  flex: 1,
                  padding: 0,
                }}>
                  {locationMissing ? null : <MapImage
                    position={{
                      course: 0,
                      latitude: geoLocation?.latitude,
                      longitude: geoLocation?.longitude,
                      speed: 0
                    }}
                    themes={themes}
                  />}
                </View>

                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View>
                      {
                        !beacon?.asset?.isInside
                          ? <WarningIcon size={20} extend={{ paddingRight: 5 }} />
                          : null
                      }
                    </View>
                    <View style={styles.address}>
                      {address}
                    </View>
                  </View>
                  <View style={{ ...styles.date, paddingLeft: !beacon?.asset?.isInside ? 25 : 0 }}>
                    {date}
                  </View>
                  <Button extend={{ flex: 1, marginBottom: 20 }} noPadding onClick={() => goToAsset()}>
                    {'View on map'}
                  </Button>
                </View>
              </View>

            </View>
          </Paper>
        </div>
      </Fade>
    </Modal>
  )
}

const MapImage = ({ themes, position }) => {
  const imageContainerRef = useRef()


  const EditInfoSide = ({ beacon }) => {
  const batteryLevel = beacon?.tracker?.currentStatus?.batteryLevel
  const hasBatteryLevel = batteryLevel || batteryLevel === 0
  const hasBatteryIssue = checkTrackersBatteryIssue(batteryLevel)
  return <View style={styles.side}>
    <InfoListItem title={'ID'} value={beacon?.tracker?.serialNumber ?? '-'} />

    <Divider />
    
    <InfoListItem title={'Added'} value={'TODO' ?? '-'} />

    <Divider />

    <InfoListItem
      title={'Battery Level'}
      value={hasBatteryLevel ? batteryLevel + '%' : '-'}
      valueIcon={hasBatteryLevel && (hasBatteryIssue ? BatteryWarningIcon : BatteryFullIcon)} />

    <Divider />


  </View>
}

const mapStateToProps = (props) => (props)

  const [imageHeight, setImageHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  }, [])

  useEffect(() => {
    handleResize()
  })

  function handleResize() {
    console.log('handleResize', imageContainerRef?.current?.clientWidth)
    setImageHeight(imageContainerRef?.current?.clientWidth)
    setImageWidth(imageContainerRef?.current?.clientWidth)
  }

  return (
    <img
      ref={imageContainerRef}
      style={{
        width: '100%',
        aspectRatio: 1,
      }}
      src={getPositionImageUrl(
        themes,
        position,
        '3A8643',
        imageHeight,
        imageWidth,
        15
      )}
      alt={"Tracker's position."}
    />
  )
}

const EditInfoSide = ({
  beacon,
  onClose,
  updateTrackerInfo,
  changeVehicleOwner,
  vehicles,
  groupBy
}) => {

  const batteryLevel = beacon?.asset?.tracker?.currentStatus?.batteryLevel
  const hasBatteryLevel = batteryLevel || batteryLevel === 0
  const hasBatteryIssue = checkTrackersBatteryIssue(batteryLevel)

  const [name, setName] = useState(beacon?.asset?.name);
  const [category, setCategory] = useState(beacon?.asset?.category);
  const [vehicleName, setVehicleName] = useState(beacon?.vehicleName);

  const { getAccessTokenSilently } = useAuth0();

  const oldName = beacon?.asset?.name
  const oldCategory = beacon?.asset?.category
  const oldVehicle = beacon
  const selectedVehicle = vehicles?.filter(vehicle => vehicle?.vehicleName === vehicleName)?.[0]

  return <View style={styles.side}>
    <View style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '30%'
    }}>
      <InfoListItem title={'ID'} value={beacon?.asset?.tracker?.serialNumber ?? '-'} />

      <Divider />

      <InfoListItem title={'Added'} value={dateTimeToString(DateTime.fromISO(beacon?.asset?.createdAt), 'date-only') ?? '-'} />

      <Divider />

      <InfoListItem
        title={'Battery Level'}
        value={hasBatteryLevel ? batteryLevel + '%' : '-'}
        valueIcon={hasBatteryLevel && (hasBatteryIssue ? BatteryWarningIcon : BatteryFullIcon)} />

      <Divider/>
    </View>


    <View style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 5
    }}>
      <InputField
        noBorder
        placeholder={beacon?.asset?.name ?? 'No name set'}
        value={name}
        onChange={setName}
        helperText={getTextByteSize(name) > 14 ? "Name too long" : null}
        error={getTextByteSize(name) > 14}
      />

      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

      <Dropdown size={"medium"} noBorder value={category} onChange={setCategory} options={toolsCategory.map(category => {return { key: category, value: category, name: category }})}/>

      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

      <Dropdown noBorder value={vehicleName} onChange={setVehicleName} options={vehicles.map(vehicle =>
              { return { key: vehicle.vehicleName, value: vehicle.vehicleName, name: vehicle.vehicleName}}
            )}/>

      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

      <View extend={{ ...modal.footer, justifyContent: 'center', marginTop: '24px' }}>
        <Button
          extend={{ flex: 1 }}
          noPadding
          onClick={() =>
            getAccessTokenSilently().then((accessToken) => {
              if (selectedVehicle.boxId === oldVehicle.boxId)
                updateTrackerInfo(accessToken, name, oldName, category, oldCategory, selectedVehicle.boxId, beacon.asset.tracker.beaconId, groupBy)
              else
                changeVehicleOwner(accessToken, name, category, selectedVehicle.boxId, oldVehicle, beacon.asset.tracker.beaconId, groupBy, selectedVehicle,)
              onClose()
            })}
          disabled={getTextByteSize(name) > 14}
        >
          {'Save'}
        </Button>
        <Button extend={{ flex: 1 }} noBg cancel onClick={() => onClose()}>
          {'Cancel'}
        </Button>
      </View>
    </View>
  </View>
}
const toolsCategory = [
  'Air Tools',
  'Axes',
  'Drills',
  'Compressors',
  'Ladders',
  'Lathes',
  'Nail Guns',
  'Power Ratchet Sets',
  'Saws',
  'Shovels',
  'Wrenches',
  'Other'
]

const mapStateToProps = ({themes, vehicle_assets, asset_addresses}) => ({themes, vehicle_assets, asset_addresses});

const mapDispatchToProps = dispatch => ({
  updateTrackerInfo: (accessToken, name, oldName, category, oldCategory, assignTo, beaconId, groupBy) => dispatch(updateTrackerInfo(accessToken, name, oldName, category, oldCategory, assignTo, beaconId, groupBy)),
  selectVehicle: (boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle) => dispatch(selectVehicle(boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle)),
  selectAssetTracker: (beaconId, boxId) => dispatch(selectAssetTracker(beaconId, boxId)),
  changeVehicleOwner: (accessToken, name, category, assignTo, oldOwner, beaconId, groupBy, vehicle) => dispatch(changeVehicleOwner(accessToken, name, category, assignTo, oldOwner, beaconId, groupBy, vehicle))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackerDetailsModal);
