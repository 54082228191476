import React, { useRef } from 'react'
import View from '@modul-connect/shared/components/atoms/view'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateTime } from "luxon";
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import theme from '@modul-connect/shared/theme';
import Calendar from '@modul-connect/shared/img/callendar_black.svg'
import { styles as textStyles } from '@modul-connect/shared/components/atoms/text/text'
import { Title } from '@modul-connect/shared/components/molecules/dropdown/dropdown'
import './daySelector-bottom.css'
import './daySelector-top.css'
import './daySelector-middle.css'


// TODO: the placeholder text does not look like the one on the comment
export const DateSelector = ({
  disableDaysBefore,
  disableDaysAfter,
  placeholder,
  date,
  onDayPicked,
  disabled,
  isRequired,
  title,
  opensOn='top'
}) => {
  const inputRef = useRef(null);

  const now = DateTime.local()

  const CalendarIcon = <img src={Calendar} />

  return (
    <View style={{ display: 'flex', flexDirection: 'column' }}>
      <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        {isRequired ? <View style={{ color: theme.colors.inputTextGrey }}>*</View> : null}
        <Title>{title}</Title>
      </View>
      <View 
        className={
          opensOn === 'top' ? "DaySelector-top" 
          : opensOn === 'bottom' ? "DaySelector-bottom" 
          : "DaySelector-middle"
        }
        style={{
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          width: '100%',
          justifyContent: 'space-between',
          border: `1px solid ${theme.colors.uiBorderColor}`,
          borderRadius: 4,
          padding: '0px 10px' }}
        onClick={() => { 
          if (inputRef?.current) inputRef.current.focus()
        }}>
        <DayPickerInput
          style={{
            ...textStyles.inputText,
          }}
          value={date && dateTimeToString(date, 'date-only')}
  /*         formatDate={(date) => {
            return dateTimeToString(DateTime.fromJSDate(date), 'date-only')
          }} */
          placeholder={placeholder ?? 'yymmdd'}
          dayPickerProps={{
            selectedDays: [date?.toJSDate()],
            disabledDays: (
              disableDaysBefore && disableDaysAfter ? { before: disableDaysBefore.toJSDate(), after: disableDaysAfter.toJSDate() } 
              : disableDaysBefore ? { before: disableDaysBefore.toJSDate() } 
              : disableDaysAfter ? { after: disableDaysAfter.toJSDate() }
              : undefined
            ),
            month: date?.toJSDate() ,
            fromMonth: disableDaysBefore ? new Date(disableDaysBefore.minus({months: 1}).year, disableDaysBefore.minus({months: 1}).month) : undefined, // calendar excludes the fromMonth -> need to go one back
            toMonth: disableDaysAfter ? new Date(disableDaysAfter.year, disableDaysAfter.month - 1) : undefined,
            onDayClick: (date) => { onDayPicked(DateTime.fromJSDate(date)) },
          }}
          inputProps={{
            ref: inputRef,
            style: {
              ...textStyles.inputText,
              backgroundColor: disabled ? theme.colors.grey : 'transparent', // TODO: the background color behind the icon is not the same ...
              cursor: 'pointer'
            },
            readOnly: true,
            disabled: disabled  ? true : false,
          }}
          //onDayChange={(day) => { manualInputDate = { date: day, is: dateIs } }}
        />
        <View style={{ alignSelf: 'center' }}>
          {CalendarIcon}
        </View>
      </View>
    </View>
  )
}

