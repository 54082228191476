import { convertToSelectedUnit } from "../../../../utils/unitConverter";
import NoteExists from '@modul-connect/shared/img/checkmark.svg'
import React from 'react'
import { groupingOptions } from "../drivingLogPage";

const minWidthDriverCol = 250
const minWidthVehicleCol = 220
const routesWidth = 130
const noteWidth = 130

const driverColumn = [{
  id: 'name',
  label: 'Driver',
  minWidth: minWidthDriverCol,
  hide: [],
  align: 'left'
}]

const vehicleColumn = [{
  id: 'name',
  label: 'Vehicle',
  minWidth: minWidthVehicleCol,
  hide: [],
  align: 'left'
}]

const commonColumns = [
  {
    id: 'numberOfRoutes',
    label: 'Routes',
    width: routesWidth,
    hide: []
  },
  {
    id: 'businessKilometers',
    format: (value) => (value || value === 0) ? convertToSelectedUnit(value, 1, 'distance') : '-',
    label: 'Business',
    hide: []
  },
  {
    id: 'privateKilometers',
    format: (value) => (value || value === 0) ? convertToSelectedUnit(value, 1, 'distance') : '-',
    label: 'Private',
    hide: []
  },
  {
    id: 'businessRoutesWithoutNotes',
    label: 'Note',
    width: noteWidth,
    warning: (value) => value > 0,
    icon: <img style={{ width: 30 }} src={NoteExists} />,
    showIcon: (value) => value === 0,
    hide: [],
    align: 'left'
  }
]

export const log_summary_columns_driver = driverColumn.concat(commonColumns)
export const log_summary_columns_vehicle = vehicleColumn.concat(commonColumns)


const routeNrWidth = 70
const startEndTimeWidth = 40

const log_columns = [
  {
    id: 'routeNumber',
    isCustomComponent: true,
    width: routeNrWidth,
    //isSortable: true
  },
  {
    id: 'startEndTime',
    hasValues: true,
    width: startEndTimeWidth,
    noPadding: true
  },
  {
    id: 'startEndAddress',
    hasValues: true,
    minWidth: minWidthDriverCol + routesWidth - (startEndTimeWidth + routeNrWidth)
  },
  {
    id: 'weight',
    hasValues: true,
    icon: true,
    iconWidth:16,
    minWidth: 150,
  },
  {
    id: 'purpose',
    hasValues: true,
    icon: true,
    iconWidth: 24,
    minWidth: 150,
//    isSortable: true
  },
  {
    id: 'distance',
    hasValues: true,
   // isSortable: true,
    minWidth: 120,
  },
  {
    id: 'hasNote',
    hasValues: true,
    icon: true,
    iconWidth: 24,
    width: 110,
 //   isSortable: true
  },
  {
    id: 'driver',
    hasValues: true,
    minWidth: 150,
  },
  {
    id: 'totalMileage',
    hasValues: true,
    icon: true,
    iconWidth: 24,
    minWidth: 175,
  },
  {
    id: 'expand'
  }
]

export const getDrivingLogsColumns = (groupBy, showStartWeight) => {
  const columns = groupBy === groupingOptions.byDriver ? log_columns : log_columns.map(col => (col.id === 'driver') ? {id: 'vehicle', hasValues: true, minWidth: 150} : col)
  if (!showStartWeight) return columns.filter(col => col.id !== 'weight')
  else return columns
}