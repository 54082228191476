import React, { useRef } from 'react'
import { connect } from 'react-redux'
import View from '@modul-connect/shared/components/atoms/view'
import { H3, DatePickerTitle } from '@modul-connect/shared/components/atoms/text';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateTime } from "luxon";
import { dateTimeToString } from '@modul-connect/shared/utils/dateTimeUtils'
import { styles } from '../datePickerStyles'
import theme from '@modul-connect/shared/theme';
import { CalendarIcon } from '../startEndMonthPicker/startEndMonthPicker';
import { timespanOptions } from '../timespanPickers/timespanPickers';

const StartEndDatePicker = ({ loading, startDate, endDate, onStartDatePicked, onEndDatePicked, disabled, noTitle }) => {
  let manualInputDate

  const startDatePickerRef = useRef()
  const endDatePickerRef = useRef()

  const isValidStartDate = (date) => {
    if (!date) {
      return false
    }
    let dateTime = DateTime.fromJSDate(date)
    if (dateTime.startOf('day') - endDate.startOf('day') > 0) {
      return false
    }
    if (dateTime.hasSame(startDate, 'day')) {
      return false
    }
    return true
  }

  const isValidEndDate = (date) => {
    if (!date) {
      return false
    }
    let dateTime = DateTime.fromJSDate(date)
    if (dateTime.startOf('day') - DateTime.local().startOf('day') > 0) {
      return false
    }
    if (startDate.startOf('day') - dateTime.startOf('day') > 0) {
      return false
    }
    if (dateTime.hasSame(endDate, 'day')) {
      return false
    }
    return true
  }

  return (
    <View extend={styles.dayPickerWithTitle}>
      <View extend={styles.dayPickerView}>
        {noTitle ? null : <View extend={styles.filterHeaderContainer}>
          <DatePickerTitle extend={{ width: '100%' }} title1={'Selected time'} title2={'period:'} />
        </View>}
        <View extend={[styles.filterSelectContainer, noTitle && {paddingLeft: 20}]}>
          <View extend={{  display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              datePickerRef={startDatePickerRef}
              disabledDays={{ after: endDate.toJSDate() }}
              startDate={startDate}
              endDate={endDate}
              onDayPicked={onStartDatePicked}
              loading={loading}
              dateIs='start'
              validityCheck={isValidStartDate}
              manualInputDate={manualInputDate}
            />
          </View>
          <H3 extend={{ paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: 'center', textAlign: 'center', lineHeight: 0 }}>―</H3>
          <View extend={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker
              disabled={disabled}
              datePickerRef={endDatePickerRef}
              disabledDays={{
                after: DateTime.local().toJSDate(),
                before: startDate.toJSDate()

              }}
              startDate={startDate}
              endDate={endDate}
              onDayPicked={onEndDatePicked}
              loading={loading}
              dateIs='end'
              validityCheck={isValidEndDate}
              manualInputDate={manualInputDate}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export const DatePicker = ({
  datePickerRef,
  disabledDays,
  startDate,
  endDate,
  onDayPicked,
  loading,
  dateIs,
  validityCheck,
  manualInputDate,
  disabled
}) => {

  var fired = false;

  document.addEventListener("keydown", (e) => {
    if (e.key === 'Enter') {
      if (!fired) {
        fired = true
        handleEnter()
      }
    }
  })

  document.addEventListener("keyup", (e) => {
    if (e.key === 'Enter') {
      fired = false
    }
  })

  const handleEnter = () => {
    if (!manualInputDate)
      return

    if (datePickerRef && datePickerRef.current) datePickerRef.current.hideDayPicker()

    if (validityCheck(manualInputDate.date)) {
      onDayPicked(DateTime.fromJSDate(manualInputDate.date))
    }
    else {
      datePickerRef.current.state.value = dateTimeToString(dateIs === 'start' ? startDate : endDate, 'date-only')
    }
    manualInputDate = null
  }

  const inputRef = useRef(null);

  return (
    <View 
      style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
      onClick={() => { 
        if (inputRef?.current) inputRef.current.focus()
       }}>
      <DayPickerInput
        ref={datePickerRef}
        style={{color: theme.colors.text}}
        value={dateIs === 'start' ? dateTimeToString(startDate, 'date-only') : dateTimeToString(endDate, 'date-only')}
        formatDate={(date) => {
          return dateTimeToString(DateTime.fromJSDate(date), 'date-only')
        }}
        placeholder='YYYY-MM-DD'
        dayPickerProps={{
          selectedDays: [startDate.toJSDate(), { from: startDate.toJSDate(), to: endDate.toJSDate() }],
          disabledDays: disabledDays,
          month: dateIs === 'start' ? startDate.toJSDate() : endDate.toJSDate(),
          fromMonth: dateIs === 'end' ? new Date(startDate.year, startDate.month - 1) : null,
          toMonth: dateIs === 'end' ? DateTime.local().toJSDate() : new Date(endDate.year, endDate.month - 1),
          modifiers: { start: startDate.toJSDate(), end: endDate.toJSDate() },
          onDayClick: (date) => { onDayPicked(DateTime.fromJSDate(date)) },
        }}
        inputProps={{
          ref: inputRef,
          style: {
            ...styles.inputField,
            backgroundColor: (disabled || loading?.progress === 'loading') ? theme.colors.grey : styles.inputField.backgroundColor
          },
          readOnly: true,
          disabled: (disabled || loading?.progress === 'loading') ? true : false,
        }}
        onDayChange={(day) => { manualInputDate = { date: day, is: dateIs } }}
        onDayPickerHide={() => {
          if (!manualInputDate)
            return
          if (validityCheck(manualInputDate.date)) {
            onDayPicked(DateTime.fromJSDate(manualInputDate.date))
          }
        }}
      />
      <CalendarIcon />
    </View>
  )
}

const mapStateToProps = props => props;

export default connect(mapStateToProps)(StartEndDatePicker)
