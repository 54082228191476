import React from "react";
import { createRoot } from 'react-dom/client';
import { createRenderer } from "fela";
import { RendererProvider, ThemeProvider } from "react-fela";
import webPreset from "fela-preset-web";
import theme from "@modul-connect/shared/theme";
import { Provider } from 'react-redux'
import configureStore from './state/configureStore'
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import baseStyles from "@modul-connect/shared/baseStyles";
import { BrowserRouter } from "react-router-dom";
// import { SwitchesProvider } from '@modul-connect/shared/hooks';
import { PersistGate } from 'redux-persist/integration/react'
import './index.css'

const renderer = createRenderer({
  plugins: [
    ...webPreset
    // other plugins
  ]
});

renderer.renderStatic(baseStyles);

export const reduxStore = configureStore()

const Root = () => {
  const {
    store,
    persistor
  } = reduxStore

  return (
    <RendererProvider renderer={renderer}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>

            {/* <SwitchesProvider> */}
            <BrowserRouter>
              <App/>
            </BrowserRouter>
          </PersistGate>
        </Provider>
        {/* </SwitchesProvider> */}
      </ThemeProvider>
    </RendererProvider>
  );
}

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
