import { dateTimeToEpoch } from "@modul-connect/shared/utils/dateTimeUtils";
import { DateTime } from "luxon";

export const selectVehicle = (boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle) => (
  {
    type: 'VEHICLE_SELECTED',
    boxId,
    shouldScroll,
    deselectIfAlreadySelected,
    zoomToRoutes,
    zoomToVehicle
  }
)

export const selectAssetTracker = (beaconId, boxId) => (
  {
    type: 'ASSET_TRACKER_SELECTED',
    beaconId,
    boxId
  }
)

export const changeNameAssetTracker = (beaconId, telematicsId, boxId, name) => (
  {
    type: 'ASSET_TRACKER_NAME_CHANGED',
    beaconId,
    telematicsId,
    boxId,
    name
  }
)

export const changeCategoryAssetTracker = (beaconId, telematicsId, boxId, category) => (
  {
    type: 'ASSET_TRACKER_CATEGORY_CHANGED',
    beaconId,
    telematicsId,
    boxId,
    category
  }
)

export const removeAssetTracker = (beaconId) => (
  {
    type: 'ASSET_TRACKER_REMOVED',
    beaconId
  }
)

export const assignedAssetTracker = (beaconId) => (
  {
    type: 'ASSET_TRACKER_ASSIGNED',
    beaconId
  }
)

export const foundAssetTracker = (beaconId) => (
  {
    type: 'ASSET_TRACKER_FOUND',
    beaconId
  }
)

export const missingAssetTracker = (beaconId) => (
  {
    type: 'ASSET_TRACKER_IS_MISSING',
    beaconId
  }
)

export const deselectAssetTracker = (beaconId, boxId) => (
  {
    type: 'ASSET_TRACKER_DESELECTED',
    beaconId,
    boxId
  }
)

export const clearAssetTrackers = () => (
  {
    type: 'CLEAR_ASSET_TRACKERS',
  }
)


export const selectRoute = (boxId, endTime) => (
  {
    type: 'ROUTE_SELECTED',
    boxId,
    endTime
  }
)

export const unselectRoute = () => (
  {
    type: 'ROUTE_UNSELECTED',
  }
)

export const addVehicle = vehicle => (
  {
    type: 'VEHICLE_ADDED',
    vehicle
  }
)

export const resetVehicles = () => (
  {
    type: 'VEHICLES_RESET'
  }
)

export const changeVehicleName = (accessToken, vehicleId, newName) => (
  {
    type: 'CHANGE_VEHICLE_NAME',
    accessToken: accessToken,
    vehicleId: vehicleId,
    newName: newName
  }
)

export const fetchVehicles = (accessToken) => (
  {
    type: 'FETCH_VEHICLES',
    accessToken
  }
)

export const fetchVehicle = (accessToken, boxId) => (
  {
    type: 'FETCH_VEHICLE',
    accessToken,
    boxId
  }
)

export const fetchBeaconSnapshot = (accessToken) => (
  {
    type: 'FETCH_BEACON_SNAPSHOTS',
    accessToken,
  }
)

export const fetchVehiclesData = (accessToken) => (
  {
    type: 'FETCH_VEHICLES_DATA',
    accessToken,
  }
)

export const fetchVehiclesSorted = (accessToken, options) => (
  {
    type: 'FETCH_VEHICLES_SORTED',
    accessToken,
    options
  }
)

export const fetchVehicleRoutes = (accessToken, start, end, skip, boxId) => (
  {
    type: 'FETCH_VEHICLE_ROUTES',
    accessToken,
    start,
    end,
    startEpoch: start && start.toSeconds().toFixed(0),
    endEpoch: end && end.toSeconds().toFixed(0),
    skip,
    boxId,
  }
)


export const fetchVehicleRoutesDetailed = (accessToken, start, end, skip) => (
  {
    type: 'FETCH_VEHICLE_ROUTES_DETAILED',
    accessToken,
    start,
    end,
    startEpoch: start && start.toSeconds().toFixed(0),
    endEpoch: end && end.toSeconds().toFixed(0),
    skip
  }
)


export const fetchVehiclePositions = (accessToken, start, end) => (
  {
    type: 'FETCH_VEHICLE_POSITIONS',
    accessToken,
    start,
    end,
    startEpoch: start && start.toSeconds().toFixed(0),
    endEpoch: end && end.toSeconds().toFixed(0)
  }
)

export const fetchLastKnownVehiclePosition = (accessToken, start, end) => (
  {
    type: 'FETCH_LAST_KNOWN_POSITIONS',
    accessToken,
    start,
    end,
    startEpoch: start && start.toSeconds().toFixed(0),
    endEpoch: end && end.toSeconds().toFixed(0)
  }
)

export const fetchLastKnownVehiclePosition_v2 = (accessToken, start, end) => (
  {
    type: 'FETCH_LAST_KNOWN_POSITIONS_V2',
    accessToken,
    start,
    end,
    startEpoch: start && start.toSeconds().toFixed(0),
    endEpoch: end && end.toSeconds().toFixed(0)
  }
)
 
export const fetchVehicleWeights = (accessToken, start, end, id, samplingInterval) => (
  {
    type: 'FETCH_VEHICLE_WEIGHTS',
    accessToken,
    start,
    end,
    startEpoch: start ? dateTimeToEpoch(start) : null,
    endEpoch: end ? dateTimeToEpoch(end) : null,
    id: id,
    samplingInterval: samplingInterval
  }
)

export const fetchVehicleBatteryLogs = (accessToken, start, end, id, samplingInterval) => (
  {
    type: 'FETCH_VEHICLE_BATTERY_LOGS',
    accessToken,
    start,
    end,
    startEpoch: start ? dateTimeToEpoch(start) : null,
    endEpoch: end ? dateTimeToEpoch(end) : null,
    id: id,
    samplingInterval: samplingInterval
  }
)

export const fetchVehicleTemperatureLogs = (accessToken, start, end, id, samplingInterval) => (
  {
    type: 'FETCH_VEHICLE_TEMPERATURE_LOGS',
    accessToken,
    start,
    end,
    startEpoch: start ? dateTimeToEpoch(start) : null,
    endEpoch: end ? dateTimeToEpoch(end) : null,
    id: id,
    samplingInterval: samplingInterval
  }
)

export const fetchVehicleHumidityLogs = (accessToken, start, end, id, samplingInterval) => (
  {
    type: 'FETCH_VEHICLE_HUMIDITY_LOGS',
    accessToken,
    start,
    end,
    startEpoch: start ? dateTimeToEpoch(start) : null,
    endEpoch: end ? dateTimeToEpoch(end) : null,
    id: id,
    samplingInterval: samplingInterval
  }
)

export const fetchLpsBatteryLogs = (accessToken, start, end, id, samplingInterval) => (
  {
    type: 'FETCH_LPS_BATTERY_LOGS',
    accessToken,
    start,
    end,
    startEpoch: start ? dateTimeToEpoch(start) : null,
    endEpoch: end ? dateTimeToEpoch(end) : null,
    id: id,
    samplingInterval: samplingInterval
  }
)


export const fetchOverweightReport = (accessToken, start, end, boxId) => (
  {
    type: 'FETCH_OVERWEIGHT_REPORT',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId: boxId
  }
)

export const fetchOverweightReportSummaries = (accessToken, start, end, desc ) => (
  {
    type: 'FETCH_OVERWEIGHT_REPORT_SUMMARIES',
    accessToken,
    start: dateTimeToEpoch(start),
    end: dateTimeToEpoch(end, "floor"),
    desc
  }
)

export const fetchTyrePressureReport = (accessToken, start, end, boxId) => (
  {
    type: 'FETCH_TYRE_PRESSURE_REPORT',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId: boxId
  }
)

export const fetchBatteryReport = (accessToken, start, end, boxId) => (
  {
    type: 'FETCH_BATTERY_REPORT',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId: boxId
  }
)

export const fetchTemperatureReport = (accessToken, start, end, boxId) => (
  {
    type: 'FETCH_TEMPERATURE_REPORT',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId: boxId
  }
)

export const fetchAssetTrackerReport = (accessToken, start, end, boxId) => (
  {
    type: 'FETCH_ASSET_TRACKER_REPORT',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId: boxId
  }
)

export const fetchHumidityReport = (accessToken, start, end, boxId) => (
  {
    type: 'FETCH_HUMIDITY_REPORT',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId: boxId
  }
)

export const fetchSecurityIncidentSummaryReport = (accessToken, start, end) => (
  {
    type: 'FETCH_SECURITY_REPORTS_SUMMARY',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
  }
)

export const fetchVehiclesSecurityReport = (accessToken, start, end, boxId, vin) => (
  {
    type: 'FETCH_VEHICLES_SECURITY_REPORTS',
    accessToken,
    start: !start ? start : dateTimeToEpoch(start),
    end: !end ? end : dateTimeToEpoch(end, "floor"),
    boxId,
    vin
  }
)


export const fetchDrivingLogs = (accessToken, boxId, driverId, deviceType, start, end) => (
  {
    type: 'FETCH_DRIVING_LOGS',
    accessToken,
    boxId,
    driverId,
    deviceType,
    start: !start ? start : dateTimeToEpoch(start.startOf('day')),
    end: !end ? end : dateTimeToEpoch(end.endOf('day'), "floor"),
  }
)

export const downloadDrivingLogs = (accessToken, boxId, driverId, deviceType, start, end, groupBy) => (
  {
    type: 'DOWNLOAD_DRIVING_LOGS',
    accessToken,
    boxId,
    driverId,
    deviceType,
    start: !start ? start : dateTimeToEpoch(start.startOf('day')),
    end: !end ? end : dateTimeToEpoch(end.endOf('day'), "floor"),
    groupBy: groupBy
  }
)

export const fetchDrivingLogDetails = (accessToken, start, end, boxId)  => (
  {
    type: 'FETCH_DRIVING_LOG_DETAILS',
    accessToken,
    boxId: boxId,
    start: start && dateTimeToEpoch(start),
    end: end && dateTimeToEpoch(end),
  }
)

export const updateDrivingLogNote = (accessToken, boxId, driverId, logId, telematicsId, startTime, note, previousNote, purpose) => (
  {
    type: 'UPDATE_DRIVING_LOG_NOTE',
    accessToken,
    boxId,
    driverId,
    logId,
    telematicsId,
    startTime: dateTimeToEpoch(startTime),
    note,
    previousNote,
    purpose
  }
)

export const climateSensorReportTypeOptions = {
  temperature: 'temperature',
  humidity: 'humidity'
}
export const downloadClimateLogs = (accessToken, vehicle, deviceType, start, end, samplingInterval, avgOverMinutes, typeOfReport) => (
  {
    type: 'DOWNLOAD_CLIMATE_LOGS',
    accessToken,
    vehicle,
    deviceType,
    start: !start ? start : dateTimeToEpoch(start.startOf('day')),
    end: !end ? end : dateTimeToEpoch(end.endOf('day'), "floor"),
    samplingInterval,
    avgOverMinutes,
    typeOfReport
  }
)

export const fetchWarningLogs = (accessToken, boxId, start, end, warning_type) => (
  {
    type: 'FETCH_WARNING_LOGS',
    accessToken,
    boxId,
    start: start ? dateTimeToEpoch(start) : null,
    end: end ? dateTimeToEpoch(end, "floor") : null,
    warning_type: warning_type
  }
)

export const fetchTollReport = (accessToken, boxId, driverId, start, end) => (
  {
    type: 'FETCH_TOLL_REPORT',
    accessToken,
    boxId,
    driverId,
    start: start ? dateTimeToEpoch(start) : null,
    end: end ? dateTimeToEpoch(end) : null
  }
)

export const fetchTollReportSummaries = (accessToken, boxIds, start, end, groupBy) => (
  {
    type: 'FETCH_TOLL_REPORT_SUMMARIES',
    accessToken,
    boxIds,
    start: dateTimeToEpoch(start.startOf('day')),
    end: dateTimeToEpoch(end.endOf('day'), "floor"),
    groupBy: groupBy
  }
)

export const downloadTollReport = (accessToken, boxId, userId, start, end, groupBy) => (
  {
    type: 'DOWNLOAD_TOLL_REPORT',
    accessToken,
    boxId,
    userId,
    start: dateTimeToEpoch(start.startOf('day')),
    end: dateTimeToEpoch(end.endOf('day'), "floor"),
    groupBy
  }
)

export const fetchDrivingLogSummaries = (accessToken, start, end,groupBy ) => (
  {
    type: 'FETCH_DRIVING_LOG_SUMMARIES',
    accessToken,
    start: dateTimeToEpoch(start),
    end: dateTimeToEpoch(end, "floor"),
    groupBy: groupBy
  }
)

export const clearAnimationTargets = () => (
  {
    type: 'CLEAR_ANIMATION_TARGETS',
  }
)

export const fetchMileage = (accessToken, boxId) => (
  {    
    type: 'FETCH_MILEAGE',
    accessToken,
    boxId: boxId
  }
)

export const fetchCurrentDrivers = (accessToken, boxId) => (
  {
    type: 'FETCH_CURRENT_DRIVERS',
    accessToken,
    boxId
  }
)

export const updateMileage = (accessToken, boxId, mileage, timestamp) => (
  {
    type: 'UPDATE_MILEAGE',
    accessToken,
    boxId,
    mileage,
    timestampEpochs: timestamp ? dateTimeToEpoch(timestamp, "floor") : Math.floor(DateTime.utc().toSeconds())
  }
)

export const setLicenseNumber = (accessToken, boxId, licenseNumber) => (
  {
    type: 'SET_LICENSE_NUMBER',
    accessToken,
    boxId,
    licenseNumber
  }
)

// TODO: If we ever put this back in, need to make sure we actually send startTimeUTC and endTimeUTC. (Depending on how server-side is implemented?) Not currently implemented
export const designateCurrentDriver = (accessToken, boxId, driver, options = { dates: null, startTimeUTC: null, endTimeUTC: null }) => (
  {
    type: 'DESIGNATE_CURRENT_DRIVER',
    accessToken,
    boxId,
    dates: options.date,
    startTimeUTC: options.startTimeUTC,
    endTimeUTC: options.endTimeUTC,
    driver
  }
)
export const fetchAssetTrackers = (accessToken, byVehicle ) => (
  {
    type: 'FETCH_ASSET_TRACKERS', 
    accessToken, 
    byVehicle
  }
)

export const fetchAssetTrackers_v2 = (accessToken, byVehicle ) => (
  {
    type: 'FETCH_ASSET_TRACKERS_V2', 
    accessToken, 
    byVehicle
  }
)

export const updateNotificationsOnTrackers = (accessToken, trackers, isEnabled) => (
  {
    type: 'UPDATE_NOTIFICATIONS_ON_TRACKERS',
    accessToken,
    trackers,
    isEnabled
  }
)

export const fetchAssetTrackerAddress = (accessToken, beaconId) => (
  {
    type: 'FETCH_TRACKER_ADDRESS', 
    accessToken, 
    beaconId
  }
)

export const fetchAssetTrackerAddresses = (accessToken, boxId) => (
  {
    type: 'FETCH_ASSET_TRACKER_ADDRESSES', 
    accessToken, 
    boxId
  }
)

export const fetchAssetTrackerAddresses_v2 = (accessToken, boxId) => (
  {
    type: 'FETCH_ASSET_TRACKER_ADDRESSES_V2', 
    accessToken, 
    boxId
  }
)

export const startSignalRConnection = (accessToken) => (
  {
    type: 'START_SIGNALR_CONNECTION',
    accessToken
  }
)

export const stopSignalRConnection = connection => (
  {
    type: 'STOP_SIGNALR_CONNECTION',
    connection
  }
)

export const updateAnimationSpeed = (boxId, speed) => (
  {
    type: 'UPDATE_ANIMATION_SPEED',
    boxId,
    speed
  }
)

export const updateTrackerInfo = (accessToken, name, oldName, category, oldCategory, assignTo, beaconId, groupBy) => (
  {
    type: 'UPDATE_TRACKER_INFO',
    accessToken, 
    name, 
    oldName,
    category,
    oldCategory,
    assignTo,
    beaconId,
    groupBy
  }
)

export const changeVehicleOwner = (accessToken, name, category, assignTo, oldOwner ,beaconId, groupBy, vehicle) => ({
  type: 'UPDATE_TRACKER_OWNER',
  accessToken,
  name, 
  category,
  assignTo,
  oldOwner,
  beaconId, 
  groupBy,
  vehicle
})

export const fetchLPSReportSummaries = (accessToken, start, end) => ({
  type: 'FETCH_LPS_REPORT_SUMMARIES',
  accessToken,
  start: dateTimeToEpoch(start, 'ceil'),
  end: dateTimeToEpoch(end, 'floor'),
})

export const fetchLPSReportSummaries_v2 = (accessToken, start, end) => ({
  type: 'FETCH_LPS_REPORT_SUMMARIES_V2',
  accessToken,
  start: dateTimeToEpoch(start, 'ceil'),
  end: dateTimeToEpoch(end, 'floor'),
})

export const fetchCharginData = (accessToken, boxId, start, end) => (
  {
    type: 'FETCH_LPS_CHARGING_DATA',
    accessToken,
    boxId,
    start: dateTimeToEpoch(start),
    end: dateTimeToEpoch(end),
  }
)

export const fetchChargingData = (accessToken, boxId, start, end) => (
  {
    type: 'FETCH_LPS_CHARGING_DATA_V2',
    accessToken,
    boxId,
    start: dateTimeToEpoch(start),
    end: dateTimeToEpoch(end),
  }
)

export const fetchLPSFootprint = (accessToken, start, end) => (
  {
    type: 'FETCH_LPS_FOOTPRINT',
    accessToken,
    start: dateTimeToEpoch(start),
    end: dateTimeToEpoch(end),
  }
)

export const fetchLPSFootprint_v2 = (accessToken, start, end) => (
  {
    type: 'FETCH_LPS_FOOTPRINT_V2',
    accessToken,
    start: dateTimeToEpoch(start, 'ceil'),
    end: dateTimeToEpoch(end, 'floor'),
  }
)

export const fetchMaintenanceTasks = (accessToken, homebase) => (
  { 
    type: 'FETCH_MAINTENANCE_TASKS',
    accessToken,
    homebase
  }
)

export const fetchMaintenanceTasksSummary = (accessToken) => (
  { 
    type: 'FETCH_MAINTENANCE_TASKS_SUMMARY',
    accessToken
  }
)


export const fetchMaintenancePlans = (accessToken) => (
  { 
    type: 'FETCH_MAINTENANCE_PLANS',
    accessToken
  }
)

export const fetchMaintenanceCategories = (accessToken) => (
  { 
    type: 'FETCH_MAINTENANCE_CATEGORIES',
    accessToken
  }
)
export const fetchMaintenanceHomebases = (accessToken) => (
  { 
    type: 'FETCH_MAINTENANCE_HOMEBASES',
    accessToken
  }
)

export const uploadImage = (accessToken, image, typeOfImage) => (
  { 
    type: 'UPLOAD_IMAGE',
    accessToken,
    image,
    typeOfImage,
  }
)
export const clearUploadedImages = () => (
  { 
    type: 'CLEAR_UPLOADED_IMAGES',
  }
)

export const clearUploadedDocuments = () => (
  { 
    type: 'CLEAR_UPLOADED_DOCUMENTS',
  }
)

export const uploadDocument = (accessToken, document) => (
  { 
    type: 'UPLOAD_DOCUMENT',
    accessToken,
    document,
  }
)

export const removeImage = (accessToken, imageId) => (
  { 
    type: 'REMOVE_IMAGE',
    accessToken,
    imageId
  }
)

export const removeDocument = (accessToken, documentId) => ({
  type: 'REMOVE_DOCUMENT',
  accessToken,
  documentId
})

export const createAssetForMaintenance = (
  accessToken, 
  organisationId,
  assetName,
  category,
  homebase,
  homebaseType,
  brand,
  serialNumber,
  frequency,
  dueDate,
  instructions,
  maintenanceName,
  assetImages,
  serialImages) => (
  { 
    type: 'CREATE_ASSET_FOR_MAINTENANCE',
    accessToken,
    organisationId,
    assetName,
    category,
    homebase,
    homebaseType,
    brand,
    serialNumber,
    frequency,
    dueDate,
    instructions,
    maintenanceName,
    assetImages,
    serialImages
    } 
)

export const createNewHomebase = (
  accessToken, 
  organisationId,
  homebaseName,
  homebaseType) => (
  {
    type: 'CREATE_NEW_HOMEBASE',
    accessToken, 
    organisationId,
    homebaseName,
    homebaseType
  }
)

export const removeAssetAndMaintenancePlan = (
  accessToken, 
  assetId,
  maintenancePlanId,
) => (
  {
    type: 'REMOVE_ASSET_AND_MAINTENANCE_PLAN',
    accessToken, 
    assetId,
    maintenancePlanId,
  }
)


export const selectSecurityAlarmIncident = (alarmId, longitude, latitude, boxId) => (
  {
    type: 'SECURITY_ALARM_INCIDENT_SELECTED',
    alarmId,
    longitude,
    latitude,
    boxId
  }
)

export const unselectSecurityAlarmIncidentOnMap = () => ({
  type: 'SECURITY_ALARM_INCIDENT_UNSELECTED'
})

export const fetchVehiclesServices = (accessToken)=> ({
  type: 'FETCH_VEHICLES_SECURITY_SYSTEM_STATUS',
  accessToken
})