import React, { useContext } from "react";
import { AssetMaintenanceTaskContext } from "../maintenanceDetailsModal";
import View from '@modul-connect/shared/components/atoms/view'
import { ContainerWithBorder } from '@modul-connect/shared/components/atoms/containerWithBorder'
import CircularArrows from '@modul-connect/shared/img/circular_arrows.svg'
import CloudUpload from '@modul-connect/shared/img/cloud_upload.svg'
import { CircularProgress } from "@material-ui/core";
import Image  from '@modul-connect/shared/components/atoms/image'
import { Link, DownloadLink } from '@modul-connect/shared/components/atoms/text/text'


export const ImageSection = () => {
  const context = useContext(AssetMaintenanceTaskContext)

  const assetImage = context.images?.find(i => i.typeOfImage === 'Asset')
  const serialImage = context.images?.find(i => i.typeOfImage === 'SerialNumber')
  return ( 
    <View style={{
      display:'flex',
      flexDirection: 'row',
      width: "100%",
      height: context.images?.length ? 200 : 'fit-content',
      gap: 20,
      alignItems: 'center',
      justifyContent: 'flex-start'
      }}>
        <ContainerWithBorder>
          <ImageInput image={assetImage} typeOfImage={'Asset'} openUploadImage={context.openUploadImage} fullSize={context.images?.length > 0}/>
        </ContainerWithBorder>

        <ContainerWithBorder>
          <ImageInput image={serialImage} typeOfImage={'SerialNumber'} openUploadImage={context.openUploadImage} fullSize={context.images?.length > 0}/>
        </ContainerWithBorder>
    </View>
  );
};

const minImageWidth = 155
const maxImageHeight = 120
const ImageInput = ({
  image,
  openUploadImage,
  typeOfImage,
  fullSize
}) =>
{
  const context = useContext(AssetMaintenanceTaskContext)
  const updatedImage = context.maintenance_plans?.images?.find(i => i.typeOfImage === typeOfImage)
  const uploadingImage = context?.saving?.uploadImage === 'uploading' && updatedImage
  const fullView = image?.imageUrl || updatedImage || context.maintenance_plans?.images?.length > 0 || fullSize

  return (
    <View style={{display: 'flex' , flexDirection: 'column', gap: 12, alignSelf: 'flex-start', justifyContent: 'center', width: '100%'}}>
      {
        fullView
        ? <View style={{display: 'flex', justifyContent: 'center', minWidth: minImageWidth, maxWidth: '100%', height: maxImageHeight}}>
          {
            updatedImage?.fileData?.blobUrl
            ? <Image mode='fit' src={updatedImage?.fileData?.blobUrl} extend={{minWidth: minImageWidth, maxWidth: '100%', maxHeight: maxImageHeight}}/>
            : uploadingImage 
              ? <CircularProgress style={{alignSelf: 'center'}} size={50} color="inherit"/>
              : image?.imageUrl
                ? <Image mode='fit' src={image?.imageUrl} extend={{minWidth: minImageWidth, maxWidth: '100%', height: maxImageHeight}}/>
                : null
          }
          </View>
        : null
      }

      <View>
        <View style={{display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'space-between',  cursor: 'pointer'}} onClick={() =>  {
          context.setModalForUploadingImage(typeOfImage)
          openUploadImage(true)
          }}>

         <Link v2 onClick={() => {context.setModalForUploadingImage(typeOfImage)}} >
            {
              image?.imageUrl 
              ? `Change image` 
              : `Upload image: ${typeOfImage === 'SerialNumber' ? 'Serial number ': typeOfImage}`
            }
          </Link>
          {
            image?.imageUrl
              ? <img src={CircularArrows} style={{maxWidth: 24, maxHeight: 24, }}/>
              : <img src={CloudUpload} style={{maxWidth: 24, maxHeight: 24, }}/>
          }  
        </View>

        {
        image?.imageUrl
          ? <DownloadLink text={'Download image'} url={image?.imageUrl}/>
          : null
        }

      </View>
    </View>
  )
}