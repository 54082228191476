import React, { Fragment } from "react";
import { A } from "@modul-connect/shared/components/atoms/text";
import View from "../../atoms/view";
import { SidebarIcon } from "../../atoms/sideBarIcon";
import theme from "../../../theme";
import chroma from "chroma-js";
import {useLocation, useHistory} from 'react-router-dom';
import { CustomTooltip } from "../../molecules/tooltip";


const SideBarLinks = ({
  isSelected,
  sidebarContent,
  onClick,
  iconOnly,
  textOnly,
}) => {
  return (
    <View style={iconOnly && { borderBottom: `0.5px solid ${theme.colors.primaryDark}` }}>
      {sidebarContent.map((item, index) => {
        return item.hide ? null : (
          <SidebarLink
            key={index}
            iconOnly={iconOnly}
            textOnly={textOnly}
            title={item.title}
            id={item.id}
            icon={item.icon}
            onClick={(title) => {onClick(index)}}
            isSelected={isSelected(index)}
          />
        );
      })}
    </View>
  );
};

export const SidebarLink = ({title, id, icon, isSelected, onClick, iconOnly, textOnly}) => {
  const location = useLocation()
  const history = useHistory()
  return (
    <CustomTooltip title={title} placement="right-start">
      <View
        extend={[
          styles.link,
          iconOnly && !textOnly && styles.link_iconOnly,
          textOnly && !iconOnly && styles.link_textOnly,
          isSelected && styles.link_selected,
        ]}
        onClick={() => onClick(id, location, history)}
      >
        <View style={{ width: "8px" }}> </View>

        {textOnly ? null : <LinkIcon icon={icon} isSelected={isSelected} />}

        {iconOnly ? null : <LinkText isSelected={isSelected} onClick={onClick} text={title} extend={textOnly && styles.link_textOnly_text} />}
      </View>
    </CustomTooltip>
  );
};

export const SidebarLinkForSubpages = ({id, title, icon, isSelected, onClick, iconOnly, textOnly, subPages}) => {
  const location = useLocation()
  const history = useHistory()
  let isSubPageSelected = true
  if (subPages?.length >= 2)
  {
    let splittedPathName = location.pathname.split('/')
    if(splittedPathName.length > 3){
      isSubPageSelected = splittedPathName[3]?.toLowerCase() === id?.toLowerCase()
    }
    else{
      isSubPageSelected = false
    }
  }
  return (
    <View
      extend={[
        styles.link,
        iconOnly && !textOnly && styles.link_iconOnly,
        textOnly && !iconOnly && styles.link_textOnly,
        isSubPageSelected ? styles.link_selected : styles.link_unselected,
      ]}
      onClick={() => onClick(id, location, history, subPages)}
    >
      <View style={{ width: "8px" }}> </View>

      {textOnly ? null : <LinkIcon icon={icon} isSelected={isSelected} />}

      {iconOnly ? null : <LinkText isSelected={isSelected} onClick={onClick} text={title} extend={textOnly && styles.link_textOnly_text} />}
    </View>
  );
};

const LinkIcon = ({ icon, isSelected }) => {
  return (
    <View
      extend={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SidebarIcon dark icon={icon} large selected={isSelected} />
    </View>
  )
}

const LinkText = ({ isSelected, onClick, text, extend }) => {
  return (
    <Fragment>
      <View style={{ width: "16px" }}> </View>

      <A 
        extend={[isSelected ? styles.selected : styles.unselected, extend]}
        onClick={onClick}
        text={text}
      />
    </Fragment>
  )
}

const styles = {
  sidebar_title: ({ theme: { colors } }) => ({
    color: colors.black,
    fontWeight: "bold",
  }),
  link: ({ theme: { colors } }) => ({
    display: "flex",
    maxHeight: "35px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 8px 4px 0px",
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: chroma(colors.lightGrey).darken(0.125).hex()
    },

    "& a": {
      color: colors.darkGrey,
    },
    "& div": {
      color: colors.darkGrey,
    },
    /* "&:hover": {
      "& a": {
        textDecoration: 'underline'
      },
    }, */
    "> a": {
      marginTop: 7,
      marginBottom: 7,
      paddingLeft: 20,
    },
  }),
  link_iconOnly: ({ theme: { colors, layout } }) => ({
    height: layout.sidebarItemHeight,
    width: layout.sidebarItemWidth,
    maxHeight: "auto",
    borderTop: `1px solid ${colors.lightDividerGrey}`,
    borderLeft: `1px solid ${colors.lightDividerGrey}`,
  }),
  link_textOnly: ({ theme: { colors, layout } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '20px',
    marginBottom: '20px',

    height: layout.sidebarItemHeight / 2,

    width: '100%',

    backgroundColor: colors.primaryDark,
    borderRadius: '4px',    
  }),
  link_textOnly_text: ({ theme: { colors, layout } }) => ({
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#FCFCFC',
  }),
  link_selected: ({ theme: { layout, colors } }) => ({
    backgroundColor: colors.primaryDark,
    borderTop: `0.5px solid ${colors.primaryDark}`,
    borderLeft: `0.5px solid ${colors.primaryDark}`,
    borderRight: `0.5px solid ${colors.primaryDark}`,
    marginBottom:0,

    "&:hover": {
      backgroundColor: chroma(colors.primaryDark).darken(0.5).hex()
    },

    "& a": {
      color: colors.white,
    },
    "& img": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)'
        // filter: 'invert(43%) sepia(12%) saturate(1971%) hue-rotate(76deg) contrast(91%);'
      },
    "& div": {
      color: colors.primary,
    },
  }),
  link_unselected: ({ theme: { layout, colors } }) => ({
    backgroundColor: colors.white,
    borderTop: `0.5px solid ${colors.white}`,
    borderLeft: `0.5px solid ${colors.white}`,
    borderRight: `0.5px solid ${colors.white}`,
    marginBottom:0,

    "&:hover": {
      backgroundColor: chroma(colors.white).darken(0.5).hex()
    },

    "& a": {
      color: colors.black,
    },
    "& img": {
      color: colors.white,
      filter: 'invert(100%) contrast(100%) brightness(200%)'
        // filter: 'invert(43%) sepia(12%) saturate(1971%) hue-rotate(76deg) contrast(91%);'
      },
    "& div": {
      color: colors.primary,
    },
  }),
};

export default SideBarLinks;
