import React from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { A, Span } from "@modul-connect/shared/components/atoms/text";
import { MdKeyboardArrowLeft } from "react-icons/md";
import theme from "@modul-connect/shared/theme";
import { Header } from './header'

export const borderStyle = `0.5px solid ${theme.colors.lightDividerGrey}`

const styles = {
  pageCSS: ({ theme: { layout, colors, shadowStylePaper } }) => ({
    marginTop: `${2 * layout.grid}px`,
    paddingBottom: "18px",
    borderRadius: "8px 8px 0px 0px",
    width: "100%",
    overflowY: "visible",
    overflowX: 'scroll',
    backgroundColor: colors.barBackground,
    "> h1": {
      marginBottom: 20,
    },
    ...shadowStylePaper,
  }),
  linkBack: ({ theme: { layout, colors } }) => ({
    marginBottom: "8px",
    position: "relative",
    "> svg": {
      fontSize: 21,
      position: "absolute",
      left: -6,
      top: -1,
    },
    "> span": {
      paddingLeft: 17,
    },
  }),
  pageView: {
    backgroundColor: theme.colors.pageBg,
    //paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderTop: borderStyle
  },
};

const PaperWithHeader = ({
  children,
  title,
  linkBack,
  extend,
  extendChildContainer,
  onClickLinkBack,
  groupingOptionSelector,
  onTimeframePicked,
  onStartDatePicked,
  onEndDatePicked,
  noFilterBar,
  noTimePickerBar,
  noSearch,
  onSearchChange,
  onDownload,
  filterDropdowns,
  searchValue,
  timeSelectors,
  button,
  sideFitleringComponent
}) => {
  function goBack() {
    linkBack.goBack();
  }
  return (
    <View extend={[styles.pageCSS, extend]}>
      {linkBack && (
        <A
          extend={styles.linkBack}
          onClick={() => {
            goBack();
            onClickLinkBack && onClickLinkBack();
          }}
        >
          <MdKeyboardArrowLeft />
          <Span>BACK</Span>
        </A>
      )}

      <Header
        title={title}
        button={button}
        groupingOptionSelector={groupingOptionSelector}
        noFilterBar={noFilterBar}
        noTimePickerBar={noTimePickerBar}
        noSearch={noSearch}
        onSearchChange={onSearchChange}
        onDownload={onDownload}
        searchValue={searchValue}
        filterDropdowns={filterDropdowns}
        onTimeframePicked={onTimeframePicked}
        onStartDatePicked={onStartDatePicked}
        onEndDatePicked={onEndDatePicked}
        timeSelectors={timeSelectors}
        sideFitleringComponent={sideFitleringComponent}
      />

      <View extend={[styles.pageView, extendChildContainer]}>{children}</View>
    </View>
  );
};


export default PaperWithHeader;
