import React from "react";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import View from "../../atoms/view";
import theme from "../../../theme";
import { styles as textStyles } from '@modul-connect/shared/components/atoms/text/text'

const textStyle = {
  fontFamily: "Inter",

  fontWeight: 500,
  color: theme.colors.tableText
};

export const Dropdown = ({ noBorder, addOptionNone, options, placeholder, value, onChange, isRequired, title, disabled, size }) => {
  return (
    <View>
      <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        {isRequired ? <View style={{ color: theme.colors.inputTextGrey }}>*</View> : null}
        <Title>{title}</Title>
      </View>
      <FormControl fullWidth size={size ?? "small"}>
        <TextField
          select
          size={size ?? "small"}
          disabled={disabled}
          label={value ? '' : placeholder}
          id="demo-simple-select"
          variant={noBorder ? "standard" :  "outlined"}
          value={value ?? ''}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            disableUnderline: true,
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
          }}
          InputProps={{
            disableUnderline: true,
            style: textStyles.inputText,
          }}
        >
          {addOptionNone ? <MenuItem style={textStyle} selected={false} value=""><em>{"None"}</em></MenuItem> : null}
          {options?.map((option) => (
            <MenuItem onClick={option.onSelect} style={textStyle} key={option.id ?? option.name} value={option.value ?? (option.id ?? option.name)}>
              <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  color: option.color ?? theme.colors.text
                }}>
                {option.name}
                {option.icon ? option.icon : null}
              </View>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </View>
  );
};


export const Title = ({ children }) => {
  return (
    <View style={{
      color: '#606060',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      letterSpacing: '0.24px',
      textTransform: 'uppercase'
    }}>
      {children}
    </View>
  )
}