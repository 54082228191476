import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import TextInputBox from "@modul-connect/shared/components/atoms/textInput/textInputBox";
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import { DateTime } from "luxon";
import { dateTimeToEpoch } from "@modul-connect/shared/utils/dateTimeUtils";
import { CircularProgress } from "@material-ui/core";
import { useAuth0 } from '@auth0/auth0-react';
import { GroupingContext, groupingOptions } from "../../drivingLogPage";
import { updateDrivingLogNote } from "../../../../../state/actions/vehicles";


const NoteSection = ({ log, saving, updateDrivingLogNote }) => {
  const startDT = DateTime.fromISO(log.startTime);
  const logId = log?.boxId + "_" + dateTimeToEpoch(startDT);

  const groupBy = useContext(GroupingContext);

  const { getAccessTokenSilently } = useAuth0()

  const [newNote, setNewNote] = useState(
    saving?.drivingLogNote?.find((n) => n.id === logId)?.saving ?? log.note
  );
  const [noteHasBeenEdited, setNoteHasBeenEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    if (!noteHasBeenEdited && newNote !== log.note) {
      setNoteHasBeenEdited(true);
    } else if (noteHasBeenEdited && newNote === log.note)
      setNoteHasBeenEdited(false);
  }, [newNote]);

  useEffect(() => {
    const isSavingNote = saving?.drivingLogNote?.find((n) => n.id === logId);
    setIsSaving(isSavingNote);
  }, [saving?.drivingLogNote]);

  useEffect(() => {
    if (isSaving) setNoteHasBeenEdited(false);
  }, [isSaving]);

  const updateDrivingLogNoteWithAccessToken = () => {
    const previousNote = log.note
    getAccessTokenSilently().then((accessToken) => {
      updateDrivingLogNote(
        accessToken,
        groupBy === groupingOptions.byDriver ? null : log?.boxId,
        groupBy === groupingOptions.byDriver ? log?.driverId : null,
        logId,
        log.telematicsId,
        startDT,
        newNote,
        previousNote,
        log.purpose?.toLowerCase()
      );
    });
  };
  return (
    <View
      extend={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <TextInputBox
        disabled={isSaving}
        extend={{ marginBottom: "50px" }}
        rows={4}
        placeholder={(log.purpose?.toLowerCase() !== 'work') ? "No note" : "Missing note"}
        value={newNote}
        onChange={setNewNote}
        label={newNote ? "Note" : (log.purpose?.toLowerCase() !== 'work' ? "No note" : "Missing Note")}
      />
      <Button
        disabled={!noteHasBeenEdited || isSaving}
        extend={{ width: "25%", marginBottom: "20px" }}
        onClick={() => updateDrivingLogNoteWithAccessToken()}
      >
        {isSaving ? <CircularProgress size={20} color="inherit" /> : "Save"}
      </Button>
    </View>
  );
};

const mapStateToProps = ({
  saving
}) => ({
  saving
});

const mapDispatchToProps = dispatch => ({
  updateDrivingLogNote: (accessToken, boxId, driverId, logId, telematicsId, startTime, note, previousNote, purpose) => dispatch(updateDrivingLogNote(accessToken, boxId, driverId, logId, telematicsId, startTime, note, previousNote, purpose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteSection);
