import React from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import { P, A, Span } from '@modul-connect/shared/components/atoms/text';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import theme from "@modul-connect/shared/theme"
import { PaperTitle } from '../text/text';

const Paper = ({ children, title, linkBack, onClickLinkBack, linkTitle, onClickLink, extend, extendChildContainer, linkIcon = <MdAdd />, linkDisabled }) => {
  function goBack() {
    linkBack.goBack();
  }

  const styles = {
    pageCSS: ({ theme: { layout, colors } }) => ({
      marginTop: `${2 * layout.grid}px`,
      paddingBottom: '18px',
      borderRadius: `${1 * layout.grid}px`,
      width: '100%',
      height: '100%',
      overflowY: 'visible',
      backgroundColor: colors.offWhite,
      "> h1": {
        marginBottom: 20
      }
    }),
    linkBack: ({ theme: { layout, colors } }) => ({
      marginBottom: '8px',
      position: 'relative',
      "> svg": {
        fontSize: 21,
        position: 'absolute',
        left: -6,
        top: -1
      },
      "> span": {
        paddingLeft: 17
      }
    }),
    icon: {
      fontSize: 21,
      position: 'absolute',
      right: 0,
      top: 8
    },
    titleWrapper: ({ theme: { layout, colors, shadowStyle } }) => ({
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#DDDDDD',
      paddingLeft: 26,
      alignItems: 'center',
      height: 36,
      alignItems: 'center',
      borderTopRightRadius: `${1 * layout.grid}px`,
      borderTopLeftRadius: `${1 * layout.grid}px`,
      "> div": {
        backgroundColor: '#DDDDDD',
        display: 'flex',
        flexDirection: 'row',
        color: colors.primary,
        cursor: 'pointer',
        "> p": {
          color: colors.primary
        }
      },
      ...shadowStyle
    }),
    linkContainer: ({ theme: { layout, colors } }) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }),
    disabled: ({ theme: { layout, colors } }) => ({
      "> div": {
        "> p": {
          color: colors.disabledGrey
        },
        cursor: 'default',
      }
    }),
    pageView: {
      backgroundColor: theme.colors.offWhite,
      padding: '26px', paddingTop: '34px', paddingBottom: '34px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
  }

  return (
    <View extend={[styles.pageCSS, extend]}>
      {linkBack && (
        <A extend={styles.linkBack}
          onClick={() => {
            goBack()
            onClickLinkBack && onClickLinkBack()
          }}>
          <MdKeyboardArrowLeft /><Span>BACK</Span>
        </A>
      )}
      { title ?
      <View extend={[styles.titleWrapper, linkDisabled ? styles.disabled : undefined]}>
        {title && <PaperTitle text={title/* .toUpperCase() */} />}
        {linkTitle && (
          <View extend={styles.linkContainer} onClick={linkDisabled ? undefined : () => onClickLink()}>
            {linkIcon}
            <P noPadding>{linkTitle/* .toUpperCase() */}</P>
          </View>
        )}
      </View> 
      : null
      }
      <View extend={[styles.pageView, extendChildContainer]}>
        {children}
      </View>
    </View>
  )
};

export default Paper;
