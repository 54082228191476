import React from 'react';
import theme from '@modul-connect/shared/theme';
import View from '@modul-connect/shared/components/atoms/view'
import { P } from '@modul-connect/shared/components/atoms/text'
import { ThemeProvider } from '@material-ui/core/styles';
import { Switch } from '@mui/material';

const LiveMapToggle = ({
  themes,
  map_settings,
  setMapLiveMode
}) => {
  const isMobile = themes.device === 'mobile'
  const isTablet = themes.device === 'tablet'

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '10',
    backgroundColor: 'white',
    height: '29px',
    position: 'absolute',
    top: (isMobile ? theme.layout.navBarHeight_mobile : isTablet ? theme.layout.navBarHeight_tablet : theme.layout.navBarHeight) + 50,
    right: isMobile ? 'auto' : '10px',
    left: isMobile ? '10px' : 'auto',
    padding: '5px',
    paddingLeft: isMobile ? '15px' : 'auto',
    borderRadius: 4,
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 0px 2px'
  }

  return (
    <View extend={style}>
      <ThemeProvider theme={theme.mui}>
        <P>LIVE</P>
        <Switch
          checked={map_settings.isLive}
          onChange={() => {
            setMapLiveMode(!map_settings.isLive)
          }}
          color={"success"}
          name="liveModeToggle"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </ThemeProvider>
    </View>
  )
}

export default LiveMapToggle;