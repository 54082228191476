import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from "react";

export const CustomTooltip = styled(({ className, title, placement, children, dontExpandDiv }) => {
  return (
    <Tooltip title={title} placement={placement} classes={{ popper: className }}>
      <div style={dontExpandDiv ? {} : { height: '100%', width: '100%' }}>{children}</div>
    </Tooltip>
  )
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E5E5E5',
    padding: '4px 8px 4px 8px',
    borderRadius: 0,

    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 12,
    color: '#404040',

    margin: 0
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: '-5px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '-15px !important',
    marginTop: '-5px !important',
  }
}));
