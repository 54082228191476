import ReportPage from "../reportPage.v2"
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { timespanOptions } from "../datePickers/timespanPickers/timespanPickers"
import { DateTime } from "luxon"
import { summary_columns, report_columns } from "./security_system_report_columns";
import TableWidgetSeparated from "@modul-connect/shared/components/molecules/tableWidget/tableWidgetSeparated.v2";
import { fetchSecurityIncidentSummaryReport, fetchVehiclesSecurityReport, selectSecurityAlarmIncident, selectVehicle } from "../../../state/actions/vehicles"
import { useAuth0 } from '@auth0/auth0-react'
import { dateTimeToString } from "@modul-connect/shared/utils/dateTimeUtils"
import GeoPositionICon from '@modul-connect/shared/img/geo_point.svg'
import WHiteWarnignIcon from '@modul-connect/shared/img/white_warning.svg'
import View from '@modul-connect/shared/components/atoms/view'
import { useHistory } from 'react-router-dom';



const SecuritySystemReportPage = ({
  themes,
  security_reports_summary,
  security_reports_detailed,
  fetchSecurityIncidentSummaryReport,
  fetchVehiclesSecurityReport,
  loading,
  selectSecurityAlarmIncident, 
  selectVehicle
}) =>{
  const [startDate, setStartDate] = useState(DateTime.local().startOf('month'))
  const [endDate, setEndDate] = useState(DateTime.local().endOf('month'))
  const [tableData, setTableData] = useState([])

  const { getAccessTokenSilently } = useAuth0()


  const isLoadingSummary = loading.fetchSecuritySummary === 'loading'
  const loadingSummaryFailed = loading.fetchSecuritySummary === 'failed'
  
  useEffect(() => {
    getAccessTokenSilently().then(accessToken => {
        const start = startDate ? startDate : null
        const end =  endDate ? endDate : null
        fetchSecurityIncidentSummaryReport(accessToken, start, end)
    })
  }, [startDate, endDate])

  useEffect(() => {
    const prepared_data = prepareTableData(security_reports_summary.summary, security_reports_detailed.vehicles_reports)
    setTableData(prepared_data)
  },[security_reports_summary.summary, security_reports_detailed.vehicles_reports])


  const onTimeframePicked = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  }
  const history = useHistory()
  const goToAlarmPosition = (alarmId, longitude, latitude, boxId) => {
    if((!latitude && latitude !== 0)  || (!longitude && longitude !== 0) ) return
    history.push('');
    //selectVehicle(boxId, false, true, false, true) 
    selectSecurityAlarmIncident(alarmId, longitude, latitude, boxId)
  }

  const prepareData = (data) => {
    if (!data || !data?.length) return data
  
    const toReturn = []
    data.map((report, index) => {
      const row = []
      row.push({value: dateTimeToString(DateTime.fromISO(report.startedAt), "date-only"), tooltip: 'Date', sortValue: DateTime.fromISO(report.startedAt)?.toMillis() })
      row.push({value: dateTimeToString(DateTime.fromISO(report.startedAt), "time-only"), tooltip: 'Time', sortValue: DateTime.fromISO(report.startedAt)?.toMillis()  })
      row.push({value: report.duration + ' ' + 'min', tooltip: 'Duration', sortValue: report.duration})
      row.push({icon: GeoPositionICon, value1: 
        <View extend={{color: 'blue'}} onClick={() => {goToAlarmPosition(report.alarmId ,report.addressOfAlarm?.longitude, report.addressOfAlarm?.latitude, report.mainboxId)}}>
          {report.addressOfAlarm?.address?.formattedAddress ?? 'Unknown address'}
        </View>, 
      value2: dateTimeToString(DateTime.fromISO(report.startedAt), "date-and-time"),})
      row.push({icon: WHiteWarnignIcon, value: report.clearedBy ?? report.endedReason, tooltip: 'Cleared by' })
    
      toReturn.push(row) 
    })
  
    return toReturn
  }

  const prepareTableData = useCallback(
    (summary, specific_report) => 
      {
        return summary?.length 
        ? summary.map(v => {
          const specificDetailedData = specific_report ? specific_report[v.vin] : []
          return{
            id: v.vin,
            vehicle: v.vehicle,
            incidents: v.incidents,
            isExpandable: true,
            onExpand: () => {
              getAccessTokenSilently().then(accessToken => {fetchVehiclesSecurityReport(accessToken,startDate, endDate, v.mainbox_id, v.vin)})
            },
            collapsibleData: specificDetailedData,
          }},
        )
        : []
      }, [security_reports_summary.summary, security_reports_detailed.vehicles_reports]
  ) 

  const tables = [<TableWidgetSeparated
    data={tableData}
    onelineOnly
    columns={summary_columns}
    themes={themes}
    isSortable
    pagination={false}
    defaultSort={'vehicle'}
    defaultSortOrder={'desc'}
    collapsibleDataLoadingStatus={(id) => {return loading?.fetchVehiclesSecurityReports?.[id]}}
    collapsibleDefaultSortIndex={0}
    collapsibleDefaultSortOrder={'desc'}
    collapsibleLogColumns={report_columns}
    prepareCollapsibleData={(data) => prepareData(data)}
  />]

  return(
    <ReportPage
      title={"Incidents"}
      headerSection={null}
      isLoading={isLoadingSummary}
      fetchingDataFailed={loadingSummaryFailed}
      timeSelectors={[
        timespanOptions.year,
        timespanOptions.month,
        timespanOptions.week,
        timespanOptions.day,
        timespanOptions.custom
      ]}
      startDate={startDate}
      endDate={endDate}
      onTimeframePicked={onTimeframePicked}
      noDataMessage={"There are no incident reports for the selected time period."}
      tables={tables}
      noSearch
      noFilterBar
    />
  )
}

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  fetchSecurityIncidentSummaryReport: (accessToken, start, end) => dispatch(fetchSecurityIncidentSummaryReport(accessToken, start, end)),
  fetchVehiclesSecurityReport: (accessToken, start, end, boxId, vin) => dispatch(fetchVehiclesSecurityReport(accessToken, start, end, boxId, vin)),
  selectSecurityAlarmIncident: (alarmId, longitude, latitude, boxId) => dispatch(selectSecurityAlarmIncident(alarmId, longitude, latitude, boxId)),
  selectVehicle: (boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle) => dispatch(selectVehicle(boxId, shouldScroll, deselectIfAlreadySelected, zoomToRoutes, zoomToVehicle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecuritySystemReportPage);