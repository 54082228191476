import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import SideBar from '@modul-connect/shared/components/organisms/side-bar/multi-side-bar'
import SideBarLinks from '@modul-connect/shared/components/organisms/side-bar-link'
import MainContainer from '@modul-connect/shared/components/organisms/main-container'
import { useParams, useHistory } from 'react-router-dom'
import ModalAjaxError from '@modul-connect/shared/components/organisms/modal-ajax-error'
import BreadCrumbs from '@modul-connect/shared/components/molecules/breadcrumbs'
import { dismissAjaxError } from '../state/actions/app'
import { showFilterBar } from '../utils/app'

const MainPage = ({ 
  themes,
  saving,
  rootURL,
  landingPageURL,
  initialPageIndex,
  hideBreadcrumbs = true,
  breadCrumbWordReplacements,
  sidebarContent,
  blockScrolling,
  dismissAjaxError,
  //selected_organisations
}) => {
  const history = useHistory()
  const { option } = useParams()

  const [selected, setSelected] = useState(initialPageIndex);
  const menuState = selected !== null;

  /* useEffect(() => {
    if (selected !== null)
      setSelected(null)
  }, [selected_organisations]) */

  useEffect(() => {
    if (option) {
      const [category, title] = option.split('_')

      const correctPage = category === rootURL

      if (!correctPage) {
        onSetSelected(null)
        return
      }

      const titleIndex = sidebarContent.findIndex(content => title?.includes(content.id?.toLowerCase()))

      if (titleIndex < 0) {
        onSetSelected(initialPageIndex)
        return
      }

      if (selected !== titleIndex) {
        setSelected(titleIndex)
      }
    }
  }, [option, selected])

  const onSetSelected = (index) => {
    let url = '/' + rootURL

    if (index === null || index === undefined){
      url = url + '/' + rootURL + '_' + sidebarContent[0].id
      history.push(url)
    }
    
    else {
      url = url + '/' + rootURL + '_' + sidebarContent[index].id
      history.push(url)
    }
  }
  return (
    <Fragment>
      <ModalAjaxError
        openModal={ saving && saving.ajaxError }
        onClose={ dismissAjaxError }
      />
      <MainContainer themes={themes} extend={[ styles.main ]} showFilterBar={showFilterBar} blockScrolling={blockScrolling}>
          {!hideBreadcrumbs && <BreadCrumbs keepQueryString={true} rootUrl={landingPageURL} wordReplacements={breadCrumbWordReplacements}/>}
          {menuState && sidebarContent[selected].content}
      </MainContainer>
      
      <SideBar themes={themes} selected={(selected || selected === 0) ? sidebarContent[selected] : undefined} showFilterBar={showFilterBar}>
        <SideBarLinks
          iconOnly={true}
          isSelected={(index) => { return (index || index === 0) && (selected || selected === 0) ? index === selected : false }}
          sidebarContent={sidebarContent}
          onClick={ onSetSelected }
        />
      </SideBar>
    </Fragment>
  );
};

const styles = {
  main: ({ theme: { layout } }) => ({
    padding: '0 16px',
    left: `${layout.sidebarWidth}px`,
    right: 0,
    bottom: 0,
  }),
};

const mapStateToProps = ({ 
  themes, 
  saving,
  //selected_organisations
 }) => ({
  themes, 
  saving,
  //selected_organisations
});

const mapDispatchToProps = dispatch => ({
  dismissAjaxError: () => dispatch(dismissAjaxError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);