import React, { Fragment } from "react";
import { Divider } from "@material-ui/core";
import {Divider as DividerV2} from '@mui/material';
import View from '@modul-connect/shared/components/atoms/view'
import { connect } from 'react-redux'
import modal from '@modul-connect/shared/components/atoms/modal'
import Button from "@modul-connect/shared/components/atoms/button/button.v2";
import Card from '@modul-connect/shared/components/atoms/card/card';
import { ModalBase } from "@modul-connect/shared/components/molecules/modal/ModalBase";

const ConfirmationModal = ({
  open,
  title,
  body,
  onConfirm,
  onClose,
  confirmText,
  confirmIcon,
  cancelText,
  v2,
}) => {
  const buttons = (
    <View style={{ ...(v2 ? {display: 'flex', flexDirection: 'row'} : modal.footer), width: "100%", justifyContent: v2 ? 'center' : "flex-start", gap: v2 ? 10 : 'auto' }}>
      <View>
        <Button
          noBg={v2}
          border={v2}
          extend={!v2 && { borderRadius: 0 }}
          noPadding
          icon={confirmIcon}
          onClick={() => onConfirm()}
        >
          {(confirmText ?? "Confirm")}
        </Button>
      </View>
      <View>
        <Button
          extend={!v2 && { borderRadius: 0 }}
          noBg
          border={v2}
          cancel
          onClick={() => onClose()}
        >
          {cancelText ?? "Cancel"}
        </Button>
      </View>
    </View>
)

  const content = (
    <Fragment>
      <View style={{ marginTop: 4, marginBottom: 1 }}>{body}</View>
    </Fragment>
  );

  if (v2) {
    return (
      <ModalBase noBg title={title} open={ open } onClose={(event, reason) => () => onClose() }>
        <Card v2 extend={{ borderRadius: 4, height: 'fit-content', padding: 24, paddingTop: 22 }}>
          {content}
          <DividerV2 sx={{ width: "100%"}} />
          {buttons}
        </Card>
      </ModalBase>
    )
  }
  
  return( 
    <ModalBase
      open={ open }
      onClose={(event, reason) => () => onClose() }            
    >
            {content}
            <Divider sx={{ width: "100%", marginTop: 4, marginBottom: 1 }} />
            {buttons}
    </ModalBase>
  )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps,mapDispatchToProps) (ConfirmationModal);