import React, { useState } from "react";
import View from "@modul-connect/shared/components/atoms/view";
import { connect } from 'react-redux'
import { PaperTitle } from "@modul-connect/shared/components/atoms/text/text";
import { Divider } from "@material-ui/core";
import {
  TimespanPicker,
  timespanOptions,
} from "../../../../portal/src/pages/reports/datePickers/timespanPickers/timespanPickers";
import FilterIcon from "@modul-connect/shared/img/filter.svg";
import { ReportDownloadButton } from "../../../../portal/src/pages/reports/reportDownloadButton";
import StartEndMonthPicker from "../../../../portal/src/pages/reports/datePickers/startEndMonthPicker/startEndMonthPicker";
import StartEndDatePicker from "../../../../portal/src/pages/reports/datePickers/startEndDatePicker/startEndDatePicker";
import { ReportContext } from "../../../../portal/src/pages/reports/reportPage.v2";
import { borderStyle } from "./paperWithHeader";


// TODO: need to click twice to change picker ...
const TimeSelectionRow = ({
  timeSelectors,
  onTimeframePicked,
  onStartDatePicked,
  onEndDatePicked,
  isLoading,
  onDownload,
}) => {
  const startDate = React.useContext(ReportContext).startDate
  const endDate = React.useContext(ReportContext).endDate
  const useOldDatePickers = React.useContext(ReportContext).useOldDatePickers

  const [selectedStartTime, setSelectedStartTime] = useState(startDate)
  const [selectedEndTime, setSelectedEndTime] = useState(endDate)

  React.useEffect(() => {
    onTimeframePicked && onTimeframePicked(selectedStartTime, selectedEndTime)
  }, [selectedEndTime])

  const TimePickerButton = React.memo(({
    onTimeframePicked,
    timespanOption,
    noBorder,
    hide
  }) => {
    return (
      <TimespanPicker
        onTimeframePicked={onTimeframePicked}
        setSelectedStartTime={setSelectedStartTime}
        setSelectedEndTime={setSelectedEndTime}
        extendButton={{
          flex: 1,
          height: '100%',
          maxWidth: 150,
          borderRight: noBorder ? null : borderStyle,
        }}
        timespan={timespanOption}
        startTime={startDate}
        endTime={endDate}
        hide={hide}
      />
    );
  });

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: 55,
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingRight: 20,
        }}
      >
        <img
          width={15}
          height={15}
          style={{ marginRight: 10 }}
          src={FilterIcon}
          alt={"filter_icon"}
        />
        <PaperTitle extend={{ letterSpacing: "0.04em" }} bold text={"Filter"} />
      </View>

      <Divider orientation="vertical" sx={{ padding: 0, height: "100%" }} />

      <View
        style={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {
          useOldDatePickers ?
          timeSelectors?.map((timespan, index) => {
            // TODO: remove these special cases once new datepickers properly implemented
            if (timespan === timespanOptions.month)
              return (
                <StartEndMonthPicker
                  key={index}
                  noTitle
                  disabled={isLoading}
                  startDate={startDate.startOf("month")}
                  endDate={endDate.endOf("month")}
                  onStartMonthPicked={onStartDatePicked}
                  onEndMonthPicked={onEndDatePicked}
                />
              );

            if (timespan === timespanOptions.custom)
              return (
                <StartEndDatePicker
                  key={index}
                  noTitle
                  disabled={isLoading}
                  startDate={startDate}
                  endDate={endDate}
                  onStartDatePicked={onStartDatePicked}
                  onEndDatePicked={onEndDatePicked}
                />
              );

            return (
              <TimePickerButton
                key={index}
                selectedTimespanOption={selectedTimespanOption}
                onTimeframePicked={onTimeframePicked}
                startTime={selectedStartTime}
                endTime={selectedEndTime}
                timespanOption={timespan}
              />
            )
        }) : timeSelectors?.map((timespan, index) => {
          return <TimePickerButton key={index} startTime={selectedStartTime} onTimeframePicked={onTimeframePicked} endTime={selectedEndTime} timespanOption={timespan}/>
        })
        }

        {onDownload ? (
          <ReportDownloadButton disabled={isLoading} onClick={onDownload} />
        ) : null}
      </View>
    </View>
  );
};

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeSelectionRow);